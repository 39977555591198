
import { defineComponent, ref } from "vue";
import ButtonComponent from "../components/ButtonComponent.vue";
import DraggableScrollDirective from "@/directives/DraggableDirective";

export default defineComponent({
  props: ["title", "fullScreen"],
  emits: ["close-modal", "add-screenshot"],

  methods: {},
  components: { ButtonComponent },
  directives: {
    draggableScroll: DraggableScrollDirective,
  },
});
