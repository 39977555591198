
// import { getProductById } from "../services/FirebaseService";
import { computed, defineComponent, inject, ref } from "vue";
import { RouterLink, useRouter } from "vue-router";
import ButtonComponent from "./ButtonComponent.vue";
import ProductLogo from "./ProductLogo.vue";
import TagComponent from "@/components/TagComponent.vue";
import Modal from "@/components/Modal.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import StatusLabelComponent from "@/components/StatusLabelComponent.vue";
import {
  deleteCollectionItem,
  updateCollectionItem,
  getCollectionItemsByIds,
  searchCollectionItems,
  createCollectionItem,
  fetchData,
  getCollectionItemById,
} from "@/services/FirebaseService";
import { notificationService } from "@/services/NotificationsService";
import moment from "../../node_modules/moment";
import VueWordHighlighter from "vue-word-highlighter";
import Menu from "primevue/menu";
import { useStore } from "@/services/Store";
import CardComponent from "./CardComponent.vue";
import Icon from "@/components/Icon.vue";
import OpenAI from "openai";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default defineComponent({
  props: [
    "userflow",
    "upcoming",
    "screenshots",
    "categories",
    "hideTags",
    "time",
  ],
  emits: ["update-userflows"],
  components: {
    RouterLink,
    ButtonComponent,
    ProductLogo,
    Icon,
    TagComponent,
    Modal,
    StatusLabelComponent,
    LoaderComponent,
    VueWordHighlighter,
    Menu,
    CardComponent,
    QuillEditor,
  },
  data() {
    return {
      videoLength: ref(0),
      showModal: ref(false),
      searchQuery: ref(),
      showSearchDropdown: ref(false),
      allCategories: ref(),
      router: useRouter(),
      store: useStore(),
      menu: ref(),
      menuItems: ref([
        {
          label: "Delete",
          command: () => {
            this.deteteUserflow(this.userflow.id);
          },
        },
      ]),
      currentImageIndex: 0,
      product: ref(),
      screenshots: ref(),
      categories: ref(),
      blogPost: ref(),
    };
  },
  setup() {
    const store = useStore();

    const user = computed(() => store.state.user);

    return {
      user,
    };
  },
  async mounted() {
    this.getData();
    this.getVideoLength();
  },
  computed: {
    formattedVideoLength() {
      const minutes = Math.floor(this.videoLength / 60);
      const seconds = Math.floor(this.videoLength % 60);
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
      return `${formattedMinutes}:${formattedSeconds}`;
    },
  },
  methods: {
    async publish() {
      try {
        await createCollectionItem("blog", {
          ...this.blogPost,
          productId: this.product.id,
          userFlowId: this.userflow.id,
          industries: this.product.industries,
        }).then(() => {});
        notificationService.addNotification("Blog post published");
      } catch (error) {
        console.error(error);
      }
    },
    getData() {
      this.getProduct();
      this.getScreenshots();
      this.getCategories();
    },
    async generatePost() {
      notificationService.addNotification(
        `Generating blog post - ${this.userflow.title} in ${this.product.title}`
      );
      const openai = new OpenAI({
        apiKey: "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",
        dangerouslyAllowBrowser: true,
      });

      try {
        await openai.chat.completions
          .create({
            model: "gpt-4",
            messages: [
              {
                role: "user",
                content: `Write an informative post about a user flow as if you're a product designer. Information should be about how product is designed. This time it's for "${
                  this.userflow.title
                }" in "${this.product.title}" product. ${
                  this.product.description
                }. Steps in "${
                  this.userflow.title
                }" are as follows: ${this.screenshots
                  .map((screenshot, index) => {
                    return (
                      screenshot.title +
                      " with an image here: https://storage.googleapis.com/userflows-390418.appspot.com/" +
                      screenshot.screenshot.path +
                      (index !== this.screenshots.length - 1 ? ", " : ".")
                    );
                  })
                  .join(
                    ""
                  )} Start by writing a catchy and very provocative title in a question format (single short snappy title, no semicolons or dashes, etc.).Title examples could be "Is product manipulating me into subscribing?", "Are there any dark UX patterns used in onboarding?", "How much have they spend on designing this?", etc. The goal of the post is to provoke thinking behind the user flow and link it back to best practices in UX when it comes to building a product, also make it engaging. Use simple, UK english language, simple words that a 10 year old would understand. Try to keep it short and straight to point. A closing seentence should be an invitation to explore Userflows product here: https://userflows.co.uk, to see more user flow recordings across wide range of successful product designs. ARTICLE SHOULD BE IN SUITABLE JSON FORMAT, NO OTHER TEXT, JUST THE JSON OBJECT, SUITABLE FOR JAVASCRIPT PARSING, FOLLOWING THIS SPECIFIC OBJECT STRUCTURE AND FOLLOWING KEYS: title(STRING) , intro(STRING), content(in pure html format!!!), thumbnail(IMG SRC). NO ADDITIONAL KEYS IN RESPONSE. WITH CONTENT IN HTML FORMAT TITLE BEING IN H1 TAG, UTILISING SUBTITLES AND BODY TEXT, MAKE SURE TO USE IMG TAGS WHERE APPROPRIATE WITH IMAGES IN SRC!!! IMAGES MUST BE AFTER SENTENCES, NOT IN THE MIDDLE.`,
              },
            ],
            max_tokens: 1000,
          })
          .then((res) => {
            notificationService.addNotification("Suggestions retreived");
            this.blogPost = JSON.parse(res.choices[0].message.content);
          });
      } catch (error) {
        console.error("Error generating user flows:", error);
      }
    },
    async getCategories() {
      try {
        this.categories = await getCollectionItemsByIds(
          "categories",
          this.userflow.categories
        );
      } catch (error) {
        console.error(error);
      }
    },
    async getProduct() {
      try {
        this.product = await getCollectionItemById(
          "products",
          this.userflow.productId
        );
      } catch (error) {
        console.error(error);
      }
    },
    async getScreenshots() {
      try {
        await fetchData("screenshots", 100, 1, "time", "asc", [
          { field: "userflowId", value: this.userflow.id },
        ]).then((res) => {
          this.screenshots = res.items;
        });
      } catch (error) {
        console.error(error);
      }
    },
    startImageLoop() {
      this.currentImageIndex =
        (this.currentImageIndex + 1) % this.screenshots?.length;
      this.hoverInterval = setInterval(() => {
        this.currentImageIndex =
          (this.currentImageIndex + 1) % this.screenshots?.length;
      }, 700); // Change image every 1000 milliseconds (1 second)
    },
    stopImageLoop() {
      clearInterval(this.hoverInterval);
      this.currentImageIndex = 0; // Reset to first image
    },
    toggleMenu(event, id: any) {
      this.$refs[`${id}`].toggle(event);
    },
    closeDropdown() {
      this.showSearchDropdown = false;
      this.searchQuery = null;
      this.showModal = false;
    },
    isNew() {
      const today = moment(new Date());
      return this.userflow.created &&
        today.diff(moment(this.userflow.created), "days") < 14
        ? true
        : false;
    },
    ago(date: any) {
      return moment(date).fromNow();
    },
    focusOnCategoryInput() {
      setTimeout(() => {
        const categoryInput = this.$refs.categoryInput as HTMLInputElement;
        categoryInput.focus();
      }, 100);
    },
    showModalFunction() {
      this.showModal = true;
      this.focusOnCategoryInput();
    },
    async removeCategory(id: any) {
      this.resetSearch();
      let newUserflow = { ...this.userflow };
      newUserflow.categories = newUserflow.categories.filter(
        (category: any) => category !== id
      );
      try {
        await updateCollectionItem(
          "user flows",
          newUserflow.id,
          newUserflow
        ).then(() => {
          this.userflow.categories = this.userflow.categories.filter(
            (category: any) => category !== id
          );
        });
      } catch (error) {}
    },
    async addNewCategory(categoryName: string) {
      let foundCategories: any = [];
      this.allCategories.forEach((category: any) => {
        foundCategories.push(category.name);
      });

      if (!foundCategories.includes(categoryName)) {
        const category = { name: categoryName };
        try {
          await createCollectionItem("categories", category).then((id: any) => {
            this.addCategory(id);
          });
        } catch (error) {}
      }
    },
    async search() {
      this.showSearchDropdown = true;
      try {
        await searchCollectionItems(
          "categories",
          1000,
          1,
          this.searchQuery,
          "name",
          undefined,
          "name",
          "asc",
          "name"
        ).then((res: any) => {
          this.allCategories = res.items;
        });
      } catch (error) {}
    },
    resetSearch() {
      this.searchQuery = "";
      this.showSearchDropdown = false;
    },
    async addCategory(id: any) {
      this.resetSearch();
      let newUserflow = { ...this.userflow };
      newUserflow.categories = newUserflow.categories
        ? [...newUserflow.categories, id]
        : [id];
      try {
        await updateCollectionItem(
          "user flows",
          newUserflow.id,
          newUserflow
        ).then(() => {
          this.userflow.categories = this.userflow.categories
            ? [...this.userflow.categories, id]
            : [id];
          this.focusOnCategoryInput();
        });
      } catch (error) {
        console.error(error);
      }
    },
    async draftToggle() {
      try {
        await updateCollectionItem("user flows", this.userflow.id, {
          draft: !this.userflow.draft,
        }).then(() => {
          notificationService.addNotification(
            this.userflow.title +
              " user flow is now " +
              (this.userflow.draft ? "published" : "un-published")
          );
          this.store.dispatch("fetchUserFlows");
          this.store.dispatch("fetchUserFlow", this.userFlow.id);
        });
      } catch (error) {
        console.error(error);
      }
    },
    async lockedToggle() {
      try {
        await updateCollectionItem("user flows", this.userflow.id, {
          locked: !this.userflow.locked,
        }).then(() => {
          this.$emit("update-userflows");
          this.userflow.locked = !this.userflow.locked;
          notificationService.addNotification(
            this.userflow.title +
              " user flow is now " +
              (this.userflow.locked ? "free" : "locked")
          );
          this.store.dispatch("fetchUserFlows");
        });
      } catch (error) {
        console.error(error);
      }
    },
    async deteteUserflow(id: any) {
      if (this.screenshots.length < 1) {
        try {
          await deleteCollectionItem("user flows", id).then(() => {
            notificationService.addNotification("User flow deleted");
            this.store.dispatch("fetchUserFlows");
            this.$emit("update-userflows");
          });
        } catch (error) {
          console.error(error);
        }
      } else {
        notificationService.addNotification("Delete screenshots first");
      }
    },
    async getVideoLength() {
      const videoElement = document.createElement("video");

      // Arrow function to maintain the context of 'this'
      const canLoadVideo = async (url) => {
        try {
          const response = await fetch(url, { method: "HEAD" });
          return response.ok; // will be true if the response is 200-299
        } catch (error) {
          console.error("Error fetching video:", error);
          return false;
        }
      };

      // Arrow function to maintain the context of 'this'
      const setVideoSrcAndDuration = (url) => {
        videoElement.addEventListener("loadedmetadata", () => {
          this.videoLength = videoElement.duration;
          // Do something with this.videoLength
        });

        videoElement.addEventListener("error", () => {
          console.error("Error loading video metadata");
          // Handle error, potentially setting an alternative video source
        });

        videoElement.src = url;
      };

      const baseUrl =
        "https://storage.googleapis.com/userflows-390418.appspot.com/";

      // Use the nullish coalescing operator to handle the case where this.userflow.video.path is null or undefined
      const directoryPath = "user flows/";
      const filename =
        this.userflow.video?.path?.split("/").pop() ?? "default-filename"; // Provide a default filename if none is found

      // Primary video path
      const primaryPath = `${directoryPath}compressed-${filename}`;

      // Fallback video path
      const fallbackPath = `${directoryPath}${filename}`; // Provide a default fallback path if none is found

      const videoUrl = baseUrl + primaryPath;

      // Check if the compressed video exists and is accessible
      if (await canLoadVideo(videoUrl)) {
        setVideoSrcAndDuration(videoUrl);
      } else {
        // Compressed video doesn't exist, use the original video path
        setVideoSrcAndDuration(baseUrl + fallbackPath);
      }
    },
  },
});
