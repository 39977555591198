
import ProductLogo from "@/components/ProductLogo.vue";
import ProductCardComponent from "@/components/ProductCardComponent.vue";
import Icon from "@/components/Icon.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import TagComponent from "@/components/TagComponent.vue";
import { computed, defineComponent, inject, ref } from "vue";
import { RouterLink, useRouter } from "vue-router";
import { searchClient } from "@/services/Algolia";
import { useStore } from "@/services/Store";

export default defineComponent({
  components: {
    RouterLink,
    ProductLogo,
    ProductCardComponent,
    Icon,
    ButtonComponent,
    LoaderComponent,
    TagComponent,
  },
  data() {
    return {
      auth: <any>inject("auth"),
      tagLimit: ref(15),
      router: useRouter(),
      screenshotSearchQuery: ref(),
      screenshotsReady: ref(false),
      screenshots: ref(),
      showSearchDropdown: ref(false),
    };
  },
  setup() {
    const store = useStore();

    const popularTags = computed(() => store.state.popularTags);

    return {
      popularTags,
    };
  },
  created() {
    this.searchScreenshotsDebounced = this.debounce(
      this.searchScreenshots,
      500
    );
  },
  methods: {
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },
    async searchScreenshots() {
      this.screenshotsReady = false;
      this.showSearchDropdown = true;
      const baseQuery =
        this.screenshotSearchQuery && this.screenshotSearchQuery.length > 0
          ? this.screenshotSearchQuery
          : "";
      const queries = [
        {
          indexName: "tags",
          query: baseQuery,
          params: {
            hitsPerPage: 4,
            attributesToRetrieve: ["name"],
            page: 0,
          },
        },
      ];

      try {
        const responses = await searchClient.search(queries);
        this.screenshots =
          "hits" in responses.results[0] ? responses.results[0].hits : [];

        this.screenshotsReady = true;
      } catch (error) {
        console.error(error);
      }
    },
    filterToTag(tag: any) {
      this.showSearchDropdown = false;
      this.router.push({
        name: "screenshots by ui elements",
        params: { pageNumber: "1", tagTitle: tag.name },
      });
    },
    resetTagFilter() {
      this.router.push({ name: "screenshots", params: { pageNumber: "1" } });
    },
  },
});
