
import { computed, defineComponent, ref } from "vue";
import { RouterLink } from "vue-router";
import ButtonComponent from "./ButtonComponent.vue";
import DraggableScrollDirective from "@/directives/DraggableDirective";
import { useStore } from "@/services/Store";
import AvatarComponent from "./AvatarComponent.vue";
import Icon from "./Icon.vue";
import SearchComponent from "./SearchComponent.vue";
import Logo from "./Logo.vue";
import FooterComponent from "./FooterComponent.vue";
import IconLabelComponent from "./IconLabelComponent.vue";
import Menu from "primevue/menu";
import { auth } from "@/firebase";
import SidebarComponent from "./SidebarComponent.vue";
import { previousRoute } from "@/router";

declare var Canny: any;

// import Icon from '@/components/Icon.vue'

export default defineComponent({
  data() {
    return {
      previousRoute: previousRoute,
      menu: ref(),
      accountItems: ref([
        {
          items: [
            {
              label: "Account",
              command: () => {
                this.$router.push({ name: "account" });
              },
            },
            {
              label: "Personal collections",
              command: () => {
                this.$router.push({ name: "personal collections" });
              },
            },
            { separator: true },
            {
              label: "Sign out",
              command: () => {
                this.signOut();
              },
            },
          ],
        },
      ]),
    };
  },
  props: ["pageTitle", "titleEditable", "id", "hideHeader", "showSidebar"],
  emits: ["update-title"],

  computed: {},
  mounted() {
    Canny("initChangelog", {
      appID: "65cf9f17ab693a35fae5c0c1",
      position: "bottom",
      align: "right",
      theme: "light", // options: light [default], dark, auto
    });
  },
  methods: {
    async signOut() {
      try {
        await auth.signOut().then(() => {
          window.location.reload();
        });
      } catch (error) {
        console.error(error);
      }
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    updateTitle() {
      const newTitle = document.getElementById(this.id)!.innerText;
      this.$emit("update-title", { title: newTitle });
    },
  },
  components: {
    RouterLink,
    ButtonComponent,
    AvatarComponent,
    Icon,
    SearchComponent,
    Logo,
    FooterComponent,
    IconLabelComponent,
    Menu,
    SidebarComponent,
  },
  directives: {
    draggableScroll: DraggableScrollDirective,
  },
  setup() {
    const store = useStore();

    const user = computed(() => store.state.user);

    return {
      user,
    };
  },
});
