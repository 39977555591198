
import ProductLogo from "@/components/ProductLogo.vue";
import ProductCardComponent from "@/components/ProductCardComponent.vue";
import Icon from "@/components/Icon.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import TagComponent from "@/components/TagComponent.vue";
import { computed, defineComponent, inject, ref } from "vue";
import { RouterLink, useRouter } from "vue-router";
import {
  deleteCollectionItem,
  getCollectionItemById,
  getItemsInCollection,
  searchRelatedCollectionItems,
} from "@/services/FirebaseService";
import { Timestamp } from "firebase-admin/firestore";
import { searchClient } from "@/services/Algolia";
import { useStore } from "@/services/Store";

export default defineComponent({
  components: {
    RouterLink,
    ProductLogo,
    ProductCardComponent,
    Icon,
    ButtonComponent,
    LoaderComponent,
    TagComponent,
  },
  data() {
    return {
      auth: <any>inject("auth"),
      router: useRouter(),
      userFlowSearchQuery: ref(),
      userflowsAndCategories: ref(),
      showSearchDropdown: ref(false),
      userflowsReady: ref(false),
    };
  },
  setup() {
    const store = useStore();

    const popularCategories = computed(() => store.state.popularCategories);

    return {
      popularCategories,
    };
  },
  created() {
    this.searchUserFlowsDebounced = this.debounce(this.searchUserFlows, 500);
  },
  methods: {
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },
    goToCategorySearchResults(category: any) {
      this.showSearchDropdown = false;
      this.router.push({
        name: "user flows by category",
        params: { category, pageNumber: "1" },
      });
    },
    goToUserflowSearchResults(userflow: any) {
      this.showSearchDropdown = false;
      this.router.push({
        name: "user flow",
        params: { userflowId: userflow.objectID },
      });
    },
    async searchUserFlows() {
      this.userflowsReady = false;
      this.showSearchDropdown = true;
      const baseQuery =
        this.userFlowSearchQuery && this.userFlowSearchQuery.length > 0
          ? this.userFlowSearchQuery
          : "";
      const queries = [
        {
          indexName: "categories",
          query: baseQuery,
          params: {
            hitsPerPage: 4,
            attributesToRetrieve: ["name"],
            page: 0,
          },
        },
        {
          indexName: "userflows",
          query: baseQuery,
          params: {
            hitsPerPage: 4,
            attributesToRetrieve: ["title"],
            page: 0,
          },
        },
      ];

      try {
        const responses = await searchClient.search(queries);
        const categoriesHits =
          "hits" in responses.results[0] ? responses.results[0].hits : [];
        const userflowsHits =
          "hits" in responses.results[1] ? responses.results[1].hits : [];

        userflowsHits.forEach(async (userflow: any, index: any) => {
          try {
            await getCollectionItemById(
              "products",
              userflow._highlightResult.productId.value
            ).then((res: any) => {
              userflow.product = res;
              if (index + 1 == userflowsHits.length) {
                this.userflowsAndCategories = [
                  ...categoriesHits,
                  ...userflowsHits,
                ];
                setTimeout(() => {
                  this.userflowsReady = true;
                }, 50);
              }
            });
          } catch (error) {
            console.error(error);
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    filterToCategory(category: any) {
      this.router.push({
        name: "user flows by category",
        params: { pageNumber: "1", category: category.name },
      });
    },
    resetCategoryFilter() {
      this.router.push({ name: "user flows", params: { pageNumber: "1" } });
    },
  },
});
