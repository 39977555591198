import { reactive, watchEffect } from "vue";
import { auth } from "@/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import router from "@/router";
import { notificationService } from "./NotificationsService";
import mixpanel from "mixpanel-browser";

const adminUsers = [
  "edgar.culka@yahoo.co.uk",
  "daniels.culka@icloud.com",
  "kuznecovsadrians1@gmail.com",
  "venducis2@gmail.com",
  "pokeruct@gmail.com",
  "ejurevica@gmail.com",
  "gradkovskad@gmail.com",
];

export function getCleanErrorMessage(errorMessage: any) {
  // Use regular expression to find the message without the prefix and code
  const regex = /Firebase: (.*) \((.*?)\)/;
  const match = regex.exec(errorMessage);

  if (match && match[1]) {
    // Return the error message without the prefix and code
    return match[1].trim();
  } else {
    // Return the original error message if the format is unexpected
    return errorMessage;
  }
}

// Reactive user object
const user = reactive({
  isLoggedIn: false,
  isAdmin: false,
  isSubscribed: false,
  isInTrial: false,
  hasTrialExpired: false,
  data: null,
  subscriptions: null, // New property for storing subscriptions
  trial: null,
  checked: false,
});

watchEffect((onInvalidate) => {
  const unsubscribe = auth.onAuthStateChanged(async (currentUser: any) => {
    user.checked = false;
    if (currentUser) {
      user.isLoggedIn = true;
      user.data = currentUser;

      // Fetch user subscriptions from Firestore
      const customerRef = firebase
        .firestore()
        .collection("customers")
        .doc(currentUser.uid);

      // Get the current user's email
      const userEmail = currentUser.email;

      // Fetch subscriptions
      const subscriptionsSnapshot = await customerRef
        .collection("subscriptions")
        .get();
      const subscriptions = subscriptionsSnapshot.docs.map((doc) => doc.data());

      // Check if the user has an active or trialing subscription
      const hasActiveSubscription =
        subscriptions.length > 0 &&
        subscriptions.some((subscription) => {
          return (
            subscription.status == "active" || subscription.status == "trialing"
          );
        });

      // Check if the user's email is in any team's members list
      const teamsRef = firebase.firestore().collection("teams");
      const teamsSnapshot = await teamsRef
        .where("members", "array-contains", userEmail)
        .get();

      // If the user has an active subscription or is part of any team, set isSubscribed to true
      if (hasActiveSubscription || !teamsSnapshot.empty) {
        user.isSubscribed = true;
      } else {
        user.isSubscribed = false;
      }

      const subscriptionsCopy = JSON.parse(JSON.stringify(subscriptions));
      user.subscriptions = subscriptionsCopy as any;

      // Fetch user trial information from Firestore
      const trialsRef = firebase.firestore().collection("trials");
      const trialSnapshotByUserId = await trialsRef
        .where("userId", "==", currentUser.uid)
        .get();
      const trialSnapshotByEmail = await trialsRef
        .where("email", "==", currentUser.email)
        .get();

      let trialData = null;

      if (!trialSnapshotByUserId.empty) {
        trialData = trialSnapshotByUserId.docs[0].data();
      } else if (!trialSnapshotByEmail.empty) {
        trialData = trialSnapshotByEmail.docs[0].data();
      }

      if (trialData) {
        user.trial = trialData; // Add trial information to user object
      }

      if (user.isSubscribed && trialData) {
        user.trial.isActive = false;
      }

      if (adminUsers.includes(currentUser.email)) {
        user.isAdmin = true;
      }
      user.checked = true;

      sendUserDetailsToMixpanel(user);
    } else {
      user.isLoggedIn = false;
      user.isAdmin = false;
      user.isSubscribed = false;
      user.isInTrial = false;
      user.hasTrialExpired = false;
      user.data = null;
      user.subscriptions = null;
      user.trial = null; // Reset trial information when user is logged out
      user.checked = true;
    }
  });

  // Stop observing when the component is unmounted
  onInvalidate(() => unsubscribe());
});

export default user;

function sendUserDetailsToMixpanel(user: any) {
  mixpanel.identify(user.data.uid);
  mixpanel.people.set({
    LoggedIn: user.isLoggedIn, // Other property to segment by
    Admin: user.isAdmin, // Other property to segment by
    Subscribed: user.isSubscribed, // Other property to segment by
    Trial: user.isInTrial, // Other property to segment by
    TrialExpired: user.hasTrialExpired, // Other property to segment by
    EmailVerified: user.data.emailVerified, // user's email
    $email: user.data.email, // user's email
  });
}

export function registerUser(
  email: string,
  password: string,
  inviteLink?: string
) {
  return new Promise((resolve, reject) => {
    let user: firebase.User | null = null;

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        user = userCredential.user;

        // Call separate function to send email verification
        return sendEmailVerification();
      })
      .then(() => {
        const db = firebase.firestore();

        // Check for inviteLink and process if exists
        if (inviteLink) {
          return db
            .collection("teams")
            .where("inviteLink", "==", inviteLink)
            .get();
        } else {
          // Proceed without invite link processing
          return null;
        }
      })
      .then((snapshot) => {
        const db = firebase.firestore();
        if (snapshot && !snapshot.empty) {
          const team = snapshot.docs[0].data();
          if (team.inviteLinkActive) {
            // Add user email to the team's members array
            const teamId = snapshot.docs[0].id;
            return db
              .collection("teams")
              .doc(teamId)
              .update({
                members: firebase.firestore.FieldValue.arrayUnion(email),
              });
          } else {
            throw new Error("Invite link is not active.");
          }
        } else if (inviteLink) {
          throw new Error("Invalid invite link.");
        }
        return null;
      })
      .then(() => {
        // Initialize Firestore
        const db = firebase.firestore();

        // Check if email already exists in 'trials' collection
        return db.collection("trials").where("email", "==", email).get();
      })
      .then((snapshot) => {
        if (snapshot.empty) {
          // If email does not exist in 'trials', add new trial information
          const trialEnds = new Date();
          trialEnds.setDate(trialEnds.getDate() + 7);

          const db = firebase.firestore();
          return db.collection("trials").add({
            userId: user!.uid,
            email: user!.email,
            trialStarted: new Date().toISOString(),
            trialEnds: trialEnds.toISOString(),
            isActive: true,
          });
        } else {
          // If email already exists in 'trials', do nothing
          return null;
        }
      })
      .then(() => {
        resolve({ message: "User registered successfully", error: false });
      })
      .catch((error) => {
        const result = { message: error.message, error: true };
        resolve(result);
      });
  });
}

export function sendEmailVerification() {
  return new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;
    if (user) {
      user
        .sendEmailVerification()
        .then(() => {
          notificationService.addNotification("Verification email sent.");
          resolve("Email verification sent");
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject("No user is currently signed in.");
    }
  });
}

// Function to send a password reset email
export function forgotPassword(email: string) {
  return new Promise((resolve, reject) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent successfully
        resolve(
          "Password reset email sent. Check your inbox for further instructions."
        );
      })
      .catch((error) => {
        const response = { error: true, message: error.message };
        resolve(response);
      });
  });
}

// Function to delete the user account
export function deleteUserAccount() {
  return new Promise(async (resolve, reject) => {
    try {
      const currentUser = firebase.auth().currentUser;

      if (currentUser) {
        // Delete the user account from Firebase Authentication
        await currentUser.delete();

        // Additional actions after account deletion (e.g., redirect, display success message, etc.)
        // For example, you can redirect the user to the home page:
        router.push("/");

        // Resolve the Promise with a success message
        resolve("User account deleted successfully.");
      } else {
        // If no user is signed in, resolve the Promise with an info message
        resolve("No user is currently signed in.");
      }
    } catch (error: any) {
      // Handle any errors that occur during account deletion and reject the Promise
      const response = { error: true, message: error.message };
      resolve(response);
    }
  });
}

function getActionCodeFromUrl(url) {
  const urlParams = new URLSearchParams(new URL(url).search);
  return urlParams.get("oobCode");
}

firebase.auth().onAuthStateChanged((user: any) => {
  if (user && !user.data?.emailVerified) {
    const actionCode = getActionCodeFromUrl(window.location.href);
    if (actionCode) {
      firebase
        .auth()
        .applyActionCode(actionCode)
        .then(() => {
          mixpanel.track("Event", {
            Event: "Email verified",
          });
          notificationService.addNotification(
            `You've sucessfully verified your email address. Redirecting...`
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
});
