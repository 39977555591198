
import VideoCard from "@/components/VideoCard.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { SwiperSlide, Swiper } from "swiper/vue";
import { Navigation } from "swiper/modules";
import { useStore } from "@/services/Store";
import RegistrationComponent from "./RegistrationComponent.vue";
import ProductLogo from "./ProductLogo.vue";
import ProductCardComponent from "./ProductCardComponent.vue";
import CardComponent from "./CardComponent.vue";
import Paginator from "primevue/paginator";
import LoaderComponent from "./LoaderComponent.vue";
import UserFlowComponent from "./UserFlowComponent.vue";
import UserFlowsVue from "./UserFlows.vue";
import PricingComponent from "./PricingComponent.vue";

export default defineComponent({
  emits: ["update-userflows", "load-more"],
  props: ["userflows", "limit", "pageNumber", "upcoming", "layout"],
  components: {
    VideoCard,
    ButtonComponent,
    Swiper,
    SwiperSlide,
    RegistrationComponent,
    ProductLogo,
    ProductCardComponent,
    CardComponent,
    Paginator,
    UserFlowComponent,
    LoaderComponent,
    PricingComponent,
  },
  data() {
    return {
      router: useRouter(),
      modules: [Navigation],
    };
  },
  setup() {
    const store = useStore();

    const user = computed(() => store.state.user);
    const products = computed(() => store.state.products);
    const userFlows = computed(() => store.state.userFlows);
    const emptyProducts = computed(() => store.state.emptyProducts);

    return {
      user,
      products,
      userFlows,
      emptyProducts,
    };
  },
  computed: {
    userFlowProductMap() {
      const map = new Map();
      for (const userFlow of this.userflows) {
        if (!map.has(userFlow.productId)) {
          map.set(userFlow.productId, []);
        }
        map.get(userFlow.productId).push(userFlow);
      }
      return map;
    },

    userFlowProducts() {
      return this.products?.filter((product) =>
        this.userFlowProductMap.has(product.id)
      );
    },
  },
  methods: {
    togglePricingModal() {
      this.$store.commit("setShowPricingModal", !this.showPricingModal);
    },
    updatePagination(data) {
      this.$router.push({
        ...this.$route,
        params: { ...this.$route.params, pageNumber: data.page + 1 },
        query: { ...this.$route.query, pageNumber: data.page + 1 },
      });
    },
  },
});
