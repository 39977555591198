
import { computed, defineComponent, inject, onMounted, ref } from "vue";
import { RouterLink, useRoute, useRouter } from "vue-router";
import ButtonComponent from "@/components/ButtonComponent.vue";
import FieldComponent from "@/components/FieldComponent.vue";
import ProductLogo from "@/components/ProductLogo.vue";
import Icon from "@/components/Icon.vue";
import TagComponent from "@/components/TagComponent.vue";
import Modal from "@/components/Modal.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import StatusLabelComponent from "@/components/StatusLabelComponent.vue";
import DraggableScrollDirective from "@/directives/DraggableDirective";
import VueWordHighlighter from "vue-word-highlighter";

import {
  createCollectionItem,
  deleteCollectionItem,
  getCollectionItemById,
  getCollectionItemsByIds,
  searchCollectionItems,
  updateCollectionItem,
} from "@/services/FirebaseService";
import { notificationService } from "@/services/NotificationsService";
import { useStore } from "@/services/Store";
const bootstrap = require("bootstrap");
import Menu from "primevue/menu";
import user from "@/services/Auth";
import CardComponent from "./CardComponent.vue";
import OpenAI from "openai";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import moment from "moment";
import Checkbox from "primevue/checkbox";

export default defineComponent({
  props: [
    "screenshots",
    "screenshot",
    "index",
    "activeTag",
    "hideTags",
    "locked",
    "hideFooter",
    "collection",
    "allowSelecting",
  ],
  emits: [
    "show-gallery",
    "update-screenshot",
    "removed-from-collection",
    "checked-screenshots-updated",
  ],
  components: {
    RouterLink,
    ButtonComponent,
    FieldComponent,
    ProductLogo,
    Icon,
    TagComponent,
    Modal,
    StatusLabelComponent,
    LoaderComponent,
    VueWordHighlighter,
    Menu,
    CardComponent,
    Swiper,
    SwiperSlide,
    Checkbox,
  },
  directives: {
    draggableScroll: DraggableScrollDirective,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      if (!store.state.collections) {
        store.dispatch("fetchCollections");
      }
      if (!store.state.personalCollections) {
        if (user.value.data?.uid) {
          store.dispatch("fetchPersonalCollections");
        }
      }
    });

    async function addToCollection(collection: any) {
      try {
        let newScreenshots;
        if (collection.screenshots) {
          newScreenshots = [...collection.screenshots];
          newScreenshots.push(props.screenshot.id);
        } else {
          newScreenshots = [props.screenshot.id];
        }
        const newCollection = {
          ...collection,
          screenshots: newScreenshots,
        };
        await updateCollectionItem(
          "collections",
          collection.id,
          newCollection
        ).then(() => {
          store.dispatch("fetchCollections");
          store.dispatch("fetchPersonalCollections");
          notificationService.addNotification(`Added to ${collection.name}`);
        });
      } catch (error) {
        console.error(error);
      }
    }

    async function removeFromCollection(collection: any) {
      try {
        const newScreenshots = [
          ...collection.screenshots.filter(
            (screenshot: any) => screenshot != props.screenshot.id
          ),
        ];
        const newCollection = {
          ...collection,
          screenshots: newScreenshots,
        };

        if (newScreenshots.length == 0) {
          try {
            await deleteCollectionItem("collections", collection.id).then(
              () => {
                notificationService.addNotification(
                  `Removed ${collection.name}`
                );
                this.$router.push({ name: "collections main" });
                this.store.dispatch("fetchPersonalCollections");
              }
            );
          } catch (error) {}
        } else {
          await updateCollectionItem(
            "collections",
            collection.id,
            newCollection
          ).then(() => {
            notificationService.addNotification(
              `Removed from ${collection.name}`
            );
            this.store.dispatch("fetchCollections");
            this.store.dispatch({ type: "fetchCollection", id: collection.id });
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    async function createCollection() {
      createNewCollection();
    }

    async function createNewCollection() {
      const newCollection = {
        name: addUniqueCollectionName("New collection"),
        userId: null,
      };
      newCollection.userId = user.value.data?.uid;
      try {
        await createCollectionItem("collections", newCollection).then(
          async (res) => {
            try {
              await getCollectionItemById("collections", res).then(
                async (collection: any) => {
                  try {
                    addToCollection(collection);
                    store.dispatch("fetchCollections");
                    router.push({
                      name: "screenshots in collection",
                      params: { collectionId: collection.id, pageNumber: 1 },
                    });
                  } catch (error) {
                    console.error(error);
                  }
                }
              );
            } catch (error) {
              console.error(error);
            }
          }
        );
      } catch (error) {
        console.error(error);
      }
    }

    function addUniqueCollectionName(baseName: any) {
      let counter = 1;
      let potentialName = baseName;

      while (true) {
        // Use the `some` method to check if the name exists in the array.
        let exists = personalCollections.value?.some(
          (collection) => collection.name == potentialName
        );

        if (!exists) {
          break; // If the name doesn't exist, break out of the loop.
        }

        potentialName = `${baseName} ${counter}`; // If the name exists, append the counter and loop again.
        counter++;
      }

      // Use the determined unique name as a const.
      const uniqueName = potentialName;

      // Push the new name to the collectionsay.
      return uniqueName;
    }

    const collectionMenuItems = computed(() => {
      return personalCollections.value
        ?.map((collection) => ({
          label: collection.screenshots
            ? collection.screenshots?.includes(props.screenshot.id)
              ? `${collection.name} (${collection.screenshots.length}) (added)`
              : `${collection.name} (${collection.screenshots.length})`
            : `${collection.name} (${collection.screenshots.length})`,
          command: () => {
            collection.screenshots
              ? collection.screenshots?.includes(props.screenshot.id)
                ? removeFromCollection(collection)
                : addToCollection(collection)
              : addToCollection(collection);
          },
        }))
        .concat([
          { separator: personalCollections.value.length > 0 ? true : false },
          {
            label: "Create new collection",
            command: () => {
              createCollection();
            },
          },
        ]);
    });

    const checkedScreenshots = computed(() => store.state.checkedScreenshots);
    const user = computed(() => store.state.user);
    const allTags = computed(() => store.state.tags);
    const userFlows = computed(() => store.state.userFlows);
    const draftUserFlows = computed(() => store.state.draftUserFlows);
    const personalCollections = computed(() => store.state.personalCollections);

    return {
      checkedScreenshots,
      user,
      collectionMenuItems,
      allTags,
      userFlows,
      draftUserFlows,
      removeFromCollection,
      personalCollections,
      addToCollection,
    };
  },
  computed: {
    checkedScreenshotsReady: {
      get() {
        return this.$store.state.checkedScreenshots
          ? this.$store.state.checkedScreenshots
          : [];
      },
      set(value) {
        this.$store.commit("setCheckedScreenshots", value);
      },
    },
    screenshotCollection() {
      return this.collections.find((collection) => {
        return collection.screenshots.includes(this.screenshot.id);
      });
    },
    sortedTags() {
      if (this.$route.query?.tag) {
        const activeTagName = this.$route.query?.tag;
        return this.tags?.sort((a, b) => {
          // If a's key matches the valueToMatchFirst and b's key does not, a comes first
          if (
            activeTagName.includes(a.name) &&
            !activeTagName.includes(b.name)
          ) {
            return -1;
          }
          // If b's key matches the valueToMatchFirst and a's key does not, b comes first
          if (
            activeTagName.includes(b.name) &&
            !activeTagName.includes(a.name)
          ) {
            return 1;
          }
          // If neither or both match, their order does not change
          return 0;
        });
      } else {
        return this.tags;
      }
    },
  },
  data() {
    return {
      modules: [Navigation],
      generatingTags: ref(false),
      auth: <any>inject("auth"),
      tags: ref(),
      showModal: ref(false),
      allFoundTags: ref(),
      searchQuery: ref(),
      showSearchDropdown: ref(false),
      tooltipList: ref(),
      tagsDataReady: ref(false),
      showDeleteDropdown: ref(false),
      router: useRouter(),
      route: useRoute(),
      previousScreenshot: ref(),
      previousScreenshotTags: ref(),
      store: useStore(),
      menu: ref(),
      newCollectionName: ref("New collection"),
      menuItems: ref([
        {
          label: "Delete",
          command: () => {
            this.deleteScreenshot(this.screenshot.id);
          },
        },
      ]),
      collectionMenuItems: ref(this.collectionMenuItems),
      product: ref(),
    };
  },
  created() {
    this.$watch(
      () => this.screenshot.id,
      async () => {
        this.setPreviousScreenshot();
        this.getTags();
        this.getPreviousScreenshotTags();
        this.getPreviousScreenshotTags();
      }
    );
  },
  async mounted() {
    this.setPreviousScreenshot();
    this.getData();
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl, {
        trigger: "hover",
      });
    });
  },
  beforeUnmount() {
    this.tooltipList.forEach((tooltip: any) => {
      tooltip.dispose();
    });
  },
  methods: {
    toggleScreenshotCheck(id) {
      if (this.user.isSubscribed) {
        const index = this.checkedScreenshotsReady.indexOf(id);
        if (index > -1) {
          this.checkedScreenshotsReady.splice(index, 1);
        } else {
          this.checkedScreenshotsReady.push(id);
        }
      }
    },
    ago(date: any) {
      return moment(Number(date)).fromNow();
    },
    toggleCollection(event) {
      if (this.collection) {
        this.removeFromCollection(this.collection);
      } else {
        this.toggleCollectionMenu(event);
      }
    },
    async downloadImage(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);

        const anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = `${this.product.title} - ${this.screenshot.title}.jpg`; // Make sure `this.screenshot.title` is available

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        // It's a good practice to release the object URL after use
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error("Failed to download image:", error);
      }
    },
    async copyImageToClipboard(url) {
      if (typeof ClipboardItem === "undefined") {
        notificationService.addNotification("Clipboard API not supported");
        return;
      }

      try {
        const image = await this.fetchImage(url);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        // Convert canvas to blob
        canvas.toBlob(async (blob) => {
          const item = new ClipboardItem({ "image/png": blob });
          await navigator.clipboard.write([item]);
          notificationService.addNotification("Copied to clipboard");
        }, "image/png");
      } catch (error) {
        console.error(error);
      }
    },
    fetchImage(url) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = "anonymous"; // This should help with CORS when drawing to canvas
        image.onload = () => resolve(image);
        image.onerror = reject;
        image.src = url;
      });
    },
    async generateTags(imageUrl) {
      this.generatingTags = true;
      const openai = new OpenAI({
        apiKey: "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",
        dangerouslyAllowBrowser: true,
      });

      try {
        await await openai.chat.completions
          .create({
            model: "gpt-4-vision-preview",
            messages: [
              {
                role: "user",
                content: [
                  {
                    type: "text",
                    text: `
The GPT's primary role is to assist users by analyzing screenshots of user interfaces (UI) and listing out the UI elements, UI sections, and the page name in a flat array of strings (maximum 3 words, no additional text or questions, straight to the answer).

This requires the GPT to have a keen eye for detail and an understanding of UI design principles to accurately identify elements in the interface. It should provide concise and precise listings, ensuring clarity and ease of understanding for the user. It should avoid making assumptions about the functionality of the UI elements or the intentions behind the design, focusing solely on identification based on context withing the screenshot, especially careful when identifying 'Modal' and 'Tooltip' UI elements. Modal elements count as modal onl with overlays behind them. Tooltips are tooltips only when having pointy arrows / triangles coming from them. It should only highlight those UI elements that are unique and of interest when looking for UI inspirations, avoiding basic elements without context like 'text', 'button', 'primary button', 'secondary button', 'link', 'hyperlink', 'logo' etc.

The GPT's responses should be tailored to be helpful for designers, developers, or anyone working with UI, providing them with a clear list of the elements visible in the screenshot.

Outcome of the ask should allways be a flat array with strings.

Every list should start with a name for screenshot's page, meaning that every answer should start with array where first string is the best attempt at naming the page for the screenshot, without calling out product in the name in a format 'name' + page. For context, this specific screenshot is called ${this.screenshot.title}.

When seeing form elements in UI, list named form with best attempt at naming form (e.g. Registration form, checkout form, login form, etc.)

When listing UI elements, make sure important variations are noted (e.g. not just Text input, but also Text input, Date input, Number input, Autocomplete, Select, etc.)

When listing UI elements, don't quote labels or add further information other than UI (e.g. Checkbox instead of Checkbox:select all).

Do not break down elements into smaller parts (e.g. Modal instead of Modal header, Modal body and Modal footer)

When listing UI element with dropdown, only list it when dropdown menu is open.

Do not repeat strings in a list of array response.

Images added in knowledge are examples of screenshots that will be used for UI analysis. Ignore browser navigation bar at the top including other UI elements aruond it at the very top.`,
                  },
                  {
                    type: "image_url",
                    image_url: {
                      url: imageUrl,
                    },
                  },
                ],
              },
            ],
            max_tokens: 300,
          })
          .then((res: any) => {
            const response = res;
            this.addGeneratedTags(
              JSON.parse(response.choices[0].message.content)
            );
          });
      } catch (error) {
        this.generatingTags = false;
        console.error(error);
      }
    },
    async addGeneratedTags(generatedTags: any) {
      // Loop through each generated tag
      for (let generatedTag of generatedTags) {
        generatedTag = this.capitalize(generatedTag);
        if (this.nameExistsInArray(generatedTag, this.allTags)) {
          this.addTags(
            this.allTags.find((tag: any) => tag.name == generatedTag).id
          );
        } else {
          this.addNewTag(generatedTag);
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      this.store.dispatch("fetchTags");
      this.generatingTags = false;
    },
    capitalize(str) {
      if (str && typeof str === "string") {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      }
      return "";
    },
    nameExistsInArray(nameToCheck, array) {
      return array?.some(function (item) {
        return item.name === nameToCheck;
      });
    },
    addUniqueCollectionName(baseName: any) {
      let counter = 1;
      let potentialName = baseName;

      while (true) {
        // Use the `some` method to check if the name exists in the array.
        let exists = this.collections?.some(
          (collection) => collection.name == potentialName
        );

        if (!exists) {
          break; // If the name doesn't exist, break out of the loop.
        }

        potentialName = `${baseName} ${counter}`; // If the name exists, append the counter and loop again.
        counter++;
      }

      // Use the determined unique name as a const.
      const uniqueName = potentialName;

      // Push the new name to the collectionsay.
      return uniqueName;
    },
    async createNewCollection() {
      const newCollection = {
        name: this.addUniqueCollectionName(this.newCollectionName),
        userId: null,
      };
      newCollection.userId = this.user.data?.uid;
      try {
        await createCollectionItem("collections", newCollection).then(
          async (res) => {
            try {
              await getCollectionItemById("collections", res).then(
                (collection) => {
                  this.addToCollection(collection);
                  this.store.dispatch("fetchCollections");
                }
              );
            } catch (error) {
              console.error(error);
            }
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    addedToCollection() {
      return this.collections
        ?.filter((collection: any) => {
          return collection?.userId == this.user.data?.uid;
        })
        ?.some((collection) => {
          return collection.screenshots.includes(this.screenshot.id);
        });
    },
    toggleMenu(event) {
      if (this.$refs.menu) this.$refs.menu.toggle(event);
    },
    toggleCollectionMenu(event) {
      if (this.personalCollections && this.personalCollections.length > 0) {
        if (this.$refs.collectionMenu) this.$refs.collectionMenu.toggle(event);
      } else {
        this.createNewCollection();
      }
    },
    async copyPreviousTags(tags: any) {
      this.resetSearch();

      const screenshotTags = [];

      tags.forEach((tag) => {
        if (!this.screenshot.tags || !this.screenshot.tags.includes(tag.id)) {
          screenshotTags.push(tag.id);
        }
      });

      let newScreenshot = { ...this.screenshot };
      newScreenshot.tags = newScreenshot.tags
        ? [...newScreenshot.tags, ...screenshotTags]
        : [...screenshotTags];

      try {
        await updateCollectionItem(
          "screenshots",
          newScreenshot.id,
          newScreenshot
        ).then(() => {
          this.screenshot.tags = this.screenshot.tags
            ? [...this.screenshot.tags, ...screenshotTags]
            : [...screenshotTags];
          this.getTags();
          this.getPreviousScreenshotTags();
          this.focusOnCategoryInput();
        });
      } catch (error) {
        console.error(error);
      }
    },
    setPreviousScreenshot() {
      this.previousScreenshot =
        this.screenshots && this.index > 0
          ? this.screenshots[this.index - 1]
          : null;
    },
    closeDropdown() {
      this.showSearchDropdown = false;
      this.searchQuery = null;
      this.showModal = false;
    },
    existsOnScreenshot(tag: any) {
      return this.screenshot.tags
        ? this.screenshot.tags.includes(tag.id)
          ? true
          : false
        : false;
    },
    async updateScreenshot($event) {
      const title = $event.target.innerText;

      const newScreenshot = { ...this.screenshot };
      newScreenshot.title = title;

      try {
        await updateCollectionItem(
          "screenshots",
          newScreenshot.id,
          newScreenshot
        ).then(() => {
          notificationService.addNotification("Screenshot title udpated");
          this.store.dispatch("fetchScreenshots");
        });
      } catch (error) {
        console.error(error);
      }
    },
    async search() {
      this.showSearchDropdown = true;
      try {
        await searchCollectionItems(
          "tags",
          1000,
          1,
          this.searchQuery,
          "name",
          undefined,
          "name",
          "asc",
          "name"
        ).then((res: any) => {
          this.allFoundTags = res.items;
        });
      } catch (error) {}
    },
    resetSearch() {
      this.searchQuery = "";
      this.showSearchDropdown = false;
    },
    async addTag(id: any) {
      this.resetSearch();
      let newScreenshot = { ...this.screenshot };
      newScreenshot.tags = newScreenshot.tags
        ? [...newScreenshot.tags, id]
        : [id];

      try {
        await updateCollectionItem(
          "screenshots",
          newScreenshot.id,
          newScreenshot
        ).then(() => {
          this.screenshot.tags = this.screenshot.tags
            ? [...this.screenshot.tags, id]
            : [id];
          this.getTags();
          this.getPreviousScreenshotTags();
          if (this.showModal) {
            this.focusOnCategoryInput();
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async addTags(idsArray: any) {
      this.resetSearch();
      let newScreenshot = { ...this.screenshot };
      newScreenshot.tags = newScreenshot.tags
        ? [...newScreenshot.tags, idsArray]
        : [idsArray];

      try {
        await updateCollectionItem(
          "screenshots",
          newScreenshot.id,
          newScreenshot
        ).then(() => {
          this.screenshot.tags = this.screenshot.tags
            ? [...this.screenshot.tags, idsArray]
            : [idsArray];
          this.getTags();
          this.getPreviousScreenshotTags();
          if (this.showModal) {
            this.focusOnCategoryInput();
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async addNewTag(tagName: string) {
      let foundCategories: any = [];
      this.allTags.forEach((tag: any) => {
        foundCategories.push(tag.name);
      });

      if (!foundCategories.includes(tagName)) {
        const tag = { name: tagName };
        try {
          await createCollectionItem("tags", tag).then((id: any) => {
            this.store.dispatch("fetchTags");
            this.tags.push({ ...tag, id });
            this.addTag(id);
          });
        } catch (error) {}
      }
    },
    focusOnCategoryInput() {
      setTimeout(() => {
        const tagInput = this.$refs.tagInput as HTMLInputElement;
        tagInput.focus();
      }, 100);
    },
    showModalFunction() {
      this.showModal = true;
      this.getPreviousScreenshot();
      this.focusOnCategoryInput();
    },
    async getPreviousScreenshot() {
      if (this.previousScreenshot) {
        await getCollectionItemById(
          "screenshots",
          this.previousScreenshot.id
        ).then((res: any) => {
          this.previousScreenshot = res;
          this.getPreviousScreenshotTags();
        });
      }
    },
    async removeTag(event: Event, id: any) {
      event.preventDefault();
      const newScreenshot = { ...this.screenshot };

      newScreenshot.tags = newScreenshot.tags.filter((tag: any) => tag != id);

      try {
        await updateCollectionItem(
          "screenshots",
          newScreenshot.id,
          newScreenshot
        ).then(() => {
          this.screenshot.tags = this.screenshot.tags.filter(
            (tag: any) => tag != id
          );
          this.getTags();
        });
      } catch (error) {}
    },
    async deleteScreenshot(id: any) {
      try {
        await deleteCollectionItem("screenshots", id).then(() => {
          notificationService.addNotification("Screenshot deleted");
          this.store.dispatch("fetchScreenshots");
        });
      } catch (error) {
        console.error(error);
      }
    },
    getData() {
      this.getProduct();
      if (this.screenshot.tags) {
        this.getTags();
        this.getPreviousScreenshotTags();
      } else {
        this.tagsDataReady = true;
      }
    },
    async getProduct() {
      try {
        this.product = await getCollectionItemById(
          "products",
          this.screenshot.productId
        );
      } catch (error) {
        console.error(error);
      }
    },
    async getTags() {
      this.tags = null;
      if (this.screenshot.tags && this.screenshot.tags.length > 0) {
        try {
          await getCollectionItemsByIds("tags", this.screenshot.tags).then(
            (res: any) => {
              this.tags = res;
            }
          );
        } catch (error) {
          console.error(error);
        }
      } else {
        this.tags = null;
      }
    },
    async getPreviousScreenshotTags() {
      if (
        this.previousScreenshot &&
        this.previousScreenshot.tags &&
        this.previousScreenshot.tags.length > 0
      ) {
        try {
          await getCollectionItemsByIds(
            "tags",
            this.previousScreenshot.tags
          ).then((res: any) => {
            this.previousScreenshotTags = res;
          });
        } catch (error) {
          console.error(error);
        }
      } else {
        this.previousScreenshotTags = null;
      }
    },
  },
});
