
import { defineComponent } from "vue";
import Icon from "./Icon.vue";
import ProductLogo from "./ProductLogo.vue";
import VueWordHighlighter from "vue-word-highlighter";
import mixpanel from "mixpanel-browser";

export default defineComponent({
  props: [
    "style",
    "label",
    "icon",
    "size",
    "disabled",
    "tabindex",
    "shortcut",
    "type",
    "dissmisible",
    "iconPosition",
    "iconWeight",
    "iconSize",
    "image",
    "backgroundColor",
    "iconStyle",
    "buttonClasses",
    "highlightQuery",
  ],
  emits: ["dismiss"],
  components: { Icon, ProductLogo, VueWordHighlighter },
  methods: {
    buttonClickEvent() {
      mixpanel.track("Event", {
        Event: "Button clicked",
        Label: this.label ? this.label : null,
        Icon: this.icon ? this.icon : null,
      });
    },
  },
});
