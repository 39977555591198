import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";

function convertDataUrlToFile(dataUrl: string, fileType: string): File {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const fileName = `${fileType}_${Date.now()}`; // Generate a unique filename based on the file type and current timestamp
  return new File([u8arr], fileName, { type: mime });
}

// Function to generate a random string
function generateRandomString(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function updateCollectionItem(
  collectionName: string,
  id: string,
  data: any
) {
  return new Promise<void>((resolve, reject) => {
    const storageRef = firebase.storage().ref();
    const collectionRef = firebase.firestore().collection(collectionName);

    const uploadPromises = [];

    data.updated = new Date().valueOf();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];

        if (value instanceof File || value instanceof Blob) {
          // File is selected, upload it to storage
          const file = value;
          const fileType = key === "video" ? "video" : "image"; // Determine the file type based on the key
          const randomString = generateRandomString(10); // Generate a random string
          const fileName = `${collectionName}/${randomString}_${
            data.title
          }_${fileType}_${file.name}${fileType == "video" ? ".mp4" : ""}`; // Include the random string, file type, and other details in the filename

          const fileRef = storageRef.child(fileName);

          const metadata = {
            cacheControl: "public,max-age=604800",
          };

          uploadPromises.push(
            fileRef
              .put(file, metadata)
              .then(() => fileRef.getDownloadURL())
              .then((fileUrl) => {
                data[key] = {
                  url: fileUrl,
                  path: fileName, // Add the file path to the data object
                };
              })
          );
        } else if (
          typeof value === "string" &&
          (value.startsWith("data:image") || value.startsWith("data:video"))
        ) {
          // Data URL is an image or video, convert and upload it to storage
          const fileType =
            value.startsWith("data:image") || value.startsWith("blob:")
              ? "image"
              : "video"; // Determine the file type based on the data URL
          const file = convertDataUrlToFile(value, fileType);
          const randomString = generateRandomString(10); // Generate a random string
          const fileName = `${collectionName}/${randomString}_${
            data.title
          }_${fileType}_${file.name}${fileType == "video" ? ".mp4" : ""}`; // Include the random string, file type, and other details in the filename
          const fileRef = storageRef.child(fileName);

          const metadata = {
            cacheControl: "public,max-age=604800",
          };

          uploadPromises.push(
            fileRef
              .put(file, metadata)
              .then(() => fileRef.getDownloadURL())
              .then((fileUrl) => {
                data[key] = {
                  url: fileUrl,
                  path: fileName, // Add the file path to the data object
                };
              })
          );
        }
      }
    }

    Promise.all(uploadPromises)
      .then(() => {
        // All files have been uploaded, update the document in the collection
        return collectionRef.doc(id).update(data);
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sendEmail(data) {
  return new Promise((resolve, reject) => {
    const mailRef = firebase.firestore().collection("mail");

    // Add timestamp to the data
    data.sentAt = new Date().valueOf();

    // Validate and prepare the email data
    if (!data.to || !data.message) {
      reject("Email data is incomplete");
      return;
    }

    // If there are attachments or other complex data handling, add here
    // ...

    // Write the email data to the 'mail' collection
    mailRef
      .add(data)
      .then((docRef) => {
        resolve(docRef.id); // Resolve with the document ID
      })
      .catch((error) => {
        reject(error); // Reject the promise if there's an error
      });
  });
}

export function createCollectionItem(collectionName: string, data: any) {
  return new Promise((resolve, reject) => {
    const storageRef = firebase.storage().ref();
    const collectionRef = firebase.firestore().collection(collectionName);

    const uploadPromises = [];

    data.created = new Date().valueOf();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];

        if (value instanceof File || value instanceof Blob) {
          // File is selected, upload it to storage
          const file = value;
          const fileType = key === "video" ? "video" : "image"; // Determine the file type based on the key
          const randomString = generateRandomString(10); // Generate a random string
          const fileName = `${collectionName}/${randomString}_${
            data.title
          }_${fileType}_${file.name}${fileType == "video" ? ".mp4" : ""}`; // Include the random string, file type, and other details in the filename

          const fileRef = storageRef.child(fileName);

          const metadata = {
            cacheControl: "public,max-age=604800",
          };

          uploadPromises.push(
            fileRef
              .put(file, metadata)
              .then(() => fileRef.getDownloadURL())
              .then((fileUrl) => {
                data[key] = {
                  url: fileUrl,
                  path: fileName, // Add the file path to the data object
                };
              })
          );
        } else if (
          typeof value === "string" &&
          (value.startsWith("data:image") || value.startsWith("data:video"))
        ) {
          // Data URL is an image or video, convert and upload it to storage
          const fileType =
            value.startsWith("data:image") || value.startsWith("blob:")
              ? "image"
              : "video"; // Determine the file type based on the data URL
          const file = convertDataUrlToFile(value, fileType);
          const randomString = generateRandomString(10); // Generate a random string
          const fileName = `${collectionName}/${randomString}_${
            data.title
          }_${fileType}_${file.name}${fileType == "video" ? ".mp4" : ""}`; // Include the random string, file type, and other details in the filename
          const fileRef = storageRef.child(fileName);

          const metadata = {
            cacheControl: "public,max-age=604800",
          };

          uploadPromises.push(
            fileRef
              .put(file, metadata)
              .then(() => fileRef.getDownloadURL())
              .then((fileUrl) => {
                data[key] = {
                  url: fileUrl,
                  path: fileName, // Add the file path to the data object
                };
              })
          );
        }
      }
    }

    Promise.all(uploadPromises)
      .then(() => {
        // All files have been uploaded, create the document in the collection
        return collectionRef.add(data);
      })
      .then((docRef) => {
        resolve(docRef.id);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteCollectionItem(collectionName: string, id: string) {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const collectionRef = firebase.firestore().collection(collectionName);
      const documentRef = collectionRef.doc(id);

      // Get the document data
      const documentSnapshot = await documentRef.get();
      const documentData = documentSnapshot.data();

      if (!documentData) {
        throw new Error("Item not found");
      }

      // Delete associated storage items
      const storageRef = firebase.storage().ref();
      const storagePromises = [];

      for (const key in documentData) {
        if (documentData.hasOwnProperty(key)) {
          const value = documentData[key];

          if (value && value.url && value.path) {
            const fileRef = storageRef.child(value.path);

            // Check if the storage item exists before attempting deletion
            const fileSnapshot = await fileRef.getMetadata().catch(() => null);

            if (fileSnapshot) {
              storagePromises.push(fileRef.delete());
            } else {
              console.warn("Storage item not found:", value.path);
            }
          }
        }
      }

      // Wait for all storage items to be deleted
      await Promise.all(storagePromises);

      // Delete the document from the collection
      await documentRef.delete();

      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

export function getCollectionItemById(collectionName: string, id: any) {
  return new Promise((resolve, reject) => {
    const collectionRef = firebase
      .firestore()
      .collection(collectionName)
      .doc(id);
    collectionRef
      .get({ source: "cache" }) // Try to use cache first
      .then((doc) => {
        if (doc.exists) {
          const item = doc.data();
          resolve({ id, ...item });
        } else {
          // Cache didn't have data, fetch from server
          collectionRef
            .get() // Attempt to retrieve from server without specifying source
            .then((networkDoc) => {
              if (networkDoc.exists) {
                const item = networkDoc.data();
                resolve({ id, ...item });
              } else {
                resolve(null); // Both cache and server don't have data
              }
            })
            .catch((error) => {
              reject(error);
            });
        }
      })
      .catch((error) => {
        // Retry without specifying source to attempt retrieving from server
        collectionRef
          .get()
          .then((networkDoc) => {
            if (networkDoc.exists) {
              const item = networkDoc.data();
              resolve({ id, ...item });
            } else {
              resolve(null); // Both cache and server don't have data
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
  });
}

export function getCollectionItemsByIds(collectionName: string, ids: any[]) {
  return new Promise((resolve, reject) => {
    const collectionRef = firebase.firestore().collection(collectionName);
    const promises = ids.map((id) =>
      collectionRef.doc(id).get({ source: "cache" })
    );

    Promise.all(promises)
      .then((docs) => {
        const items: any = [];
        docs.forEach((doc, index) => {
          const id = ids[index];
          if (doc.exists) {
            const item = doc.data();
            items.push({ id, ...item });
          } else {
            // Cache didn't have data, fetch from server
            collectionRef
              .doc(id)
              .get()
              .then((networkDoc) => {
                if (networkDoc.exists) {
                  const item = networkDoc.data();
                  items.push({ id, ...item });
                } else {
                  items.push(null); // Both cache and server don't have data
                }

                // Check if all promises have been resolved
                if (items.length === ids.length) {
                  resolve(items);
                }
              })
              .catch((error) => {
                reject(error);
              });
          }
        });

        // Check if all promises have been resolved
        if (items.length === ids.length) {
          resolve(items);
        }
      })
      .catch((error) => {
        // Retry without specifying source to attempt retrieving from server
        const networkPromises = ids.map((id) => collectionRef.doc(id).get());
        Promise.all(networkPromises)
          .then((networkDocs) => {
            const items: any = [];
            networkDocs.forEach((networkDoc, index) => {
              const id = ids[index];
              if (networkDoc.exists) {
                const item = networkDoc.data();
                items.push({ id, ...item });
              }

              resolve(items);
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
  });
}

export async function fetchData(
  collectionName: string,
  limit: number,
  pageNumber: number = 1,
  orderByField: string,
  orderByDirection: "asc" | "desc",
  filters?: { field: string; value: any | any[] }[],
  lastDocSnapshot = null
): Promise<{
  items: any[];
  lastDocSnapshot: firebase.firestore.DocumentSnapshot | null;
  hasMore: boolean;
}> {
  try {
    const firestore = firebase.firestore();
    let baseQuery = firestore
      .collection(collectionName)
      .orderBy(orderByField, orderByDirection);

    // Apply filters
    filters?.forEach((filter) => {
      if (Array.isArray(filter.value)) {
        if (
          (filter.field === "categories" ||
            filter.field === "tags" ||
            filter.field === "collectionRelatedTags") &&
          filter.value.length <= 10
        ) {
          baseQuery = baseQuery.where(
            filter.field,
            "array-contains-any",
            filter.value
          );
        } else if (filter.value.length <= 10) {
          baseQuery = baseQuery.where(filter.field, "in", filter.value);
        } else {
          console.warn(
            "Filter arrays with more than 10 items are not supported."
          );
        }
      } else {
        baseQuery = baseQuery.where(filter.field, "==", filter.value);
      }
    });

    // Adjust for pagination
    let queryForPage = baseQuery.limit(limit);
    if (lastDocSnapshot) {
      queryForPage = queryForPage.startAfter(lastDocSnapshot);
    }

    const pageSnapshot = await queryForPage.get();
    const items = pageSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Capture the last document snapshot from the current query result
    const newLastDocSnapshot =
      pageSnapshot.docs[pageSnapshot.docs.length - 1] || null;
    const hasMore = pageSnapshot.docs.length === limit;

    // Return both the items and the newLastDocSnapshot for future queries
    return { items, lastDocSnapshot: newLastDocSnapshot, hasMore };
  } catch (error) {
    console.error("Error fetching filtered paginated collection data:", error);
    throw error;
  }
}

export async function fetchAllCountsData(): Promise<any> {
  try {
    const firestore = firebase.firestore();
    const countsDocRef = firestore.collection("counts").doc("collectionsCount");
    const docSnapshot = await countsDocRef.get();

    if (!docSnapshot.exists) {
      console.log("No counts document found");
      return null;
    }

    return docSnapshot.data();
  } catch (error) {
    console.error("Error fetching counts data:", error);
    throw error;
  }
}

export function searchCollectionItems(
  collectionName: string,
  limit: number = 9,
  pageNumber: number = 1,
  searchQuery?: string,
  fieldName?: string,
  filter?: Record<string, any> | Record<string, any>[],
  orderByField?: string,
  orderByDirection?: "asc" | "desc",
  uniqueKey?: string // Added optional parameter
) {
  return new Promise((resolve, reject) => {
    const collectionRef = firebase.firestore().collection(collectionName);
    const searchLowerCase = searchQuery ? searchQuery.toLowerCase() : "";

    let query: firebase.firestore.Query<firebase.firestore.DocumentData> =
      collectionRef;

    if (Array.isArray(filter)) {
      // If filter is an array of objects, create a compound query
      filter.forEach((filterObj) => {
        query = query.where(filterObj.key, "==", filterObj.value);
      });
    } else if (filter) {
      // If filter is a single object, create a simple query
      query = query.where(filter.key, "==", filter.value);
    }

    // Perform the search query to retrieve all items that match the search query
    query
      .get()
      .then((querySnapshot) => {
        const items: any[] = [];
        const uniqueValues: Set<string> = new Set(); // Create a set to store unique values

        querySnapshot.forEach((doc) => {
          const item = doc.data();
          const fieldValue = fieldName
            ? (item[fieldName] || "").toLowerCase()
            : "";
          if (fieldValue.includes(searchLowerCase)) {
            const id = doc.id;
            const uniqueKeyValue = item[uniqueKey as string];

            if (
              uniqueKey &&
              uniqueKeyValue &&
              !uniqueValues.has(uniqueKeyValue)
            ) {
              uniqueValues.add(uniqueKeyValue);

              if (orderByField && orderByField in item) {
                items.push({ id, ...item });
              }
            } else if (!uniqueKey) {
              items.push({ id, ...item });
            }
          }
        });

        // Sort items based on orderByField and orderByDirection
        items.sort((a, b) => {
          if (orderByField && orderByField in a && orderByField in b) {
            if (orderByDirection === "desc") {
              return b[orderByField] - a[orderByField];
            } else {
              return a[orderByField] - b[orderByField];
            }
          } else {
            // Move items without orderByField to the end
            if (!(orderByField! in a)) return 1;
            if (!(orderByField! in b)) return -1;
            return 0;
          }
        });

        const totalItems = items.length;

        // Apply pagination by slicing the items array based on the limit and pageNumber
        const startIndex = (pageNumber - 1) * limit;
        const endIndex = startIndex + limit;
        const paginatedItems = items.slice(startIndex, endIndex);

        resolve({ items: paginatedItems, totalItems });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function searchRelatedCollectionItems(
  collectionName: string,
  idArrayKey: string,
  relatedId: string,
  limit: number = 9,
  pageNumber: number = 1,
  orderByField?: string,
  orderByDirection?: "asc" | "desc",
  uniqueKey?: string
) {
  const collectionRef = firebase.firestore().collection(collectionName);
  let query: firebase.firestore.Query<firebase.firestore.DocumentData> =
    collectionRef;

  query = query.where(idArrayKey, "array-contains", relatedId);

  return query
    .get()
    .then((querySnapshot) => {
      const items: any[] = [];
      const uniqueValues: Set<string> = new Set();

      querySnapshot.forEach((doc) => {
        const item = doc.data();
        const id = doc.id;
        const uniqueKeyValue = item[uniqueKey as string];
        if (uniqueKey && uniqueKeyValue && !uniqueValues.has(uniqueKeyValue)) {
          uniqueValues.add(uniqueKeyValue);
          items.push({ id, ...item });
        } else if (!uniqueKey) {
          items.push({ id, ...item });
        }
      });

      if (orderByField) {
        items.sort((a, b) => {
          if (a[orderByField] === undefined && b[orderByField] === undefined)
            return 0;
          if (a[orderByField] === undefined) return 1;
          if (b[orderByField] === undefined) return -1;

          if (orderByDirection === "asc") {
            if (
              typeof a[orderByField] === "number" &&
              typeof b[orderByField] === "number"
            ) {
              return a[orderByField] - b[orderByField];
            } else {
              return String(a[orderByField]).localeCompare(
                String(b[orderByField])
              );
            }
          } else {
            if (
              typeof a[orderByField] === "number" &&
              typeof b[orderByField] === "number"
            ) {
              return b[orderByField] - a[orderByField];
            } else {
              return String(b[orderByField]).localeCompare(
                String(a[orderByField])
              );
            }
          }
        });
      }

      const totalItems = items.length;
      const startIndex = (pageNumber - 1) * limit;
      const endIndex = startIndex + limit;
      const paginatedItems = items.slice(startIndex, endIndex);

      return { items: paginatedItems, totalItems };
    })
    .catch((error) => {
      throw error;
    });
}

export function getCollectionItemByKeyValue(
  collectionName: string,
  key: any,
  value: any,
  orderByField?: string,
  orderByDirection?: "asc" | "desc"
) {
  return new Promise((resolve, reject) => {
    const collectionRef = firebase.firestore().collection(collectionName);
    let query = collectionRef.where(key, "==", value).limit(1);

    if (orderByField) {
      query = query.orderBy(orderByField, orderByDirection);
    }

    query
      .get({ source: "cache" }) // Try to use cache first
      .then((snapshot) => {
        if (!snapshot.empty) {
          const item = snapshot.docs[0];
          const data = item.data();
          const id = item.id;
          resolve({ id, ...data });
        } else {
          // Cache didn't have data, try fetching from network
          query
            .get()
            .then((networkSnapshot) => {
              if (!networkSnapshot.empty) {
                const item = networkSnapshot.docs[0];
                const data = item.data();
                const id = item.id;
                resolve({ id, ...data });
              } else {
                reject(new Error("Item not found"));
              }
            })
            .catch((error) => {
              reject(error);
            });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCollectionItemsByKeyValue(
  collectionName: string,
  filter: Record<string, any> | Record<string, any>[],
  limit: number = 12,
  pageNumber: number = 1,
  orderByField?: string,
  orderByDirection?: "asc" | "desc"
) {
  return new Promise((resolve, reject) => {
    const collectionRef = firebase.firestore().collection(collectionName);
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> =
      collectionRef;

    if (Array.isArray(filter)) {
      // If filter is an array of objects, create a compound query
      filter.forEach((filterObj) => {
        query = query.where(filterObj.key, "==", filterObj.value);
      });
    } else {
      // If filter is a single object, create a simple query
      query = query.where(filter.key, "==", filter.value);
    }

    if (orderByField) {
      query = query.orderBy(orderByField, orderByDirection);
    }

    // Retrieve all items that match the filter
    query
      .get()
      .then((snapshot) => {
        const totalItems = snapshot.size;

        // Apply pagination using startAfter
        let paginatedQuery = query;

        if (orderByField) {
          // If orderByField is provided, use it for pagination
          const lastVisible = snapshot.docs[limit * (pageNumber - 1) - 1];
          if (lastVisible) {
            paginatedQuery = query.startAfter(lastVisible.data()[orderByField]);
          }
        } else {
          // If orderByField is not provided, use document snapshots for pagination
          const startAtDoc = snapshot.docs[limit * (pageNumber - 1)];
          if (startAtDoc) {
            paginatedQuery = query.startAt(startAtDoc);
          }
        }

        paginatedQuery = paginatedQuery.limit(limit);

        // Fetch paginated items
        paginatedQuery
          .get()
          .then((paginatedSnapshot) => {
            const items: any[] = [];
            paginatedSnapshot.forEach((item) => {
              const data = item.data();
              const id = item.id;
              items.push({ id, ...data });
            });

            resolve({ items, totalItems });
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCollectionItemCountByKeyValue(
  collectionName: string,
  key: any,
  value: any
) {
  return new Promise((resolve, reject) => {
    const collectionRef = firebase.firestore().collection(collectionName);
    const query = collectionRef.where(key, "==", value);

    query
      .get({ source: "cache" }) // Try to use cache first
      .then((snapshot) => {
        if (!snapshot.empty) {
          const count = snapshot.size;
          resolve(count);
        } else {
          // Cache didn't have data, try fetching from network
          query
            .get()
            .then((networkSnapshot) => {
              const count = networkSnapshot.size;
              resolve(count);
            })
            .catch((error) => {
              reject(error);
            });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProductById(id: any) {
  return new Promise((resolve, reject) => {
    const collectionRef = firebase.firestore().collection("products").doc(id);

    collectionRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const product = doc.data();
          resolve(product);
        } else {
          reject(new Error("Product not found"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function searchScreenshots(
  limit: number = 9,
  pageNumber: number = 1,
  filter?: {
    device?: "mobile" | "desktop";
    product?: any;
    tag?: any;
    uniqueKey?: any;
  },
  orderBy?: { [key: string]: "asc" | "desc" }
) {
  try {
    const collectionRef = firebase.firestore().collection("screenshots");
    let query = applyFiltersAndOrdering(collectionRef);

    // Step 1: Fetch all items based on filters but not limit
    const allQuerySnapshot = await query.get();
    let allItems = handleQuerySnapshot(allQuerySnapshot);

    // Step 2: Apply uniqueKey filter if provided
    if (filter?.uniqueKey?.value) {
      const seen = new Set();
      allItems = allItems.filter((item) => {
        const key = item[filter?.uniqueKey?.value];
        if (seen.has(key)) {
          return false;
        }
        seen.add(key);
        return true;
      });
    }

    // Step 3: Calculate totalItems based on filtered unique items
    const totalItems = allItems.length;

    // Step 4: Apply pagination on filtered and unique items
    const start = (pageNumber - 1) * limit;
    const end = start + limit;
    const items = allItems.slice(start, end);

    return { items, totalItems };
  } catch (error) {
    throw error;
  }

  function applyFiltersAndOrdering(query: firebase.firestore.Query) {
    if (filter?.device === "mobile") {
      query = query.where("mobile", "==", true);
    } else if (filter?.device === "desktop") {
      query = query.where("mobile", "==", false);
    }
    if (filter?.product?.id) {
      query = query.where("productId", "==", filter.product.id);
    }
    if (filter?.tag?.id) {
      query = query.where("tags", "array-contains", filter.tag.id);
    }
    if (orderBy) {
      for (const [key, direction] of Object.entries(orderBy)) {
        query = query.orderBy(key, direction);
      }
    }
    query = query.orderBy(firebase.firestore.FieldPath.documentId());
    return query;
  }

  function handleQuerySnapshot(
    querySnapshot: firebase.firestore.QuerySnapshot
  ) {
    const items: any[] = [];
    querySnapshot.forEach((doc) => {
      const item = doc.data();
      const id = doc.id;
      items.push({ id, ...item });
    });
    return items;
  }
}

export function getItemsInCollection(
  collectionName: string,
  limit: number = 6,
  pageNumber: number = 1,
  orderByField?: string,
  orderByDirection?: "asc" | "desc"
) {
  return new Promise((resolve, reject) => {
    const collectionRef = firebase.firestore().collection(collectionName);

    let query: any = collectionRef;

    if (orderByField) {
      query = query.orderBy(orderByField, orderByDirection);
    }

    query
      .get()
      .then((snapshot: any) => {
        if (!snapshot.empty) {
          const items = snapshot.docs.map((doc: any) => {
            const data = doc.data();
            const id = doc.id;
            return { id, ...data };
          });

          const totalItems = items.length;

          // Apply pagination by slicing the items array based on the limit and pageNumber
          const startIndex = (pageNumber - 1) * limit;
          const endIndex = startIndex + limit;
          const paginatedItems = items.slice(startIndex, endIndex);

          resolve({ items: paginatedItems, totalItems });
        } else {
          resolve({ items: [], totalItems: 0 }); // Empty result when no items found
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
