import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["disabled", "tabindex", "type"]
const _hoisted_2 = {
  key: 0,
  class: "button__label overflow-hidden"
}
const _hoisted_3 = {
  key: 2,
  class: "button__shortcut p-1 px-2 border-rounded"
}
const _hoisted_4 = { class: "small" }
const _hoisted_5 = { class: "button__label button__label--outside" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductLogo = _resolveComponent("ProductLogo")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_VueWordHighlighter = _resolveComponent("VueWordHighlighter")!

  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex flex-column align-items-center justify-content-center overflow-hidden",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.buttonClickEvent && _ctx.buttonClickEvent(...args)))
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(["button btn d-flex gap-2 align-items-center w-100", [
        _ctx.buttonClasses,
        !_ctx.style ? 'btn-primary' : '',
        'btn-' + _ctx.style,
        'button--' + _ctx.style,
        _ctx.label ? 'button--with-label' : 'button--no-label p-2',
        'btn-' + (_ctx.size ? _ctx.size : 'medium'),
        'button--' + (_ctx.size ? _ctx.size : 'medium'),
        'button--' + (_ctx.dissmisible ? 'dissmisible' : 'not-dissmisible'),
        { 'flex-row-reverse': _ctx.iconPosition == 'right' },
      ]]),
      disabled: _ctx.disabled,
      style: _normalizeStyle({ 'background-color': _ctx.backgroundColor, 'max-height': '100%' }),
      tabindex: _ctx.tabindex,
      type: _ctx.type ? _ctx.type : 'button'
    }, [
      (_ctx.image)
        ? (_openBlock(), _createBlock(_component_ProductLogo, {
            key: 0,
            class: "me-n1",
            logo: _ctx.image
          }, null, 8, ["logo"]))
        : _createCommentVNode("", true),
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            class: _normalizeClass(["button__icon", _ctx.style == 'primary' ? 'text-white' : 'text-primary']),
            icon: _ctx.icon,
            weight: _ctx.iconWeight,
            size: _ctx.iconSize,
            style: _normalizeStyle(_ctx.iconStyle)
          }, null, 8, ["class", "icon", "weight", "size", "style"]))
        : _createCommentVNode("", true),
      _createVNode(_component_VueWordHighlighter, { query: _ctx.highlightQuery }, {
        default: _withCtx(() => [
          (_ctx.label)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["query"]),
      (_ctx.shortcut)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.shortcut), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.dissmisible)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 3,
            weight: _ctx.iconWeight,
            size: _ctx.iconSize,
            class: "button__icon-close",
            icon: 'close',
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dismiss')))
          }, null, 8, ["weight", "size"]))
        : _createCommentVNode("", true)
    ], 14, _hoisted_1),
    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.label), 1)
  ]))
}