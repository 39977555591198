
import VideoCard from "@/components/VideoCard.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import ScreenshotsComponent from "@/components/ScreenshotsComponent.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import ProductLogo from "@/components/ProductLogo.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import VideoCards from "@/components/VideoCards.vue";
import ProductsListComponent from "@/components/ProductsListComponent.vue";
import { computed, defineComponent, inject, ref } from "vue";
import { RouterLink, useRoute } from "vue-router";
import { deleteCollectionItem } from "@/services/FirebaseService";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import { useStore } from "@/services/Store";
import CardComponent from "./CardComponent.vue";
import Icon from "./Icon.vue";
import store from "@/store";
import moment from "moment";
import IconLabelComponent from "./IconLabelComponent.vue";
import DraggableScrollDirective from "@/directives/DraggableDirective";
import LoaderComponent from "./LoaderComponent.vue";

export default defineComponent({
  components: {
    VideoCard,
    FooterComponent,
    VideoPlayer,
    ScreenshotsComponent,
    RouterLink,
    ProductLogo,
    ButtonComponent,
    VideoCards,
    ProductsListComponent,
    QuillEditor,
    Swiper,
    SwiperSlide,
    CardComponent,
    Icon,
    IconLabelComponent,
    LoaderComponent,
  },
  directives: {
    draggableScroll: DraggableScrollDirective,
  },
  props: ["collections", "carousel", "hideEmptyMessage"],
  data() {
    return {
      auth: <any>inject("auth"),
      pageNumber: "1",
      searchQuery: ref(),
      modules: [Navigation],
      store: useStore(),
    };
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.state.user);

    return {
      user,
    };
  },
  methods: {
    ago(date: any) {
      return moment(date).fromNow();
    },
    getCollectionScreenshots(collection: any) {
      const numberOfCollectionScreenshots = this.screenshots?.filter(
        (screenshot) => {
          return collection.screenshots?.includes(screenshot.id);
        }
      );
      if (numberOfCollectionScreenshots == 0) {
        this.$router.push({ name: "collections main" });
        try {
          deleteCollectionItem("collections", collection.id).then(() => {
            this.store.dispatch("fetchCollections");
          });
        } catch (error) {}
      }
      return numberOfCollectionScreenshots;
    },
  },
});
