import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center gap-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueWordHighlighter = _resolveComponent("VueWordHighlighter")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!

  return (_ctx.tag)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(["d-flex align-items-center gap-1 py-1 rounded-5 tag-component text-nowrap border-box border", {
      'tag-component--active bg-primary text-white': _ctx.active,
      'bg-white': !_ctx.active,
      'pe-1': _ctx.allowClose,
    }])
      }, [
        _createElementVNode("small", _hoisted_1, [
          _createElementVNode("span", {
            class: _normalizeClass(["d-flex align-items-center px-2", { 'pe-1': _ctx.allowClose }])
          }, [
            _createVNode(_component_VueWordHighlighter, {
              query: _ctx.$route.params.s ? _ctx.$route.params.s as string : ''
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.tag.name), 1)
              ]),
              _: 1
            }, 8, ["query"])
          ], 2),
          (_ctx.allowClose)
            ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                key: 0,
                style: _normalizeStyle(_ctx.active ? 'dark' : 'light'),
                size: 'xs',
                class: "tag-component__icon cursor-pointer rounded-5 text-decoration-none",
                icon: 'times',
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.removeTag($event, _ctx.tag.id)), ["stop"]))
              }, null, 8, ["style"]))
            : _createCommentVNode("", true)
        ])
      ], 2))
    : _createCommentVNode("", true)
}