import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icon-label d-flex align-items-start gap-1" }
const _hoisted_2 = { style: { 'margin-top': '.3rem' } }
const _hoisted_3 = { class: "mb-0 icon-label__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Icon, {
        class: "text-primary",
        icon: _ctx.icon,
        weight: _ctx.iconWeight
      }, null, 8, ["icon", "weight"])
    ]),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.label), 1)
  ]))
}