import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-component__header d-flex align-items-center gap-1" }
const _hoisted_2 = { class: "d-flex flex-align-center gap-1 overflow-hidden position-relative z-1" }
const _hoisted_3 = ["id", "contenteditable"]
const _hoisted_4 = { class: "ms-auto" }
const _hoisted_5 = {
  key: 0,
  class: "card-component__body card-body d-flex flex-column gap-2 bg-transparent"
}
const _hoisted_6 = {
  key: 1,
  class: "card-component__footer d-flex border-top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueWordHighlighter = _resolveComponent("VueWordHighlighter")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card-component card d-flex w-100", {
      'card-component--transparent': _ctx.transparent,
      'card-component--not-transparent': !_ctx.transparent,
      'card-component--full-width-content': _ctx.fullWidthContent,
      'card-component--header-overlay': _ctx.headerOverlay,
      'card-component--footer-overlay': _ctx.footerOverlay,
      'overflow-visible': _ctx.transparent,
      'overflow-hidden': !_ctx.transparent,
    }])
  }, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "cardBeforeTitle"),
        _withDirectives(_createElementVNode("p", {
          id: _ctx.id,
          contenteditable: _ctx.titleEditable,
          class: _normalizeClass(["card-component__title fw-bold my-auto text-truncate", { 'cursor-pointer': _ctx.clickableTitle }]),
          onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.titleEditable && _ctx.updateTitle())),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('title-clicked')))
        }, [
          _createVNode(_component_VueWordHighlighter, {
            query: _ctx.$route.params.s ? _ctx.$route.params.s as string : ''
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.cardTitle), 1)
            ]),
            _: 1
          }, 8, ["query"])
        ], 42, _hoisted_3), [
          [_vShow, _ctx.cardTitle]
        ])
      ]),
      _renderSlot(_ctx.$slots, "cardAfterTitle"),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "cardActions")
      ])
    ], 512), [
      [_vShow, _ctx.cardTitle || _ctx.cardBeforeTitle || _ctx.cardAfterTitle]
    ]),
    (!_ctx.hideBody)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true),
    (_ctx.cardFooter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "cardFooter")
        ]))
      : _createCommentVNode("", true)
  ], 2))
}