import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center gap-2"
}
const _hoisted_2 = {
  key: 1,
  class: "mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (Math.ceil(_ctx.totalItems / _ctx.limit) > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.pageNumber > 1)
          ? (_openBlock(), _createBlock(_component_RouterLink, {
              key: 0,
              class: "page-link",
              to: _ctx.getModifiedRoute(_ctx.pageNumber - 1)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ButtonComponent, {
                  label: 'Previous',
                  style: 'primary',
                  size: 'sm'
                })
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true),
        (_ctx.totalItems)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.pageNumber) + " of " + _toDisplayString(Math.ceil(_ctx.totalItems / _ctx.limit)), 1))
          : _createCommentVNode("", true),
        (!_ctx.checkIfRouteIncludes(_ctx.routeName))
          ? (_openBlock(), _createBlock(_component_RouterLink, {
              key: 2,
              to: _ctx.getModifiedRoute(1),
              class: "page-link"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ButtonComponent, {
                  label: 'Show more',
                  style: 'primary',
                  size: 'sm'
                })
              ]),
              _: 1
            }, 8, ["to"]))
          : (Math.ceil(_ctx.totalItems / _ctx.limit) !== _ctx.pageNumber)
            ? (_openBlock(), _createBlock(_component_RouterLink, {
                key: 3,
                class: "page-link",
                to: _ctx.getModifiedRoute(_ctx.pageNumber + 1)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ButtonComponent, {
                    label: 'Next',
                    style: 'primary',
                    size: 'sm'
                  })
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}