
import ButtonComponent from "../components/ButtonComponent.vue";
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  ref,
} from "vue";
import Modal from "../components/Modal.vue";
import FieldComponent from "../components/FieldComponent.vue";
import { createWorker } from "tesseract.js";
import Icon from "@/components/Icon.vue";
import {
  createCollectionItem,
  deleteCollectionItem,
  fetchData,
  getCollectionItemById,
  getCollectionItemsByKeyValue,
  searchRelatedCollectionItems,
  updateCollectionItem,
} from "@/services/FirebaseService";
import { notificationService } from "@/services/NotificationsService";
import { RouterLink, useRoute } from "vue-router";
import router from "@/router";
import { store, useStore } from "@/services/Store";
const bootstrap = require("bootstrap");
import mixpanel from "mixpanel-browser";
import ProgressBar from "primevue/progressbar";
import ScreenshotCardComponent from "./ScreenshotCardComponent.vue";
import DraggableScrollDirective from "@/directives/DraggableDirective";
import LoaderComponent from "./LoaderComponent.vue";
import ScreenshotGalleryComponent from "./ScreenshotGalleryComponent.vue";

export default defineComponent({
  components: {
    ButtonComponent,
    Modal,
    FieldComponent,
    Icon,
    RouterLink,
    ProgressBar,
    ScreenshotCardComponent,
    LoaderComponent,
    ScreenshotGalleryComponent,
  },
  directives: {
    draggableScroll: DraggableScrollDirective,
  },
  props: ["userflow", "time"],
  emits: ["udpate-screenshots"],
  data() {
    const route = useRoute();
    return {
      galleryImageIndex: ref(),
      showGallery: ref(false),
      auth: <any>inject("auth"),
      screenshot: ref(),
      currentTime: ref(0),
      video: ref(),
      showScreenshotModal: ref(false),
      videoPlaying: ref(false),
      suggestions: ref(),
      screenshotPreview: ref(),
      addingScreenshot: ref(false),
      tooltipList: ref(),
      videoError: ref(false),
      isDragging: ref(false),
      expanded: ref(false),
      playBackSpeeds: ref([0.5, 1, 2, 3]),
      store: useStore(),
    };
  },
  computed: {
    videoSource() {
      // Base URL for the storage bucket
      const baseUrl =
        "https://storage.googleapis.com/userflows-390418.appspot.com/";

      // Extract the directory path and the filename
      const directoryPath = "user flows/";
      const filename = this.userflow.video.path?.split("/").pop(); // Get the filename part after the last '/'

      // Construct the primary video path (compressed video)
      // by inserting 'compressed-' between the directory path and the filename
      const primaryPath = directoryPath + "compressed-" + filename;

      // Construct the fallback video path (original video)
      const fallbackPath = this.userflow.video.path;

      // Return the full URL, using the primary path by default, and fallback path if there's been an error
      return baseUrl + (this.videoError ? fallbackPath : primaryPath);
    },
  },
  setup() {
    const store = useStore();

    const user = computed(() => store.state.user);

    return {
      user,
    };
  },
  created() {
    const route = useRoute();

    // watch for time change to update video if not locked or is subscribed
    this.$watch(
      () => route.params.time,
      async () => {
        if (
          route.params.time &&
          (!this.userflow.locked || this.user.isSubscribed)
        ) {
          this.jumpToTime(route.params.time);
        }
      }
    );
    this.$watch(
      () => this.userflow,
      async () => {
        this.setVideoListenersAndPlay();
      },
      { deep: true }
    );
    this.$watch(
      () => this.screenshots,
      async () => {
        setTimeout(() => {
          this.wrap("Wizard", "wizard");
          this.wrap("Guide", "guide");
        }, 0);
      },
      { deep: true }
    );
    this.$watch(
      () => this.userFlows,
      async () => {
        this.setVideoListenersAndPlay();
      },
      { deep: true }
    );
    this.$watch(
      () => this.userFlow,
      async () => {
        this.setVideoListenersAndPlay();
      },
      { deep: true }
    );
  },
  mounted() {
    this.getData();
    window.addEventListener("keydown", this.handleKeyPress);

    mixpanel.track("Event", {
      Event: "User flow video",
      Device: this.userflow.mobile ? "mobile" : "desktop",
      Industries: this.product?.industries,
    });
    setTimeout(() => {
      this.setVideoListenersAndPlay();
    }, 300);
  },
  onUnmounted() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
  methods: {
    getData() {
      this.getProduct();
      this.getScreenshots();
    },
    async getScreenshots() {
      try {
        await fetchData("screenshots", 100, 1, "time", "asc", [
          { field: "userflowId", value: this.userflow.id },
        ]).then((res: any) => {
          this.screenshots = res.items;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getProduct() {
      try {
        this.product = await getCollectionItemById(
          "products",
          this.userflow.productId
        );
      } catch (error) {}
    },
    showGalleryFunction(imageIndex: any) {
      this.showGallery = true;
      this.galleryImageIndex = imageIndex;
    },
    handleKeyPress(event) {
      // Ensure videoRef is available
      if (event.key === "ArrowLeft") {
        this.video.currentTime == this.video.currentTime--;
      } else if (event.key === "ArrowRight") {
        // Move video forward 1 second
        this.video.currentTime == this.video.currentTime++;
      }
    },
    changePlaybackSpeed() {
      const currentSpeedIndex = this.playBackSpeeds.indexOf(
        Number(this.video.playbackRate)
      );

      const newPlayBackSpeed =
        currentSpeedIndex == this.playBackSpeeds.length - 1
          ? this.playBackSpeeds[0]
          : this.playBackSpeeds[currentSpeedIndex + 1];
      this.video.playbackRate = newPlayBackSpeed;

      this.playVideo();
    },
    startDrag(event) {
      this.isDragging = true; // Set dragging to true on mousedown
      this.changeTimeOnTimeline(event);
    },
    dragging(event) {
      if (this.isDragging) {
        // Only call changeTimeOnTimeline if dragging has been initiated
        this.changeTimeOnTimeline(event);
      }
    },
    endDrag(event) {
      if (this.isDragging) {
        this.changeTimeOnTimeline(event);
        this.isDragging = false; // Reset dragging state on mouseup
      }
    },
    leaveDrag(event) {
      if (this.isDragging) {
        // Only call changeTimeOnTimeline on mouseleave if dragging is true
        this.changeTimeOnTimeline(event);
        this.isDragging = false; // It's important to reset this to stop dragging
      }
    },
    changeTimeOnTimeline($event) {
      const clientX = $event.clientX;

      const elementOffsetLeft =
        $event.currentTarget.getBoundingClientRect().left;
      const cursorPositionWithinElement = clientX - elementOffsetLeft;
      const elementWidth = $event.currentTarget.offsetWidth;
      this.video.currentTime =
        this.video.duration * (cursorPositionWithinElement / elementWidth);
    },

    showPricingModal() {
      this.$store.commit("setShowPricingModal", true);
    },
    async handleVideoChange(event: any) {
      const file = event.target.files[0];

      if (file) {
        try {
          await updateCollectionItem("user flows", this.userflow.id, {
            ...this.userflow,
            video: file,
          }).then(() => {
            window.location.reload();
          });
        } catch (error) {
          console.error(error);
        }
      }
    },
    handleVideoError() {
      this.videoError = true;
      this.$refs.videoRef?.load();
    },
    scrollToScreenshot(screenshotId) {
      const element = document.getElementById(screenshotId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    wrap(label, className) {
      const list = this.$refs.screenshotsList;
      let wrapper = null;
      let elementsToMove = [];

      // Remove existing wrappers and move their children back to the list
      const existingWrappers: any = list?.querySelectorAll(
        `.${className}-wrapper`
      )
        ? Array.from(list.querySelectorAll(`.${className}-wrapper`))
        : null;
      existingWrappers?.forEach((existingWrapper) => {
        while (existingWrapper.firstChild) {
          existingWrapper.parentNode.insertBefore(
            existingWrapper.firstChild,
            existingWrapper
          );
        }
        existingWrapper.remove();
      });

      // Loop through each child element of the list
      for (const element of list
        ? (Array.from(list?.children) as HTMLElement[])
        : []) {
        if (element.classList.contains(className)) {
          if (!wrapper) {
            wrapper = document.createElement("div");
            wrapper.className = `${className}-wrapper`;
          }
          elementsToMove.push(element);
        } else {
          if (wrapper) {
            // Insert the wrapper before the first element to be wrapped
            list.insertBefore(wrapper, elementsToMove[0]);

            // Move all elements inside the wrapper
            elementsToMove.forEach((el) => wrapper.appendChild(el));

            // Reset the wrapper and elementsToMove
            wrapper = null;
            elementsToMove = [];
          }
        }
      }

      // Handle the case where elements to be wrapped are at the end
      if (wrapper && elementsToMove.length > 0) {
        list.insertBefore(wrapper, elementsToMove[0]);
        elementsToMove.forEach((el) => wrapper.appendChild(el));
      }
    },
    setVideoListenersAndPlay() {
      this.video = null;
      this.video = this.$refs.videoRef;

      if (this.video) {
        this.video.addEventListener("timeupdate", this.handleTimeUpdate);
        this.video.addEventListener("timeupdate", () => {
          this.handleTimeUpdate();
          if (!this.video.paused && !this.video.ended) {
            this.videoPlaying = true;
          } else {
            this.videoPlaying = false;
          }
        });
      }
    },
    increase(seconds: any) {
      this.video.currentTime = this.video.currentTime + seconds;
    },
    decrease(seconds: any) {
      this.video.currentTime = this.video.currentTime - seconds;
    },
    handleFileChange(event: any) {
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.screenshot.screenshot = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async createScreenshot() {
      this.addingScreenshot = true;
      this.screenshot.mobile = this.userflow.mobile
        ? this.userflow.mobile
        : false;
      try {
        createCollectionItem("screenshots", this.screenshot).then(async () => {
          this.store.dispatch("fetchUserFlow", this.userflow.id);
          this.getScreenshots();
          this.playVideo();
          this.showScreenshotModal = false;
          this.addingScreenshot = false;
        });
      } catch (error) {
        console.error(error);
        this.addingScreenshot = false;
      }
    },
    async removeScreenshot(id: any) {
      await deleteCollectionItem("screenshots", id).then(() => {
        notificationService.addNotification("Screenshot deleted");
      });
    },
    updateValue(newValue: string) {
      this.screenshot.title = newValue;
      setTimeout(() => {
        document.getElementById("screenshot")?.focus();
      }, 0);
    },
    truncate(str: any, words: any) {
      if (typeof str === "undefined") {
        return str;
      }

      return str.split(" ").splice(0, words).join(" ");
    },
    handleTimeUpdate() {
      if (this.video) {
        this.currentTime = this.video.currentTime;
      }
    },
    jumpToTime(time: string) {
      setTimeout(() => {
        this.video.currentTime = this.convertStringToTime(time);
      }, 100);
      this.playVideo();
    },
    saveCurrentTime() {
      this.suggestions = null;
      this.pauseVideo();
      this.getVideoScreenshot();
    },
    pauseVideo() {
      this.video.pause();
      this.videoPlaying = false;
    },
    playVideo() {
      this.video.play();
      this.videoPlaying = true;
    },
    playSpeed(rate: number) {
      this.playVideo();
      this.video.playbackRate = rate;
    },
    addScreenshot(newScreenshot: any) {
      const updatedScreenshots = [...this.screenshots];
      let insertIndex = updatedScreenshots.findIndex(
        (screenshot: any) =>
          screenshot.time > this.convertTimeToString(this.currentTime)
      );
      if (insertIndex === -1) {
        insertIndex = updatedScreenshots.length;
      }
      updatedScreenshots.splice(insertIndex, 0, newScreenshot);
      this.showScreenshotModal = false;
      this.playVideo();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = this.screenshot.screenshot;
      downloadLink.download = this.generateScreenshotName();
      document.body.appendChild(downloadLink);

      // Programmatically trigger the download
      downloadLink.click();
    },
    convertStringToTime(time: any) {
      if (typeof time !== "string") {
        time = String(time);
      }
      const [minutes, seconds] = time.split(":");
      const convertedTime = parseInt(minutes) * 60 + parseInt(seconds);
      return convertedTime;
    },
    convertTimeToString(time: any) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      const timeString = `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
      return timeString;
    },
    getVideoScreenshot() {
      const canvas = document.createElement("canvas");

      canvas.width = this.video.videoWidth;
      canvas.height = this.video.videoHeight;

      canvas.getContext("2d")!.drawImage(this.video, 0, 0);

      const dataURL = canvas.toDataURL();

      fetch(dataURL)
        .then((userflow) => userflow.blob())
        .then((blob) => {
          const file = new File([blob], "screenshot.jpg", {
            type: "image/jpeg",
          });
          const objectURL = URL.createObjectURL(file);
          this.screenshotPreview = objectURL;
          const newScreenshot = {
            productId: this.product.id,
            userflowId: this.userflow.id,
            title: "",
            time: this.convertTimeToString(this.currentTime),
            screenshot: file,
          };
          this.screenshot = newScreenshot;
          this.showScreenshotModal = true;
          setTimeout(() => {
            document.getElementById("screenshot")?.focus();
          }, 0);
          // this.addDescription();
        });
    },
    generateScreenshotName() {
      return (this.screenshot.title + ".jpg")
        .replace(/\s+/g, "-")
        .toLowerCase();
    },
    async addDescription() {
      try {
        const screenshotCopy = { ...this.screenshot };
        const worker = await createWorker();
        await worker.loadLanguage("eng");
        await worker.initialize("eng");
        await worker.setParameters({
          tessedit_char_whitelist:
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'`,? ",
        });
        const {
          data: { lines },
        } = await worker.recognize(screenshotCopy.screenshot);

        const newSuggestions: any = [];

        lines.forEach((line) => {
          if (!this.containsOneLetterWord(line.text)) {
            newSuggestions.push(this.removeTextAfterSentenceEnd(line.text));
          }
        });

        screenshotCopy.title = this.screenshot.title
          ? this.screenshot.title
          : newSuggestions[0];
        this.suggestions = newSuggestions.splice(0, 3);

        this.screenshot = screenshotCopy;
        setTimeout(() => {
          document.getElementById("screenshot")?.focus();
        }, 0);
        await worker.terminate();
      } catch (error) {
        console.error(error);
      }
    },
    removeEndingIfRegMatch(text: any, regex: any) {
      if (typeof text !== "string") {
        // Convert to string if not already a string
        text = String(text);
      }

      const matchIndex = text.match(regex);
      if (matchIndex) {
        text = text.substring(0, matchIndex.index + 1);
      }

      return text;
    },
    removeTextAfterSentenceEnd(text: any) {
      if (typeof text !== "string") {
        // Convert to string if not already a string
        text = String(text);
      }

      text = this.removeEndingIfRegMatch(text, /[.!?]/);
      text = this.removeEndingIfRegMatch(text, / {2,}/);

      return text;
    },
    containsOneLetterWord(str: any) {
      const words = str.replace(/[^a-zA-Z ]/g, "").split(" ");

      for (const word of words) {
        if (word.length === 1 && word != "a") {
          return true;
        }
      }

      return false;
    },
  },
});
