// firebase.ts
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBik1Q76wXQH-e_ZZ1Mpx7LrP0cGALqD_Q",
  authDomain: "userflows-390418.firebaseapp.com",
  projectId: "userflows-390418",
  storageBucket: "userflows-390418.appspot.com",
  messagingSenderId: "295307260488",
  appId: "1:295307260488:web:4a691266ebd8c9524a9e7d",
  measurementId: "G-12PXFLBJLZ",
};

const app = firebase.initializeApp(firebaseConfig);

getAnalytics(app);

export const auth = firebase.auth();
