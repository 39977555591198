import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row g-4" }
const _hoisted_2 = { class: "col-12 col-md-6 col-xl-4" }
const _hoisted_3 = { class: "rounded overflow-hidden bg-secondary" }
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_RouterLink, {
          class: "text-decoration-none",
          to: { name: 'post', params: { postTitle: post.title } }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CardComponent, {
              class: "posts-page__card",
              "card-footer": true,
              "card-title": post.title
            }, {
              cardFooter: _withCtx(() => [
                _createVNode(_component_ButtonComponent, {
                  style: 'light',
                  label: 'Read more'
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("img", {
                    src: post.thumbnail,
                    alt: post.title,
                    class: "w-100 rounded",
                    style: { 'aspect-ratio': '16/8.7', objectFit: 'contain' }
                  }, null, 8, _hoisted_4)
                ]),
                _createTextVNode(" " + _toDisplayString(post.intro) + " ", 1)
              ]),
              _: 2
            }, 1032, ["card-title"])
          ]),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 256))
  ]))
}