export default {
  mounted(el) {
    let isDragging = false;
    let isDragged = false;
    let startX = 0;
    let startY = 0;
    let scrollLeft = 0;
    let scrollTop = 0;

    const threshold = 3; // Pixels to move before deciding if it's a drag
    const scrollSpeedMultiplier = 1;

    const mouseDrag = (event) => {
      if (!isDragging) return;
      const dx = event.pageX - startX;
      const dy = event.pageY - startY;

      // If movement is within the threshold, don't consider it dragging yet
      if (Math.abs(dx) < threshold && Math.abs(dy) < threshold) return;

      // Set the flag as soon as we exceed the threshold
      isDragged = true;

      event.preventDefault();

      // Apply the scroll speed multiplier
      const walkX = dx * scrollSpeedMultiplier;
      const walkY = dy * scrollSpeedMultiplier;

      // Allow for diagonal scrolling by applying both horizontal and vertical scroll
      el.scrollLeft = scrollLeft - walkX;
      el.scrollTop = scrollTop - walkY;
    };

    const stopDrag = () => {
      if (!isDragging) return;
      isDragging = false;
      window.removeEventListener("mousemove", mouseDrag);
      window.removeEventListener("mouseup", stopDrag);
      el.style.userSelect = "auto";
      el.style.cursor = "grab";

      // Use requestAnimationFrame to reset the isDragged flag
      requestAnimationFrame(() => {
        isDragged = false;
      });
    };

    const startDrag = (event) => {
      event.preventDefault();
      isDragging = true;
      startX = event.pageX;
      startY = event.pageY;
      scrollLeft = el.scrollLeft;
      scrollTop = el.scrollTop;
      window.addEventListener("mousemove", mouseDrag);
      window.addEventListener("mouseup", stopDrag);
      el.style.userSelect = "none";
      el.style.cursor = "grabbing";
    };

    const handleClick = (event) => {
      if (isDragged) {
        event.stopPropagation();
        event.preventDefault();
      }
    };

    el.addEventListener("mousedown", startDrag);
    el.addEventListener("click", handleClick, true);

    // Store the handlers on the element for cleanup
    el._dragHandlers = { mouseDrag, stopDrag, startDrag, handleClick };
  },
  unmounted(el) {
    // Use the stored handlers for cleanup
    const handlers = el._dragHandlers;
    if (handlers) {
      el.removeEventListener("mousedown", handlers.startDrag);
      el.removeEventListener("click", handlers.handleClick, true);
      window.removeEventListener("mousemove", handlers.mouseDrag);
      window.removeEventListener("mouseup", handlers.stopDrag);
    }
  },
};
