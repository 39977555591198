
import { ref } from "vue";

export default {
  props: {
    email: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "32",
    },
  },
  data() {
    return {
      avatarUrl: ref(null),
    };
  },
  mounted() {
    const hash = this.md5(this.email.trim().toLowerCase());
    this.avatarUrl = `https://www.gravatar.com/avatar/${hash}?d=identicon&s=${this.size}`;
  },
  methods: {
    md5(string) {
      // Import a library to perform MD5 or add MD5 implementation here
      // You can use a library like `crypto-js` or `blueimp-md5`
      // Assuming an md5 function is available, hash the string
      return string;
    },
  },
};
