import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column gap-2 w-100" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "d-flex flex-column gap-1" }
const _hoisted_4 = { class: "mb-0" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "text-decoration-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductLogo = _resolveComponent("ProductLogo")!
  const _component_VueWordHighlighter = _resolveComponent("VueWordHighlighter")!
  const _component_AddIndustryComponent = _resolveComponent("AddIndustryComponent")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RouterLink, {
      class: "text-decoration-none",
      to: {
        name: 'product',
        params: { productTitle: _ctx.product.title },
      }
    }, {
      default: _withCtx(() => [
        (_ctx.product)
          ? (_openBlock(), _createBlock(_component_CardComponent, {
              key: 0,
              class: "h-100 w-100 cursor-pointer",
              "card-title": _ctx.product.title,
              "card-before-title": true,
              "card-after-title": _ctx.userFlows,
              "card-footer": !_ctx.hideDescription,
              "hide-body": _ctx.hideDescription
            }, {
              cardBeforeTitle: _withCtx(() => [
                (_ctx.product && _ctx.product.logo)
                  ? (_openBlock(), _createBlock(_component_ProductLogo, {
                      key: 0,
                      class: "cursor-pointer",
                      logo: 
              'https://storage.googleapis.com/userflows-390418.appspot.com/' +
              _ctx.product.logo.path
            
                    }, null, 8, ["logo"]))
                  : _createCommentVNode("", true)
              ]),
              cardFooter: _withCtx(() => [
                _createVNode(_component_AddIndustryComponent, { product: _ctx.product }, null, 8, ["product"])
              ]),
              default: _withCtx(() => [
                (_ctx.screenshots && _ctx.screenshots.length > 0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (
            _ctx.$router.push({
              name: 'product',
              params: { productTitle: _ctx.product.title },
            })
          )),
                      class: "ratio ratio-16x9 cursor-pointer overflow-hidden rounded text-center d-flex justify-content-center align-items-center",
                      style: _normalizeStyle({ 'background-color': _ctx.product.background })
                    }, [
                      (_openBlock(), _createElementBlock("img", {
                        key: _ctx.screenshots[0].id,
                        class: "h-100 border w-auto",
                        src: 
              'https://storage.googleapis.com/userflows-390418.appspot.com/' +
              _ctx.screenshots[0].screenshot.path
            ,
                        alt: `${_ctx.screenshots[0].title}`,
                        loading: "lazy",
                        style: _normalizeStyle({
              'object-fit': 'contain',
              left: '50%',
              transform: 'translateX(-50%)',
            })
                      }, null, 12, _hoisted_2))
                    ], 4))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", _hoisted_4, [
                    (_ctx.userFlows && _ctx.userFlows.length)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_5, [
                          _createTextVNode(_toDisplayString(_ctx.userFlows?.length) + " user flows ", 1),
                          (
                  _ctx.userFlows.filter((userFlow) => {
                    return userFlow.draft;
                  })?.length
                )
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "(" + _toDisplayString(_ctx.userFlows.filter((userFlow) => {
                    return userFlow.draft;
                  })?.length) + " in draft)", 1))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.screenshots && _ctx.screenshots.length)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_7, " / " + _toDisplayString(_ctx.screenshots?.length) + " screenshots ", 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("p", _hoisted_8, [
                    _createVNode(_component_VueWordHighlighter, {
                      query: _ctx.$route.params.s ? _ctx.$route.params.s as string : ''
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.product.description), 1)
                      ]),
                      _: 1
                    }, 8, ["query"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["card-title", "card-after-title", "card-footer", "hide-body"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}