
// import { getProductById } from "../services/FirebaseService";
import { defineComponent } from "vue";
import VueWordHighlighter from "vue-word-highlighter";

export default defineComponent({
  props: [
    "cardTitle",
    "titleEditable",
    "fullWidthContent",
    "cardBeforeTitle",
    "cardAfterTitle",
    "cardFooter",
    "footerOverlay",
    "transparent",
    "id",
    "headerOverlay",
    "clickableTitle",
    "hideBody",
  ],
  components: {
    VueWordHighlighter,
  },
  emits: ["update-title", "title-clicked"],
  methods: {
    updateTitle() {
      const newTitle = document.getElementById(this.id)!.innerText;
      this.$emit("update-title", { title: newTitle });
    },
  },
});
