
import ButtonComponent from "@/components/ButtonComponent.vue";
import ProductLogo from "@/components/ProductLogo.vue";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "@/services/Store";
import ScreenshotCardComponent from "@/components/ScreenshotCardComponent.vue";
import moment from "moment";
import StatusLabelComponent from "@/components/StatusLabelComponent.vue";
import ScreenshotGalleryComponent from "./ScreenshotGalleryComponent.vue";
import CardComponent from "./CardComponent.vue";
import Icon from "./Icon.vue";
import { updateCollectionItem } from "@/services/FirebaseService";
import { notificationService } from "@/services/NotificationsService";
import TagComponent from "@/components/TagComponent.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Virtual, FreeMode, Navigation } from "swiper/modules";

export default defineComponent({
  components: {
    ProductLogo,
    ButtonComponent,
    ScreenshotCardComponent,
    StatusLabelComponent,
    ScreenshotGalleryComponent,
    CardComponent,
    Icon,
    TagComponent,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const store = useStore();

    const user = computed(() => store.state.user);

    return {
      user,
    };
  },
  data() {
    return {
      showGallery: ref(false),
      galleryIndex: ref(),
      store: useStore(),
      modules: [Virtual, FreeMode, Navigation],
      videoLength: ref(0),
    };
  },
  props: ["userFlow", "hidePlayButton", "screenshots", "product", "categories"],

  computed: {
    formattedVideoLength() {
      const minutes = Math.floor(this.videoLength / 60);
      const seconds = Math.floor(this.videoLength % 60);
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
      return `${formattedMinutes}:${formattedSeconds}`;
    },
    userFlowCategories() {
      return this.categories?.filter((category) => {
        return this.userFlow?.categories?.includes(category.id);
      });
    },
    currentProduct() {
      return this.products
        ?.concat(this.emptyProducts)
        .find((product) => product?.id === this.userFlow?.productId);
    },
  },
  mounted() {
    this.getVideoLength();
  },
  methods: {
    async getVideoLength() {
      const videoElement = document.createElement("video");

      // Arrow function to maintain the context of 'this'
      const canLoadVideo = async (url) => {
        try {
          const response = await fetch(url, { method: "HEAD" });
          return response.ok; // will be true if the response is 200-299
        } catch (error) {
          console.error("Error fetching video:", error);
          return false;
        }
      };

      // Arrow function to maintain the context of 'this'
      const setVideoSrcAndDuration = (url) => {
        videoElement.addEventListener("loadedmetadata", () => {
          this.videoLength = videoElement.duration;
          // Do something with this.videoLength
        });

        videoElement.addEventListener("error", () => {
          console.error("Error loading video metadata");
          // Handle error, potentially setting an alternative video source
        });

        videoElement.src = url;
      };

      const baseUrl =
        "https://storage.googleapis.com/userflows-390418.appspot.com/";

      // Use the nullish coalescing operator to handle the case where this.userflow.video.path is null or undefined
      const directoryPath = "user flows/";
      const filename =
        this.userFlow?.video?.path?.split("/").pop() ?? "default-filename"; // Provide a default filename if none is found

      // Primary video path
      const primaryPath = `${directoryPath}compressed-${filename}`;

      // Fallback video path
      const fallbackPath = `${directoryPath}${filename}`; // Provide a default fallback path if none is found

      const videoUrl = baseUrl + primaryPath;

      // Check if the compressed video exists and is accessible
      if (await canLoadVideo(videoUrl)) {
        setVideoSrcAndDuration(videoUrl);
      } else {
        // Compressed video doesn't exist, use the original video path
        setVideoSrcAndDuration(baseUrl + fallbackPath);
      }
    },
    async draftToggle() {
      try {
        await updateCollectionItem("user flows", this.userFlow.id, {
          draft: !this.userFlow.draft,
        }).then(() => {
          notificationService.addNotification(
            this.userFlow.title +
              " user flow is now " +
              (this.userFlow.draft ? "published" : "un-published")
          );
          this.store.dispatch("fetchUserFlows");
          this.store.dispatch("fetchUserFlow", this.userFlow.id);
        });
      } catch (error) {
        console.error(error);
      }
    },

    openGallery(index: any) {
      this.galleryIndex = index;
      this.showGallery = true;
    },
    isNew(userFlow) {
      const today = moment(new Date());
      return userFlow?.created &&
        today.diff(moment(userFlow?.created), "days") < 14
        ? true
        : false;
    },
    ago(date: any) {
      return moment(date).fromNow();
    },
    convertStringToTime(time: any) {
      if (typeof time !== "string") {
        time = String(time);
      }
      const [minutes, seconds] = time.split(":");
      const convertedTime = parseInt(minutes) * 60 + parseInt(seconds);
      return convertedTime;
    },
  },
});
