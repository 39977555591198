
import { RouterLink, useRoute, useRouter } from "vue-router";
import ButtonComponent from "./ButtonComponent.vue";
import FieldComponent from "@/components/FieldComponent.vue";
import Icon from "@/components/Icon.vue";
import { computed, defineComponent, inject, ref } from "vue";
import Logo from "./Logo.vue";
import { auth } from "@/firebase";
import { searchCollectionItems } from "@/services/FirebaseService";
import { getItemsInCollection } from "@/services/FirebaseService";
import VueWordHighlighter from "vue-word-highlighter";
import { searchClient } from "@/services/Algolia";
import { useStore } from "@/services/Store";

export default defineComponent({
  data() {
    return {
      showMobileMenu: ref(false),
      searchQuery: ref(),
      auth: <any>inject("auth"),
      router: useRouter(),
      userflows: ref(),
      tags: ref(),
      products: ref(),
      showSearchDropdown: ref(false),
      searchResultLimit: ref(6),
      recentSearches: ref(),
    };
  },
  setup() {
    const store = useStore();

    const collections = computed(() => store.state.collections);

    return {
      collections,
    };
  },
  async mounted() {
    this.recentSearches = localStorage.getItem("recentSearches")
      ? JSON.parse(localStorage.getItem("recentSearches"))
      : [];
  },
  created() {
    const route = useRoute();

    // Watch for route changes and update component data
    this.$watch(
      () => route.path,
      async () => {
        this.searchQuery = this.$route.params.s;
      }
    );
    this.searchDebounced = this.debounce(this.search, 300);
  },
  methods: {
    focusOnSearch() {
      this.$refs.navbarSearch.focus();
      this.showSearchDropdown = true;
    },
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },
    clearRecentSearches() {
      this.recentSearches = [];
      localStorage.removeItem("recentSearches");
    },
    async search() {
      this.showSearchDropdown = true;
      const baseQuery =
        this.searchQuery && this.searchQuery.length > 0 ? this.searchQuery : "";

      const queries = [
        {
          indexName: "categories",
          query: baseQuery,
          params: {
            attributesToRetrieve: ["name"],
            hitsPerPage: this.searchResultLimit,
            page: 0,
          },
        },
        {
          indexName: "tags",
          query: baseQuery,
          params: {
            attributesToRetrieve: ["name"],
            hitsPerPage: this.searchResultLimit,
            page: 0,
          },
        },
        {
          indexName: "products",
          query: baseQuery,
          params: {
            attributesToRetrieve: ["title"],
            hitsPerPage: this.searchResultLimit,
            page: 0,
          },
        },
      ];

      try {
        const responses = await searchClient.search(queries);
        if ("hits" in responses.results[0]) {
          this.userflows = responses.results[0].hits;
        }
        if ("hits" in responses.results[1]) {
          this.tags = responses.results[1].hits;
        }
        if ("hits" in responses.results[2]) {
          this.products = responses.results[2].hits;
        }
      } catch (error) {
        console.error(error);
      }
    },
    checkIfRouteIncludes(routeName: any) {
      const currentRouteName = this.$route.name as string;
      let includes = false;
      if (currentRouteName && currentRouteName.includes(routeName)) {
        includes = true;
      }
      return includes;
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()].slice(
        -3
      );
    },
    addToRecentSearch(search: any) {
      this.recentSearches.push(search);
      this.recentSearches = this.getUniqueListBy(
        this.recentSearches,
        "searchQuery"
      );
      localStorage.setItem(
        "recentSearches",
        JSON.stringify(this.recentSearches)
      );
    },
    goToSearchResults(searchQuery: any) {
      this.addToRecentSearch({ type: "search", searchQuery });
      this.showSearchDropdown = false;
      this.searchQuery = searchQuery != "" ? searchQuery.toLowerCase() : "";
      this.router.push({
        name: "search results",
        params: { s: searchQuery.toLowerCase(), pageNumber: "1" },
      });
    },
    goToUiElements(tagName: any) {
      this.showSearchDropdown = false;
      this.router.push({
        name: "screenshots by ui elements",
        params: { tagTitle: tagName, pageNumber: "1" },
      });
    },
    goToProduct(productTitle: any) {
      this.showSearchDropdown = false;
      this.router.push({
        name: "product",
        params: { productTitle: productTitle },
      });
    },
    goToUserflowSearchResults(category: any) {
      this.showSearchDropdown = false;
      this.router.push({
        name: "user flows by category",
        params: { category, pageNumber: "1" },
      });
    },
  },
  components: {
    RouterLink,
    ButtonComponent,
    FieldComponent,
    Logo,
    Icon,
    VueWordHighlighter,
  },
});
