import algoliasearch from "algoliasearch/lite";
import aa from "search-insights";

export const searchClient = algoliasearch(
  "NUDL7RMQPE",
  "9f92b07dda42023f3fae1196595d3246"
);

// Initialize the Algolia Insights library
const initAlgoliaInsights = () => {
  aa("init", {
    appId: "NUDL7RMQPE",
    apiKey: "9f92b07dda42023f3fae1196595d3246",
  });
};

// Export the initializer function
export { initAlgoliaInsights };
