
import { defineComponent } from "vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import Icon from "./Icon.vue";

export default defineComponent({
  methods: {
    triggerUpload() {
      document.getElementById(this.id)!.click();
    },
    handleInputChange(event: any) {
      const target = event.target as HTMLInputElement;
      this.$emit("update:value", target.value);
    },
  },
  props: [
    "title",
    "placeholder",
    "label",
    "value",
    "autofocus",
    "id",
    "name",
    "required",
    "type",
    "rows",
    "disabled",
    "buttonStyle",
    "icon",
  ],
  emits: ["update:value"],
  components: { ButtonComponent, Icon },
});
