import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_component_RouterLink, {
    class: "d-flex text-decoration-none align-items-center",
    to: { name: 'home' }
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        class: _normalizeClass(["logo", { 'logo--inverted': _ctx.inverted }]),
        src: require('@/assets/logo.svg'),
        alt: ""
      }, null, 10, _hoisted_1)
    ]),
    _: 1
  }))
}