import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex gap-1 flex-wrap" }
const _hoisted_2 = { class: "d-flex gap-1" }
const _hoisted_3 = {
  key: 1,
  class: "d-flex align-items-center gap-1"
}
const _hoisted_4 = { class: "w-100" }
const _hoisted_5 = { class: "d-flex align-items-center gap-2 mb-1 mt-n2" }
const _hoisted_6 = { class: "text-muted mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductLogo = _resolveComponent("ProductLogo")!
  const _component_StatusLabelComponent = _resolveComponent("StatusLabelComponent")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_ScreenshotCardComponent = _resolveComponent("ScreenshotCardComponent")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _component_ScreenshotGalleryComponent = _resolveComponent("ScreenshotGalleryComponent")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CardComponent, {
      "card-title": `${_ctx.userFlow?.title} on ${_ctx.product?.title}`,
      transparent: true,
      "card-before-title": true,
      "card-after-title": true,
      "card-footer": true
    }, {
      cardBeforeTitle: _withCtx(() => [
        _withDirectives(_createVNode(_component_ProductLogo, {
          class: "cursor-pointer",
          logo: _ctx.product?.logo.url,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (
          _ctx.$router.push({
            name: 'product',
            params: {
              productTitle: _ctx.product?.title,
            },
          })
        ))
        }, null, 8, ["logo"]), [
          [
            _directive_tooltip,
            _ctx.product?.title,
            void 0,
            { top: true }
          ]
        ])
      ]),
      cardAfterTitle: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.isNew(_ctx.userFlow))
            ? (_openBlock(), _createBlock(_component_StatusLabelComponent, {
                key: 0,
                label: 'New',
                state: 'success'
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      cardActions: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.$route.params.userflowId)
            ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                key: 0,
                style: 'primary',
                icon: 'play',
                label: _ctx.formattedVideoLength,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (
            _ctx.$router.push({
              name: 'user flow',
              params: {
                userflowId: _ctx.userFlow?.id,
                time: '00:00',
              },
            })
          ))
              }, null, 8, ["label"]))
            : _createCommentVNode("", true),
          (_ctx.user.isAdmin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _withDirectives(_createVNode(_component_ButtonComponent, {
                  size: 'sm',
                  style: 'primary',
                  icon: _ctx.userFlow?.draft ? 'eye-slash' : 'eye',
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.draftToggle()))
                }, null, 8, ["icon"]), [
                  [
                    _directive_tooltip,
                    _ctx.userFlow?.draft ? 'Publish' : 'Un-publish',
                    void 0,
                    { left: true }
                  ]
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, [
              _createElementVNode("small", null, _toDisplayString(_ctx.ago(_ctx.userFlow?.updated ? _ctx.userFlow?.updated : _ctx.userFlow?.created)) + " - " + _toDisplayString(_ctx.screenshots?.length) + " screens ", 1)
            ])
          ]),
          _createVNode(_component_Swiper, {
            spaceBetween: 30,
            "slides-per-view": 'auto',
            navigation: true,
            freeMode: {
          enabled: true,
          sticky: false,
          momentumRatio: 0.5,
        },
            modules: _ctx.modules
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.screenshots, (screenshot, index) => {
                return (_openBlock(), _createBlock(_component_SwiperSlide, {
                  "virtual-index": index,
                  style: _normalizeStyle({
            width: _ctx.userFlow?.mobile ? '18rem' : '50rem',
            'max-width': '70vw',
          })
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ScreenshotCardComponent, {
                      class: _normalizeClass(["user-flow-component__screenshot", {
              'user-flow-component__screenshot--mobile': _ctx.userFlow?.mobile,
            }]),
                      mobile: _ctx.userFlow?.mobile,
                      locked: index > 1 && _ctx.userFlow?.locked && !_ctx.user.isSubscribed,
                      screenshot: screenshot,
                      "hide-tags": true,
                      onClick: ($event: any) => (
              index > 1 && _ctx.userFlow?.locked && !_ctx.user.isSubscribed
                ? null
                : _ctx.openGallery(index)
            )
                    }, null, 8, ["class", "mobile", "locked", "screenshot", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["virtual-index", "style"]))
              }), 256))
            ]),
            _: 1
          }, 8, ["modules"])
        ])
      ]),
      _: 1
    }, 8, ["card-title"]),
    _createVNode(_component_ScreenshotGalleryComponent, {
      "hide-sidebar": true,
      "show-gallery": _ctx.showGallery,
      "gallery-image-index": _ctx.galleryIndex,
      screenshots: 
      _ctx.screenshots?.slice(
        0,
        _ctx.userFlow?.locked && !_ctx.user.isSubscribed ? 2 : _ctx.screenshots?.length
      )
    ,
      onCloseGallery: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showGallery = false))
    }, null, 8, ["show-gallery", "gallery-image-index", "screenshots"])
  ], 64))
}