import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navbar navbar-expand border-bottom" }
const _hoisted_2 = { class: "d-flex w-100 h-100 align-items-center gap-0" }
const _hoisted_3 = { class: "main-links d-flex align-items-center w-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["w-100 h-100", _ctx.$route.meta.fullPage ? 'container-fluid' : 'container'])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_RouterLink, {
          class: _normalizeClass([{ 'd-none d-md-block': _ctx.showSearchDropdown }, "navbar-brand me-3 me-md-4"]),
          to: "/"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Logo)
          ]),
          _: 1
        }, 8, ["class"]),
        _createElementVNode("div", {
          class: _normalizeClass(["hidden-links-on-mobile d-flex align-items-center gap-0 z-3 h-100 me-auto", { showMenu: _ctx.showMobileMenu }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMobileMenu = false))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_RouterLink, {
              class: "navbar__link text-decoration-none d-flex",
              to: { name: 'user flows main' }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ButtonComponent, {
                  label: 'User flows',
                  style: 'text',
                  class: _normalizeClass({
                  'bg-white bg-opacity-25': _ctx.checkIfRouteIncludes('user flow'),
                })
                }, null, 8, ["class"])
              ]),
              _: 1
            }),
            _createVNode(_component_RouterLink, {
              class: "navbar__link text-decoration-none d-flex",
              to: { name: 'screenshots', params: { pageNumber: 1 } }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ButtonComponent, {
                  label: 'Screenshots',
                  style: 'text',
                  class: _normalizeClass({
                  'bg-white bg-opacity-25': _ctx.checkIfRouteIncludes('screenshots'),
                })
                }, null, 8, ["class"])
              ]),
              _: 1
            }),
            (_ctx.collections.length > 0)
              ? (_openBlock(), _createBlock(_component_RouterLink, {
                  key: 0,
                  class: "navbar__link text-decoration-none d-flex",
                  to: { name: 'collections' }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ButtonComponent, {
                      label: 'Collections',
                      style: 'text',
                      class: _normalizeClass({
                  'bg-white bg-opacity-25': _ctx.checkIfRouteIncludes('collection'),
                })
                    }, null, 8, ["class"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([{ 'd-none d-md-block': _ctx.showSearchDropdown }, "d-flex gap-0 z-3 align-items-center w-auto h-100 py-1 py-md-0"])
        }, [
          _createVNode(_component_RouterLink, {
            class: "navbar__link text-decoration-none d-none d-lg-flex align-items-center",
            to: { name: 'requests and feedback' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ButtonComponent, {
                icon: 'message',
                label: 'Feedback',
                iconWeight: 
                _ctx.checkIfRouteIncludes('requests and feedback')
                  ? 'bold'
                  : 'normal'
              ,
                class: "text-decoration-none h-100",
                style: 'text'
              }, null, 8, ["iconWeight"])
            ]),
            _: 1
          }),
          (!_ctx.auth.isLoggedIn)
            ? (_openBlock(), _createBlock(_component_RouterLink, {
                key: 0,
                class: "navbar__link text-decoration-none d-flex align-items-center",
                to: { name: 'register' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ButtonComponent, { label: 'Sign up' })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.auth.isLoggedIn)
            ? (_openBlock(), _createBlock(_component_RouterLink, {
                key: 1,
                class: "navbar__link text-decoration-none w-100 d-flex",
                to: { name: 'account' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ButtonComponent, {
                    "icon-weight": _ctx.checkIfRouteIncludes('account') ? 'bold' : 'normal',
                    class: "text-decoration-none",
                    icon: 'user',
                    style: 'text',
                    label: 'Account',
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showMobileMenu = false))
                  }, null, 8, ["icon-weight"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.auth.isLoggedIn)
            ? (_openBlock(), _createBlock(_component_RouterLink, {
                key: 2,
                class: "navbar__link text-decoration-none d-flex align-items-center",
                to: { name: 'login' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ButtonComponent, {
                    class: _normalizeClass([_ctx.checkIfRouteIncludes('login') ? 'fw-bold' : 'fw-normal', "text-decoration-none"]),
                    style: 'text',
                    label: 'Login'
                  }, null, 8, ["class"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ], 2)
  ]))
}