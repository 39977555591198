import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "toast-body p-1" }
const _hoisted_2 = { class: "d-flex align-items-center gap-1 p-1" }
const _hoisted_3 = {
  class: "mb-0 fw-light",
  style: { 'font-size': '1rem' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "notifications d-none d-lg-flex flex-column gap-2 w-100 h-100",
    style: _normalizeStyle((_ctx.elementStyle as StyleValue))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "toast show bg-white",
        role: "alert",
        "aria-live": "assertive",
        "aria-atomic": "true"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Icon, {
              class: "text-success",
              icon: 'check-circle',
              size: 'large'
            }),
            _createElementVNode("p", _hoisted_3, [
              _createElementVNode("small", null, _toDisplayString(notification.message), 1)
            ])
          ])
        ])
      ]))
    }), 128))
  ], 4))
}