import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createHead } from "@vueuse/head";
import auth from "@/services/Auth";
import { createGtm } from "@gtm-support/vue-gtm";
import { key, store } from "@/services/Store";
import PrimeVue from "primevue/config";
const VueAutosuggest = require("vue-autosuggest");
const dataLayerData = auth;
delete dataLayerData.subscriptions;

import introJs from "intro.js";
import "intro.js/introjs.css";

import mixpanel from "mixpanel-browser";
import Tooltip from "primevue/tooltip";

mixpanel.init("d9715559881b5dcaf52ec6598e8a4694");

const app = createApp(App);

app.config.globalProperties.$intro = introJs;

app
  .use(store, key)
  .use(router)
  .use(
    createGtm({
      id: "GTM-NTCNS6WZ",
      vueRouter: router,
    }),
    window.dataLayer.push(dataLayerData)
  )
  .use(VueAutosuggest)
  .use(createHead())
  .use(PrimeVue)
  .provide("auth", auth)
  .directive("tooltip", Tooltip)
  .mount("#app");
