
import { defineComponent } from "vue";
import ButtonComponent from "./ButtonComponent.vue";

export default defineComponent({
  props: ["pageNumber", "totalItems", "limit", "routeName"],
  components: { ButtonComponent },
  methods: {
    checkIfRouteIncludes(routeName: any) {
      const currentRouteName = this.$route.name as string;
      let includes = false;
      if (currentRouteName.includes(routeName)) {
        includes = true;
      }
      return includes;
    },
    getModifiedRoute(pageNumber: any) {
      const currentRoute = this.$route;

      const modifiedRoute = this.$router.resolve({
        name:
          this.$route.name == "search results"
            ? this.routeName + " search results"
            : (this.$route.name as string),
        params: {
          ...currentRoute.params, // Keep existing params
          pageNumber: pageNumber, // Change the specific parameter
        },
        query: {
          ...currentRoute.query, // Keep existing query
          pageNumber: pageNumber, // Change the specific parameter
        },
      });
      return modifiedRoute;
    },
  },
});
