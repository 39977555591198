
import { defineComponent } from "vue";
// import Icon from '@/components/Icon.vue'

export default defineComponent({
  data() {
    return {};
  },
  props: ["size"],
  methods: {},
  //   components: { Icon },
});
