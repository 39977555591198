
import { useRouter } from "vue-router";
import { computed, defineComponent, onMounted, ref } from "vue";
import NavbarComponent from "@/components/NavbarComponent.vue";
import Icon from "@/components/Icon.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import NotificationsComponent from "@/components/NotificationsComponent.vue";
import ProductsListComponent from "@/components/ProductsListComponent.vue";
import TagsListComponent from "@/components/TagsListComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import Modal from "@/components/Modal.vue";
import Logo from "@/components/Logo.vue";
import CategoriesListComponent from "./components/CategoriesListComponent.vue";
import { useStore } from "@/services/Store";
import moment from "moment";
import { sendEmailVerification } from "@/services/Auth";
import mixpanel from "mixpanel-browser";
import ProgressBar from "primevue/progressbar";
import PricingView from "./views/PricingView.vue";
import { user } from "firebase-functions/v1/auth";

export default defineComponent({
  components: {
    NavbarComponent,
    FooterComponent,
    NotificationsComponent,
    ProductsListComponent,
    TagsListComponent,
    ButtonComponent,
    Modal,
    CategoriesListComponent,
    Logo,
    Icon,
    ProgressBar,
    PricingView,
  },
  data() {
    return {
      showEmailVerificationModal: ref(true),
      routeNamesWithProductSidebar: ref([
        "user flows",
        "user flow",
        "user flows search results",
        "screenshots",
        "screenshots in collection",
        "screenshots search results",
        "search results",
        "product",
        "screenshots by ui elements",
        "user flows by category",
        "user flow from screenshots",
        "user flow from category",
        "user flow from product",
        "user flow from ui element",
        "user flow from screenshots in collection",
      ]),
      routeNamesForUserFlows: ref([
        "user flows",
        "user flow",
        "user flows search results",
        "user flows by category",
        "user flow from category",
      ]),
      routeNamesScreenshots: ref([
        "screenshots",
        "screenshots in collection",
        "screenshots search results",
        "screenshots by ui elements",
        "user flow from screenshots",
        "user flow from ui element",
        "user flow from screenshots in collection",
      ]),
      router: useRouter(),
    };
  },
  setup() {
    onMounted(() => {
      if (!store.state.counts) {
        store.dispatch("fetchCounts");
      }
    });

    document.addEventListener("click", function (event) {
      const element = event.target as HTMLElement;
      const elementId = element.id;

      if (elementId) {
        mixpanel.track("Element Clicked", {
          "Element ID": elementId,
          "Element Tag": element.tagName,
        });
      }
    });

    const store = useStore();
    const user = computed(() => store.state.user);
    const globalLoading = computed(() => store.state.globalLoading);

    return {
      user,
      globalLoading,
    };
  },
  created() {
    const store = useStore();
    this.$watch(
      () => this.user.isLoggedIn,
      async () => {
        if (this.user.isLoggedIn && this.user.data.uid) {
          store.dispatch({
            type: "fetchCustomer",
            email: this.user.data.email,
          });
          store.dispatch({
            type: "fetchTeam",
            email: this.user.data.email,
          });
        }
      }
    );
    this.$watch(
      () => this.user.subscriptions,
      async () => {
        if (this.user.subscriptions) {
          store.dispatch({
            type: "fetchTeams",
            subscriptions: this.user.subscriptions,
          });
        }
      }
    );
  },
  methods: {
    async sendEmailVerificationEmail() {
      try {
        await sendEmailVerification();
      } catch (error) {
        console.error(error);
      }
    },
    getTimeLeftInTrial() {
      const trialEnds = this.user.trial.trialEnds;

      const now = moment();
      const end = moment(trialEnds);

      if (end.isBefore(now)) {
        return "Trial expired.";
      }

      const daysRemaining = Math.ceil(moment.duration(end.diff(now)).asDays());

      return `${daysRemaining} days left in trial.`;
    },
    navigateTo(page: any) {
      this.router.push({ name: page });
    },
  },
});
