
// import { getProductById } from "../services/FirebaseService";
import { computed, defineComponent, ref } from "vue";
import { RouterLink } from "vue-router";
import ProductLogo from "./ProductLogo.vue";
import AddIndustryComponent from "@/components/AddIndustryComponent.vue";
import CardComponent from "@/components/CardComponent.vue";
import VueWordHighlighter from "vue-word-highlighter";
import { useStore } from "@/services/Store";
import LoaderComponent from "./LoaderComponent.vue";

export default defineComponent({
  props: [
    "product",
    "size",
    "userFlows",
    "draftUserFlows",
    "screenshots",
    "industries",
    "hideDescription",
  ],
  setup() {
    const store = useStore();
    const user = computed(() => store.state.user);

    return {
      user,
    };
  },
  data() {
    return {
      showModal: ref(false),
    };
  },
  components: {
    RouterLink,
    ProductLogo,
    AddIndustryComponent,
    CardComponent,
    VueWordHighlighter,
    LoaderComponent,
  },
});
