
import { defineComponent, inject } from "vue";
import Icon from "./Icon.vue";
import VueWordHighlighter from "vue-word-highlighter";
import ButtonComponent from "./ButtonComponent.vue";

export default defineComponent({
  props: ["tag", "active", "allowClose"],
  emits: ["remove-tag"],
  components: { Icon, VueWordHighlighter, ButtonComponent },
  data() {
    return {
      auth: <any>inject("auth"),
    };
  },
  methods: {
    removeTag(event: any, id: any) {
      event.preventDefault();
      this.$emit("remove-tag", event, id);
    },
  },
});
