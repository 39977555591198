
import { computed, defineComponent, ref } from "vue";
import SidebarComponent from "./SidebarComponent.vue";
import Logo from "./Logo.vue";
import Icon from "./Icon.vue";
import ButtonComponent from "./ButtonComponent.vue";
import { useStore } from "@/services/Store";
import { sendEmailVerification } from "@/services/Auth";
import moment from "moment";
import { auth } from "@/firebase";
import FooterComponent from "./FooterComponent.vue";
import AvatarComponent from "./AvatarComponent.vue";
// import Icon from '@/components/Icon.vue'

export default defineComponent({
  data() {
    return {
      loginDoorOpen: ref(false),
      query: ref(),
    };
  },
  setup() {
    const store = useStore();

    const user = computed(() => store.state.user);
    const collections = computed(() => store.state.collections);

    return {
      user,
      collections,
    };
  },
  props: ["pageTitle"],
  methods: {
    togglePricingModal() {
      this.$store.commit("setShowPricingModal", !this.showPricingModal);
    },
    openLoginDoor() {
      this.loginDoorOpen = true;
    },
    closeLoginDoor() {
      this.loginDoorOpen = false;
    },
    async signOut() {
      try {
        await auth.signOut().then(() => {
          window.location.reload();
        });
      } catch (error) {
        console.error(error);
      }
    },
    async sendEmailVerificationEmail() {
      try {
        await sendEmailVerification();
      } catch (error) {
        console.error(error);
      }
    },
    getTimeLeftInTrial() {
      const trialEnds = this.user.trial.trialEnds;

      const now = moment();
      const end = moment(trialEnds);

      if (end.isBefore(now)) {
        return "Trial expired.";
      }

      const daysRemaining = Math.ceil(moment.duration(end.diff(now)).asDays());

      return `${daysRemaining} days left in trial.`;
    },
  },
  components: {
    SidebarComponent,
    Logo,
    Icon,
    ButtonComponent,
    FooterComponent,
    AvatarComponent,
  },
});
