import { reactive, readonly } from "vue";

interface Notification {
  message: string;
  timeoutId: NodeJS.Timeout | null; // New property to store the timeout ID
}

interface State {
  notifications: Notification[];
}

const state: State = reactive({
  notifications: [],
});

function addNotification(message: string): void {
  const notification: Notification = { message, timeoutId: null };

  setTimeout(() => {
    state.notifications.push(notification);
  }, 100);

  notification.timeoutId = setTimeout(() => {
    removeNotification(notification);
  }, 4000);
}

function removeNotification(notification: Notification): void {
  const index = state.notifications.indexOf(notification);
  if (index !== -1) {
    clearTimeout(notification.timeoutId!); // Clear the timeout before removing the notification
    state.notifications.splice(index, 1);
  }
}

export const notificationService = {
  state: readonly(state),
  addNotification,
  removeNotification,
};
