
import ProductLogo from "@/components/ProductLogo.vue";
import ProductCardComponent from "@/components/ProductCardComponent.vue";
import Icon from "@/components/Icon.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { defineComponent, ref, watch } from "vue";
import { RouterLink, useRoute, useRouter } from "vue-router";
import {
  getCollectionItemByKeyValue,
  getCollectionItemsByKeyValue,
  getItemsInCollection,
  searchCollectionItems,
  searchRelatedCollectionItems,
} from "@/services/FirebaseService";

export default defineComponent({
  components: {
    RouterLink,
    ProductLogo,
    ProductCardComponent,
    Icon,
    LoaderComponent,
  },
  data() {
    return {
      userflows: ref(),
      flows: ref(),
      products: ref(),
      productFilterQuery: ref(),
      numberOfProducts: ref(15),
      numberOfIndustries: ref(100),
      industries: ref(),
      industriesDataReady: ref(false),
      productDataReady: ref(false),
    };
  },
  async beforeMount() {
    this.getIndustries();
    try {
      await getItemsInCollection("products", this.numberOfProducts).then(
        (res: any) => {
          this.products = res.items;
        }
      );
    } catch (error) {
      console.error("could not get products");
    }
  },
  methods: {
    async getIndustries() {
      this.industriesDataReady = false;
      this.productDataReady = false;
      try {
        await getItemsInCollection("industries", this.numberOfIndustries).then(
          (res: any) => {
            this.industries = res.items;
            res.items.forEach(async (industry: any, index: any) => {
              try {
                await searchRelatedCollectionItems(
                  "products",
                  "industries",
                  industry.id,
                  this.numberOfProducts
                ).then((productRes: any) => {
                  this.industries[index].products = productRes.items;
                  if (index == res.items.length - 1) {
                    //sorting based on amount of products
                    this.industries = this.industries.sort((a: any, b: any) => {
                      return b.products.length - a.products.length;
                    });
                    //moving singles to other
                    const otherIndustry = { name: "Other", products: [] };
                    this.industries.forEach((industry: any) => {
                      if (industry.products.length < 2) {
                        otherIndustry.products = [
                          ...otherIndustry.products,
                          ...industry.products,
                        ];
                      }
                    });
                    //add other industry object to industries
                    this.industries.push(otherIndustry);

                    //filter out sinletons
                    this.industries = this.industries.filter(
                      (x: any) => x.products.length > 1
                    );

                    this.productDataReady = true;
                  }
                });
              } catch (error) {
                this.productDataReady = true;
              }
            });
            this.industriesDataReady = true;
          }
        );
      } catch (error) {
        console.error(error);
        this.industriesDataReady = true;
      }
    },
  },
});
