
import { defineComponent } from "vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import CardComponent from "./CardComponent.vue";

export default defineComponent({
  data() {
    return {
      faq: [
        {
          category: "Product",
          questions: [
            {
              question: "Who can benefit from Userflows?",
              answer:
                "Everyone involved in creating digital products can benefit from Userflows. Whether you're a UX designer, a researcher, a product manager, or a product owner, Userflows is designed to help you create better digital experiences.",
            },
            {
              question: "How does Userflows enhance my design process?",
              answer:
                "Imagine having a vast library of successful design examples at your disposal. Userflows brings this to your design process, enabling you to learn from proven user flows, adopt best practices, and ultimately create more intuitive and effective digital products.",
            },
            {
              question: "Can I download invoices for my subscription?",
              answer:
                "Yes, once you're a member, you have the ability to download invoices for your subscription directly from your account settings. This ensures you can easily manage and keep track of your billing documents for your records or reimbursement purposes.",
            },
            {
              question: "When does Userflows update its library?",
              answer:
                "We refresh our content weekly, giving you constant access to the most current and relevant design examples to keep your work innovative and up-to-date.",
            },
            {
              question: "Can I contribute ideas to Userflows?",
              answer:
                "Yes, your suggestions for new features or content are always welcome! Your feedback is crucial for us to tailor Userflows even more closely to what you need for success.",
            },
            {
              question: "What’s included in a Userflows subscription?",
              answer:
                "Your subscription unlocks unlimited access to our comprehensive library of user flow videos and screenshots. Plus, you have the opportunity to influence the platform's future by suggesting features and requesting new content. It's everything you need to elevate your design process in one package.",
            },
          ],
        },
      ],
    };
  },
  components: {
    Accordion,
    AccordionTab,
    CardComponent,
  },
});
