
import { computed, defineComponent, onMounted, ref } from "vue";
import Icon from "./Icon.vue";
import ProductLogo from "./ProductLogo.vue";
import { useStore } from "@/services/Store";
import AvatarComponent from "./AvatarComponent.vue";
import CardComponent from "./CardComponent.vue";
import VueWordHighlighter from "vue-word-highlighter";
import ButtonComponent from "./ButtonComponent.vue";
import DraggableScrollDirective from "@/directives/DraggableDirective";
import ProductCardComponent from "./ProductCardComponent.vue";

export default defineComponent({
  setup() {
    const store = useStore();

    const user = computed(() => store.state.user);
    const tags = computed(() => store.state.tags);
    const industries = computed(() => store.state.industries);
    const products = computed(() => store.state.products);
    const userFlows = computed(() => store.state.userFlows);
    const collections = computed(() => store.state.collections);

    return {
      user,
      tags,
      industries,
      userFlows,
      products,
      collections,
    };
  },
  data() {
    return {
      query: ref(this.$route.params.s ? this.$route.params.s : ""),
      groupedData: ref([]),
      showSearchResults: ref(false),
      groupedDataResults: ref(null),
      store: useStore(),
    };
  },
  props: [],
  emits: [],
  components: {
    Icon,
    ProductLogo,
    AvatarComponent,
    CardComponent,
    VueWordHighlighter,
    ButtonComponent,
    ProductCardComponent,
  },
  directives: {
    draggableScroll: DraggableScrollDirective,
  },
  mounted() {
    this.showSearchResults = false;
  },
  created() {
    this.$watch(
      () => {
        {
          this.industries, this.userFlows, this.tags, this.products;
        }
      },
      async () => {
        this.prepareData();
      },
      { deep: true }
    );
  },
  methods: {
    showResults(data, item) {
      this.showSearchResults = false;
      if (data.label == "Collections" && item.id) {
        this.$router.push({
          name: "screenshots in collection",
          params: {
            collectionId: item.id,
            pageNumber: 1,
          },
        });
      } else {
        this.$router.push({
          ...this.$route,
          name: "search results",
          params: { s: item.label },
        });
      }
    },
    search() {
      if (!this.store.state.industries) {
        this.store.dispatch("fetchIndustries");
      }
      if (!this.store.state.tags) {
        this.store.dispatch("fetchTags");
      }
      if (!this.store.state.products) {
        this.store.dispatch("fetchProducts");
      }
      if (!this.store.state.userFlows) {
        this.store.dispatch("fetchUserFlows");
      }
      if (!this.store.state.collections) {
        this.store.dispatch("fetchCollections");
      }

      const query = this.query.toLowerCase();

      this.groupedDataResults = [
        ...this.groupedData
          ?.map((group) => {
            return {
              label: group.label,
              pageName: group.pageName ? group.pageName : null,
              items: group.items?.filter((item) => {
                // Convert query to lowercase once at the beginning
                const lowerCaseQuery = query.toLowerCase();

                // Check if label matches query
                const labelMatchesQuery = item.label
                  ?.toLowerCase()
                  .includes(lowerCaseQuery);

                // Check if description is a string and matches query
                const descriptionMatchesQuery =
                  typeof item.description === "string" &&
                  item.description.toLowerCase().includes(lowerCaseQuery);

                // Return true if either label or description matches the query
                return labelMatchesQuery || descriptionMatchesQuery;
              }),
            };
          })
          ?.filter((group) => group.items?.length > 0),
      ];
    },
    prepareData() {
      // Function to filter duplicates based on the 'label' property
      const filterDuplicates = (array) => {
        const unique = {};
        return array?.filter((item) => {
          if (!unique[item.label]) {
            unique[item.label] = true;
            return true;
          }
          return false;
        });
      };

      // Map and filter products
      const uniqueIndustries = filterDuplicates(
        this.industries?.map((industry) => ({
          ...industry,
          label: industry.name,
        }))
      );
      // Map and filter user flows
      const uniqueCollections = filterDuplicates(
        this.collections
          ?.filter((collection) => {
            return collection.public;
          })
          ?.map((collection) => ({
            ...collection,
            label: collection.name,
          }))
      );

      // Map and filter products
      const uniqueTags = filterDuplicates(
        this.tags?.map((tag) => ({
          ...tag,
          label: tag.name,
        }))
      );

      // Map and filter products
      const uniqueProducts = filterDuplicates(
        this.products?.map((product) => ({
          ...product,
          label: product.title,
        }))
      );

      // Map and filter user flows
      const uniqueUserFlows = filterDuplicates(
        this.userFlows?.map((userFlow) => ({
          ...userFlow,
          label: userFlow.title,
        }))
      );

      this.groupedData = [];

      this.groupedData.push({
        label: "Products",
        pageName: "products",
        items: uniqueProducts,
      });
      this.groupedData.push({
        label: "Collections",
        items: uniqueCollections,
      });
      this.groupedData.push({
        label: "User flows",
        pageName: "user flows main",
        items: uniqueUserFlows,
      });
      this.groupedData.push({
        label: "UI elements",
        pageName: "screenshots main",
        items: uniqueTags,
      });
      this.groupedData.push({
        label: "Industries",
        items: uniqueIndustries,
      });
    },
  },
});
