import { createStore, useStore as baseUseStore } from "vuex";
import {
  fetchAllCountsData,
  fetchData,
  getCollectionItemById,
  getCollectionItemByKeyValue,
  getCollectionItemsByIds,
  getCollectionItemsByKeyValue,
  getItemsInCollection,
  searchCollectionItems,
  searchRelatedCollectionItems,
} from "./FirebaseService";
import user from "./Auth";

// define injection key
export const key = Symbol();

export const store = createStore({
  state: {
    globalLoading: false,
    user: user,
    customer: null,
    product: null,
    products: null,
    emptyProducts: [],
    industries: null,
    popularTags: null,
    selectedTag: {},
    selectedTagScreenshots: [],
    categories: null,
    popularCategories: null,
    selectedCategory: {},
    selectedCategoryUserFlow: null,
    popularProducts: [],
    userFlow: {},
    userFlowProduct: null,
    numberOfUserFlowScreenshots: null,
    tags: null,
    pageTags: [],
    userFlows: null,
    draftUserFlows: null,
    userFlowsCount: null,
    screenshots: null,
    emails: null,
    paginatedEmails: null,
    // paginatedUserFlows: null,
    screenshotsCount: null,
    collection: null,
    collectionRelatedTags: null,
    collections: null,
    personalCollections: null,
    publicCollections: null,
    emailFilter: {
      product: null,
    },
    userFlowSorter: {
      sortName: "Free first",
      field: "locked",
      direction: "asc",
    },
    userFlowFilter: {
      category: null,
      product: null,
      device: null,
    },
    screenshotFilter: {
      tag: null,
      product: null,
      device: "",
    },
    productFilter: {
      industry: null,
    },
    searchFilter: {
      device: "",
    },
    showPricingModal: false,
    teams: [],
    team: null,
    blog: [],
    plan: null,
    checkedScreenshots: [],
    counts: null,
  },
  mutations: {
    setCollection(state, collection) {
      state.collection = collection;
    },
    setCheckedScreenshots(state, checkedScreenshots) {
      state.checkedScreenshots = checkedScreenshots;
    },
    setCollectionRelatedTags(state, collectionRelatedTags) {
      state.collectionRelatedTags = collectionRelatedTags;
    },
    setPersonalCollections(state, personalCollections) {
      state.personalCollections = personalCollections;
    },
    setPublicCollections(state, publicCollections) {
      state.publicCollections = publicCollections;
    },
    setEmails(state, emails) {
      state.emails = emails;
    },
    setPaginatedEmails(state, paginatedEmails) {
      state.paginatedEmails = paginatedEmails;
    },
    setGlobalLoading(state, globalLoading) {
      state.globalLoading = globalLoading;
    },
    setPlan(state, plan) {
      state.plan = plan;
    },
    setCustomer(state, customer) {
      state.customer = customer;
    },
    setProduct(state, product) {
      state.product = product;
    },
    setProducts(state, products) {
      state.products = products;
    },
    setEmptyProducts(state, emptyProducts) {
      state.emptyProducts = emptyProducts;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setIndustries(state, industries) {
      state.industries = industries;
    },
    setPopularTags(state, popularTags) {
      state.popularTags = popularTags;
    },
    setSelectedTag(state, selectedTag) {
      state.selectedTag = selectedTag;
      store.dispatch("fetchSelectedTagScreenshots", selectedTag);
    },
    setSelectedTagScreenshots(state, selectedTagScreenshots) {
      state.selectedTagScreenshots = selectedTagScreenshots;
    },
    setPopularCategories(state, popularCategories) {
      state.popularCategories = popularCategories;
    },
    setSelectedCategory(state, selectedCategory) {
      state.selectedCategory = selectedCategory;
      store.dispatch("fetchSelectedCategoryUserflow", selectedCategory);
    },
    setSelectedCategoryUserFlow(state, selectedCategoryUserFlow) {
      state.selectedCategoryUserFlow = selectedCategoryUserFlow;
    },
    setPopularProducts(state, popularProducts) {
      state.popularProducts = popularProducts;
    },
    setUserFlow(state, userFlow) {
      state.userFlow = userFlow;
    },
    setUserFlowProduct(state, userFlowProduct) {
      state.userFlowProduct = userFlowProduct;
    },
    setNumberOfUserFlowScreenshots(state, numberOfUserFlowScreenshots) {
      state.numberOfUserFlowScreenshots = numberOfUserFlowScreenshots;
    },
    setTags(state, tags) {
      state.tags = tags;
    },
    setPageTags(state, pageTags) {
      state.pageTags = pageTags;
    },
    setUserFlows(state, userFlows) {
      state.userFlows = userFlows;
    },
    setDraftUserFlows(state, draftUserFlows) {
      state.draftUserFlows = draftUserFlows;
    },
    setUserFlowsCount(state, userFlowsCount) {
      state.userFlowsCount = userFlowsCount;
    },
    setScreenshots(state, screenshots) {
      state.screenshots = screenshots;
    },
    setScreenshotsCount(state, screenshotsCount) {
      state.screenshotsCount = screenshotsCount;
    },
    setCollections(state, collections) {
      state.collections = collections;
    },
    setSearchFilter(state, searchFilter) {
      state.searchFilter = searchFilter;
    },
    setEmailFilter(state, emailFilter) {
      state.emailFilter = emailFilter;
    },
    setUserFlowSorter(state, userFlowSorter) {
      state.userFlowSorter = userFlowSorter;
    },
    setUserFlowFilter(state, userFlowFilter) {
      state.userFlowFilter = userFlowFilter;
    },
    setScreenshotFilter(state, screenshotFilter) {
      state.screenshotFilter = screenshotFilter;
    },
    setProductFilter(state, productFilter) {
      state.productFilter = productFilter;
    },
    setShowPricingModal(state, showPricingModal) {
      state.showPricingModal = showPricingModal;
    },
    setTeams(state, teams) {
      state.teams = teams;
    },
    setTeam(state, team) {
      state.team = team;
    },
    setBlog(state, blog) {
      state.blog = blog;
    },
    setCounts(state, counts) {
      state.counts = counts;
    },
  },
  actions: {
    async fetchCounts({ commit }) {
      try {
        await fetchAllCountsData().then((res) => {
          commit("setCounts", res);
        });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCustomer({ commit }, { email }) {
      try {
        await getCollectionItemByKeyValue("customers", "email", email).then(
          (res: any) => {
            commit("setCustomer", res);
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async fetchPaginatedEmails({ commit }, { params }) {
      try {
        await fetchData(
          "emails",
          params.limit,
          params.pageNumber,
          "dateReceived",
          "desc",
          params.filters
        ).then(async (res: any) => {
          // Assuming res.items contains the array of email objects
          const emails = res.items;

          // Fetch product details for each email
          const emailsWithProducts = await Promise.all(
            emails.map(async (email) => {
              // Fetch the product by productId
              if (email.productId) {
                const product = await getCollectionItemById(
                  "products",
                  email.productId
                );

                // Add the product information to the email object
                return { ...email, product };
              }
              return email;
            })
          );

          // Commit the enriched emails to the store
          commit("setPaginatedEmails", { ...res, items: emailsWithProducts });
        });
      } catch (error) {
        console.error(error);
      }
    },
    // async fetchPaginatedScreenshots({ commit }, { params }) {
    //   try {
    //     const res = await fetchData(
    //       "screenshots",
    //       params.limit,
    //       params.pageNumber,
    //       store.state.user.isAdmin ? "created" : "time",
    //       store.state.user.isAdmin ? "desc" : "asc",
    //       params.filters,
    //       params.lastDocSnapshot
    //     );

    //     console.log(
    //       params.limit,
    //       params.pageNumber,
    //       store.state.user.isAdmin ? "created" : "time",
    //       store.state.user.isAdmin ? "desc" : "asc",
    //       params.filters,
    //       params.lastDocSnapshot
    //     );

    //     const screenshotsWithDetails = await Promise.all(
    //       res.items.map(async (screenshot) => {
    //         let enrichedScreenshot = { ...screenshot };

    //         let screenshotTags: any = [];

    //         if (screenshot.tags) {
    //           screenshotTags = await getCollectionItemsByIds(
    //             "tags",
    //             screenshot.tags
    //           );
    //         }
    //         enrichedScreenshot.screenshotTags = screenshotTags;

    //         return enrichedScreenshot;
    //       })
    //     );

    //     // Commit the enriched user flows to the store
    //     commit("setPaginatedScreenshots", {
    //       ...res,
    //       items: params.lastDocSnapshot
    //         ? store.state.paginatedScreenshots.items.concat(
    //             screenshotsWithDetails
    //           )
    //         : screenshotsWithDetails,
    //     });
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    // async fetchPaginatedUserFlows({ commit }, { params }) {
    //   try {
    //     const res = await fetchData(
    //       "user flows",
    //       params.limit,
    //       params.pageNumber,
    //       params.sorter.field,
    //       params.sorter.direction,
    //       params.filters,
    //       params.lastDocSnapshot
    //     );

    //     const userFlowsWithDetails = await Promise.all(
    //       res.items.map(async (userFlow) => {
    //         let enrichedUserFlow = { ...userFlow };

    //         // Fetch the product by productId if available
    //         if (userFlow.productId) {
    //           enrichedUserFlow.product = await getCollectionItemById(
    //             "products",
    //             userFlow.productId
    //           );
    //         }

    //         // Fetch screenshots related to the user flow
    //         const screenshots: any = await fetchData(
    //           "screenshots",
    //           50,
    //           1,
    //           "time",
    //           "asc",
    //           [{ field: "userflowId", value: userFlow.id }]
    //         );
    //         enrichedUserFlow.screenshots = screenshots.items;

    //         return enrichedUserFlow;
    //       })
    //     );

    //     // Commit the enriched user flows to the store
    //     commit("setPaginatedUserFlows", {
    //       ...res,
    //       items: params.lastDocSnapshot
    //         ? store.state.paginatedUserFlows.items.concat(userFlowsWithDetails)
    //         : userFlowsWithDetails,
    //     });
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    async fetchEmails({ commit }) {
      try {
        await getItemsInCollection("emails", 10000).then((res: any) => {
          commit(
            "setEmails",
            sortArrayByDateKey(res.items, "dateReceived", true)
              .sort((a, b) => {
                if (a.published !== b.published) {
                  return a.published ? 1 : -1;
                }
                const dateA: any = new Date(a.dateReceived);
                const dateB: any = new Date(b.dateReceived);
                return dateA - dateB;
              })
              .filter((email) => {
                return email.productId;
              })
          );
        });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCollections({ commit }) {
      try {
        await fetchData("collections", 500, 1, "created", "desc", [
          { field: "public", value: true },
        ]).then((res: any) => {
          commit("setCollections", sortArrayByDateKey(res.items, "updated"));
        });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCollectionRelatedTags({ commit }, data) {
      let tagIds: any = [];
      data.screenshots.forEach((screenshot: any) => {
        tagIds = tagIds.concat(screenshot.tags);
      });
      try {
        await getCollectionItemsByIds(
          "tags",
          orderByMostRepeating(tagIds).slice(0, 10)
        ).then((res) => {
          commit("setCollectionRelatedTags", res);
        });
      } catch (error) {}
    },
    async fetchPublicCollections({ commit }) {
      try {
        const collections: any = await fetchData(
          "collections",
          100,
          1,
          "created",
          "desc",
          [{ field: "public", value: true }]
        );

        const collectionsWithScreenshots = await Promise.all(
          collections.items.map(async (collection) => {
            const screenshots = await getCollectionItemsByIds(
              "screenshots",
              collection.screenshots
            );
            return {
              ...collection,
              collectionScreenshots: screenshots,
            };
          })
        );

        commit("setPublicCollections", collectionsWithScreenshots);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCollection({ commit }, data) {
      try {
        await getCollectionItemById("collections", data.id).then(
          async (collection: any) => {
            // Assuming `getCollectionItemsByIds` returns an array of screenshots
            const collectionScreenshots: any = await getCollectionItemsByIds(
              "screenshots",
              collection.screenshots
            );

            // Fetching product data for each screenshot
            const screenshotsWithProducts = await Promise.all(
              collectionScreenshots.map(async (screenshot) => {
                // Replace `getCollectionItemById` with your method of fetching a single product by ID
                const product = await getCollectionItemById(
                  "products",
                  screenshot.productId
                );
                return {
                  ...screenshot,
                  product, // Add the fetched product here
                };
              })
            );

            commit("setCollection", {
              ...collection,
              collectionScreenshots: screenshotsWithProducts, // Now includes products
            });

            store.dispatch({
              type: "fetchCollectionRelatedTags",
              screenshots: screenshotsWithProducts, // Ensure you're dispatching with products if needed
            });
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async fetchPersonalCollections({ commit }) {
      try {
        const collections: any = await getCollectionItemsByKeyValue(
          "collections",
          {
            key: "userId",
            value: store.state.user.data.uid,
          }
        );

        const collectionsWithScreenshots = await Promise.all(
          collections.items.map(async (collection) => {
            const screenshots = await getCollectionItemsByIds(
              "screenshots",
              collection.screenshots
            );
            return {
              ...collection,
              collectionScreenshots: screenshots,
            };
          })
        );

        commit("setPersonalCollections", collectionsWithScreenshots);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchProduct({ commit }, title) {
      if (!title) return;
      try {
        const product: any = await getCollectionItemByKeyValue(
          "products",
          "title",
          title
        );
        commit("setProduct", {
          ...product,
          screenshots: null,
          productIndustries: null,
          userFlows: null,
        });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchUserFlow({ commit }, id) {
      if (id) {
        try {
          await getCollectionItemById("user flows", id).then(
            async (res: any) => {
              commit("setUserFlow", {
                ...res,
                product: null,
                screenshots: null,
                emails: null,
              });
            }
          );
        } catch (error) {
          console.error(error);
        }
      }
    },
    async fetchPopularTags({ commit }) {
      try {
        // Fetch categories from the collection
        const res: any = await searchCollectionItems(
          "tags",
          12,
          1,
          "page",
          "name",
          null,
          "screenshotCount",
          "desc"
        );
        const tagsWithExtras = await Promise.all(
          res.items.map(async (tag) => {
            try {
              // Fetch userflows related to the category
              const tagScreenshots = await fetchData(
                "screenshots",
                1,
                1,
                "time",
                "asc",
                [{ field: "tags", value: [tag.id] }]
              );
              tag.screenshots = tagScreenshots;
              return tag;
            } catch (error) {
              console.error(error);
              return tag; // Return category even if there's an error
            }
          })
        );

        // Process categories after all have been fetched and augmented
        const popularTags = tagsWithExtras;
        // commit("setSelectedCategory", popularCategories[0]);
        commit("setPopularTags", popularTags);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchPopularCategories({ commit }) {
      try {
        // Fetch categories from the collection
        const res = await fetchData(
          "categories",
          100,
          1,
          "userFlowCount",
          "desc"
        );
        const categoriesWithExtras = await Promise.all(
          res.items.map(async (category) => {
            try {
              // Fetch 5 user flows related to the category
              const userflowRes = await fetchData(
                "user flows",
                5,
                1,
                "locked",
                "asc",
                [{ field: "categories", value: [category.id] }]
              );

              // Process user flows to attach screenshots and fetch related categories
              let allProductsIds = [];
              const userflowsWithExtras = await Promise.all(
                userflowRes.items.map(async (userflow, index) => {
                  try {
                    const screenshotsRes: any = await fetchData(
                      "screenshots",
                      20,
                      1,
                      "time",
                      "asc",
                      [{ field: "userflowId", value: userflow.id }]
                    );
                    userflow.screenshots = screenshotsRes.items || [];

                    // Fetch categories related to the userflow
                    if (userflow.categories && userflow.categories.length > 0) {
                      const userFlowCategories = await Promise.all(
                        userflow.categories.map(async (categoryId) => {
                          return await getCollectionItemById(
                            "categories",
                            categoryId
                          );
                        })
                      );
                      userflow.categories = userFlowCategories;
                    }

                    if (userflow.productId) {
                      allProductsIds.push(userflow.productId);
                    }

                    return index === 0 ? userflow : null; // Return the first user flow to attach to the category
                  } catch (error) {
                    console.error(error);
                    return index === 0 ? userflow : null; // Return the first user flow even if there's an error
                  }
                })
              );

              // Deduplicate product IDs
              const uniqueProductIds = [...new Set(allProductsIds)];

              // Fetch products based on extracted product IDs
              const products = await getCollectionItemsByIds(
                "products",
                uniqueProductIds
              );

              // Attach the first user flow and the fetched products to the category object
              category.userflows = userflowsWithExtras.filter(
                (u) => u !== null
              ); // Attach only the first user flow
              category.products = products; // Attach all fetched products
              return category;
            } catch (error) {
              console.error(error);
              return category; // Return category even if there's an error
            }
          })
        );

        commit("setPopularCategories", categoriesWithExtras);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchPopularProducts({ commit }) {
      try {
        await getItemsInCollection("products", 12).then((res: any) => {
          commit("setPopularProducts", res.items);
        });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCategories({ commit }) {
      try {
        await fetchData("categories", 500, 1, "userFlowCount", "desc").then(
          (res: any) => {
            commit("setCategories", res.items);
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async fetchProducts({ commit }) {
      try {
        const productsResponse: any = await fetchData(
          "products",
          500,
          1,
          "created",
          "desc"
        );
        const products: any = productsResponse.items;
        const productsWithDetails: any = await Promise.all(
          products.map(async (product) => {
            return {
              ...product,
              screenshots: null,
              productIndustries: null,
              userFlows: null,
            };
          })
        );
        commit("setProducts", productsWithDetails);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchIndustries({ commit }) {
      try {
        await fetchData("industries", 500, 1, "created", "desc").then(
          (res: any) => {
            commit("setIndustries", res.items);
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async fetchUserFlowProduct({ commit }, userFlow) {
      try {
        await getCollectionItemById("products", userFlow.productId).then(
          (res: any) => {
            commit("setUserFlowProduct", res);
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async fetchTeams({ commit }, { subscriptions }) {
      let teams = [];
      subscriptions.forEach((subscription) => {
        subscription.items?.forEach(async (item) => {
          try {
            await getCollectionItemByKeyValue(
              "teams",
              "subscriptionId",
              item.subscription
            ).then((res: any) => {
              teams.push(res);
              commit("setTeams", teams);
            });
          } catch (error) {
            console.error(error);
          }
        });
      });
    },
    async fetchTeam({ commit }, { email }) {
      try {
        await searchRelatedCollectionItems("teams", "members", email).then(
          (res: any) => {
            commit("setTeam", res.items[0]);
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async fetchSelectedCategoryUserflow({ commit }, category) {
      try {
        await searchRelatedCollectionItems(
          "user flows",
          "categories",
          category.id
        ).then(async (res: any) => {
          const selectedCategoryUserflow = res.items[0];
          selectedCategoryUserflow.locked = false;

          commit("setSelectedCategoryUserFlow", selectedCategoryUserflow);
        });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchSelectedTagScreenshots({ commit }, tag) {
      try {
        await searchRelatedCollectionItems(
          "screenshots",
          "tags",
          tag.id,
          4,
          1,
          undefined,
          undefined,
          "userflowId"
        ).then((res: any) => {
          commit("setSelectedTagScreenshots", res.items);
        });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchTags({ commit }) {
      try {
        fetchData("tags", 500, 1, "screenshotCount", "desc").then(
          (res: any) => {
            commit("setTags", res.items);
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async fetchUserFlows({ commit }) {
      try {
        await fetchData("user flows", 500, 1, "created", "asc").then(
          (res: any) => {
            commit("setUserFlows", res.items);
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async fetchScreenshots() {
      try {
        await fetchData("screenshots", 10, 1, "time", "asc").then(
          (res: any) => {
            this.commit("setScreenshots", res.items);
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async fetchBlog({ commit }) {
      try {
        await fetchData("blog", 6, 1, "created", "desc").then((res: any) => {
          commit("setBlog", res.items);
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
});

function sortArrayByDateKey(arr, key, isAscending = false) {
  return arr.sort((a, b) => {
    const dateA: any = new Date(a[key]),
      dateB: any = new Date(b[key]);
    return isAscending ? dateA - dateB : dateB - dateA;
  });
}

function sortArrayByKey(arr, key) {
  return arr.sort((a, b) => {
    return a[key].localeCompare(b[key]);
  });
}

// define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key);
}

function orderByMostRepeating(values: any) {
  // Count the occurrences of each value
  const counts = values.reduce((acc: any, value: any) => {
    acc[value] = (acc[value] || 0) + 1;
    return acc;
  }, {});

  // Group values by their frequency
  const groupedByCount = Object.entries(counts).reduce(
    (acc: any, [value, count]: any) => {
      if (!acc[count]) {
        acc[count] = [];
      }
      acc[count].push(value);
      return acc;
    },
    {}
  );

  // Sort the groups by frequency in descending order
  const sortedGroups = Object.keys(groupedByCount).sort(
    (a: any, b: any) => b - a
  );

  // Flatten the groups into a single array, with most frequent values first
  const sortedArray = sortedGroups.flatMap((count) =>
    groupedByCount[count].map((value) => value)
  );

  return sortedArray;
}
