import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex-fill d-flex flex-column" }
const _hoisted_2 = { class: "page-content-component overflow-y-auto overflow-x-hidden w-100 mh-100 flex-fill d-flex flex-column" }
const _hoisted_3 = {
  key: 0,
  class: "page-content-component__header px-1 py-1 px-md-3 bg-light border-bottom"
}
const _hoisted_4 = { class: "d-flex flex-column gap-1" }
const _hoisted_5 = { class: "d-flex flex-fill align-items-center gap-1" }
const _hoisted_6 = { class: "d-flex flex-column flex-md-row flex-fill gap-1 position-relative" }
const _hoisted_7 = { class: "d-none d-lg-flex flex-shrink-1 overflow-auto" }
const _hoisted_8 = {
  key: 1,
  class: "d-flex align-items-center gap-1 flex-shrink-0"
}
const _hoisted_9 = { class: "d-flex d-lg-none flex-shrink-1 overflow-auto" }
const _hoisted_10 = { class: "d-flex w-100" }
const _hoisted_11 = {
  key: 0,
  style: { 'min-width': '20rem', 'max-width': '20rem' }
}
const _hoisted_12 = { class: "d-flex flex-column flex-fill" }
const _hoisted_13 = { class: "flex-fill d-flex flex-column px-1 py-3 px-md-3" }
const _hoisted_14 = { class: "container h-100" }
const _hoisted_15 = { class: "d-flex flex-column gap-2 mb-2 position-relative z-1" }
const _hoisted_16 = {
  key: 0,
  class: "page-content__breadcrumbs d-flex align-items-center gap-1"
}
const _hoisted_17 = {
  class: "d-flex align-items-center gap-3 w-100",
  style: { 'min-height': '4rem' }
}
const _hoisted_18 = {
  key: 0,
  class: "d-flex align-items-center gap-2"
}
const _hoisted_19 = ["id", "contenteditable"]
const _hoisted_20 = { class: "flex-shrink-0 flex-fill" }
const _hoisted_21 = { class: "d-flex flex-column gap-4 align-items-stretch flex-column flex-fill pb-3" }
const _hoisted_22 = { class: "d-flex flex-column align-items-center pb-3 gap-2" }
const _hoisted_23 = { class: "d-flex align-items-center gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_AvatarComponent = _resolveComponent("AvatarComponent")!
  const _component_SidebarComponent = _resolveComponent("SidebarComponent")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      (!_ctx.hideHeader)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass({ 'container-fluid': _ctx.showSidebar, container: !_ctx.showSidebar })
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_Logo, { class: "me-2 py-1" }),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      (_ctx.user.isLoggedIn)
                        ? (_openBlock(), _createBlock(_component_RouterLink, {
                            key: 0,
                            class: "text-decoration-none",
                            to: { name: 'dashboard' }
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ButtonComponent, {
                                class: _normalizeClass(["page-content-component__link", {
                        'page-content-component__link--active':
                          _ctx.$route.name == 'dashboard',
                      }]),
                                label: 'Home',
                                style: 'light'
                              }, null, 8, ["class"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_RouterLink, {
                        class: "text-decoration-none",
                        to: { name: 'user flows main' }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ButtonComponent, {
                            class: _normalizeClass(["page-content-component__link", {
                        'page-content-component__link--active':
                          _ctx.$route.name.includes('user flows'),
                      }]),
                            label: 'User flows',
                            style: 'light'
                          }, null, 8, ["class"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_RouterLink, {
                        class: "text-decoration-none",
                        to: { name: 'screenshots main' }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ButtonComponent, {
                            class: _normalizeClass(["page-content-component__link", {
                        'page-content-component__link--active':
                          _ctx.$route.name.includes('screenshots'),
                      }]),
                            label: 'Screens',
                            style: 'light'
                          }, null, 8, ["class"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_RouterLink, {
                        class: "text-decoration-none d-flex align-items-center",
                        to: { name: 'emails main' }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ButtonComponent, {
                            class: _normalizeClass(["page-content-component__link", {
                        'page-content-component__link--active':
                          _ctx.$route.name.includes('emails'),
                      }]),
                            label: 'Emails',
                            style: 'light'
                          }, null, 8, ["class"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_RouterLink, {
                        class: "text-decoration-none mb-md-auto",
                        to: { name: 'products' }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ButtonComponent, {
                            class: _normalizeClass(["page-content-component__link", {
                        'page-content-component__link--active':
                          _ctx.$route.name.includes('product'),
                      }]),
                            label: 'Products',
                            style: 'light'
                          }, null, 8, ["class"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_RouterLink, {
                        class: "text-decoration-none mb-md-auto",
                        to: { name: 'collections' }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ButtonComponent, {
                            class: _normalizeClass(["page-content-component__link", {
                        'page-content-component__link--active':
                          _ctx.$route.name.includes('collections'),
                      }]),
                            label: 'Collections',
                            style: 'light'
                          }, null, 8, ["class"])
                        ]),
                        _: 1
                      }),
                      (!_ctx.user.isLoggedIn)
                        ? (_openBlock(), _createBlock(_component_RouterLink, {
                            key: 1,
                            class: "text-decoration-none mb-md-auto",
                            to: { name: 'pricing' }
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ButtonComponent, {
                                class: _normalizeClass(["page-content-component__link", {
                        'page-content-component__link--active':
                          _ctx.$route.name.includes('pricing'),
                      }]),
                                label: 'Pricing',
                                style: 'light'
                              }, null, 8, ["class"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createVNode(_component_Menu, {
                    ref: "menu",
                    id: "overlay_menu",
                    model: _ctx.accountItems,
                    popup: true
                  }, null, 8, ["model"]),
                  (_ctx.user.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_AvatarComponent, {
                        key: 0,
                        class: "cursor-pointer",
                        email: _ctx.user.data.name ? _ctx.user.data.name : _ctx.user.data.email,
                        onClick: _ctx.toggle,
                        "aria-haspopup": "true",
                        "aria-controls": "overlay_menu"
                      }, null, 8, ["email", "onClick"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_RouterLink, {
                          class: "text-decoration-none",
                          to: { name: 'pricing' }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ButtonComponent, { label: 'Join' })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_RouterLink, {
                          class: "text-decoration-none",
                          to: { name: 'login' }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ButtonComponent, {
                              style: 'light',
                              label: 'Sign in'
                            })
                          ]),
                          _: 1
                        })
                      ])),
                  _createVNode(_component_ButtonComponent, {
                    "data-canny-changelog": "",
                    style: 'light',
                    icon: 'bell',
                    "icon-size": 'lg'
                  })
                ]),
                _createElementVNode("div", _hoisted_9, [
                  (_ctx.user.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_RouterLink, {
                        key: 0,
                        class: "text-decoration-none",
                        to: { name: 'dashboard' }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ButtonComponent, {
                            class: _normalizeClass(["page-content-component__link", {
                    'page-content-component__link--active':
                      _ctx.$route.name == 'dashboard',
                  }]),
                            label: 'Home',
                            style: 'light'
                          }, null, 8, ["class"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_RouterLink, {
                    class: "text-decoration-none",
                    to: { name: 'user flows main' }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ButtonComponent, {
                        class: _normalizeClass(["page-content-component__link", {
                    'page-content-component__link--active':
                      _ctx.$route.name.includes('user flows'),
                  }]),
                        label: 'User flows',
                        style: 'light'
                      }, null, 8, ["class"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_RouterLink, {
                    class: "text-decoration-none",
                    to: { name: 'screenshots main' }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ButtonComponent, {
                        class: _normalizeClass(["page-content-component__link", {
                    'page-content-component__link--active':
                      _ctx.$route.name.includes('screenshots'),
                  }]),
                        label: 'Screens',
                        style: 'light'
                      }, null, 8, ["class"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_RouterLink, {
                    class: "text-decoration-none",
                    to: { name: 'emails main' }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ButtonComponent, {
                        class: _normalizeClass(["page-content-component__link", {
                    'page-content-component__link--active':
                      _ctx.$route.name.includes('emails'),
                  }]),
                        label: 'Emails',
                        style: 'light'
                      }, null, 8, ["class"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_RouterLink, {
                    class: "text-decoration-none mb-md-auto",
                    to: { name: 'products' }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ButtonComponent, {
                        class: _normalizeClass(["page-content-component__link", {
                    'page-content-component__link--active':
                      _ctx.$route.name.includes('product'),
                  }]),
                        label: 'Products',
                        style: 'light'
                      }, null, 8, ["class"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_RouterLink, {
                    class: "text-decoration-none mb-md-auto",
                    to: { name: 'collections' }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ButtonComponent, {
                        class: _normalizeClass(["page-content-component__link", {
                    'page-content-component__link--active':
                      _ctx.$route.name.includes('collections'),
                  }]),
                        label: 'Collections',
                        style: 'light'
                      }, null, 8, ["class"])
                    ]),
                    _: 1
                  }),
                  (!_ctx.user.isLoggedIn)
                    ? (_openBlock(), _createBlock(_component_RouterLink, {
                        key: 1,
                        class: "text-decoration-none mb-md-auto",
                        to: { name: 'pricing' }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ButtonComponent, {
                            class: _normalizeClass(["page-content-component__link", {
                    'page-content-component__link--active':
                      _ctx.$route.name.includes('pricing'),
                  }]),
                            label: 'Pricing',
                            style: 'light'
                          }, null, 8, ["class"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ])
            ], 2)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        (_ctx.showSidebar)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(_component_SidebarComponent, { class: "border-end" }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "sideBar")
                ]),
                _: 3
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                (
                    _ctx.$route.meta.breadcrumbs && _ctx.previousRoute.fullPath != '/'
                  )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createVNode(_component_ButtonComponent, {
                        icon: 'arrow-left-long',
                        label: (_ctx.$route.meta.breadcrumbs as any).title,
                        style: 'light',
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
                      }, null, 8, ["label"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_17, [
                  (_ctx.pageTitle)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _renderSlot(_ctx.$slots, "beforeTitle"),
                        _createElementVNode("h2", {
                          id: _ctx.id,
                          contenteditable: _ctx.titleEditable,
                          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.titleEditable && _ctx.updateTitle())),
                          class: "mb-0"
                        }, _toDisplayString(_ctx.pageTitle), 41, _hoisted_19)
                      ]))
                    : _createCommentVNode("", true),
                  _renderSlot(_ctx.$slots, "afterTitle"),
                  _createElementVNode("div", _hoisted_20, [
                    _renderSlot(_ctx.$slots, "pageActions")
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _renderSlot(_ctx.$slots, "default")
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_RouterLink, {
                class: "text-decoration-none",
                to: { name: 'requests and feedback' },
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ButtonComponent, {
                    icon: "comment-dots",
                    label: "How can we make this page better?",
                    style: 'light',
                    size: "lg"
                  })
                ]),
                _: 1
              })
            ])
          ]),
          (_ctx.$route.name == 'home')
            ? (_openBlock(), _createBlock(_component_FooterComponent, {
                key: 0,
                class: "bg-dark"
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}