import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["type", "id", "placeholder", "value", "autofocus", "name", "required", "rows", "disabled"]
const _hoisted_3 = ["type", "id", "placeholder", "value", "autofocus", "name", "required", "rows", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-group", { 'form-group--icon': _ctx.icon, type: _ctx.type }])
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          class: "form-group__icon text-primary",
          icon: _ctx.icon
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    (_ctx.label && _ctx.type != 'file')
      ? (_openBlock(), _createElementBlock("label", {
          key: 1,
          class: "w-100 mb-1",
          for: _ctx.id
        }, _toDisplayString(_ctx.label), 9, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.type === 'textarea')
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 2,
          "data-hj-allow": "",
          type: _ctx.type,
          class: _normalizeClass(["form-control", { 'invisible w-0 h-0 position-fixed z-0': _ctx.type == 'file' }]),
          id: _ctx.id,
          placeholder: _ctx.placeholder,
          value: _ctx.value,
          autofocus: _ctx.autofocus,
          name: _ctx.name,
          required: _ctx.required,
          rows: _ctx.rows,
          disabled: _ctx.disabled,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInputChange && _ctx.handleInputChange(...args)))
        }, null, 42, _hoisted_2))
      : (_openBlock(), _createElementBlock("input", {
          key: 3,
          "data-hj-allow": "",
          type: _ctx.type,
          class: _normalizeClass(["form-control form-group__input", { 'invisible w-0 h-0 position-fixed z-0': _ctx.type == 'file' }]),
          id: _ctx.id,
          placeholder: _ctx.placeholder,
          value: _ctx.value,
          autofocus: _ctx.autofocus,
          name: _ctx.name,
          required: _ctx.required,
          rows: _ctx.rows,
          disabled: _ctx.disabled,
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInputChange && _ctx.handleInputChange(...args)))
        }, null, 42, _hoisted_3)),
    (_ctx.type == 'file')
      ? (_openBlock(), _createBlock(_component_ButtonComponent, {
          key: 4,
          icon: 'upload',
          style: _normalizeStyle(_ctx.buttonStyle ? _ctx.buttonStyle : 'secondary'),
          label: _ctx.label,
          class: "w-100",
          onClick: _ctx.triggerUpload
        }, null, 8, ["style", "label", "onClick"]))
      : _createCommentVNode("", true)
  ], 2))
}