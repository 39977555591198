
import ButtonComponent from "../components/ButtonComponent.vue";
import FieldComponent from "../components/FieldComponent.vue";
import ErrorComponent from "../components/ErrorComponent.vue";
import Icon from "../components/Icon.vue";
import { computed, defineComponent, ref } from "vue";
import { getCleanErrorMessage, registerUser } from "@/services/Auth";
import { notificationService } from "@/services/NotificationsService";
import { useHead } from "@vueuse/head";
import { useRouter } from "vue-router";
import mixpanel from "mixpanel-browser";
import PageComponent from "@/components/PageComponent.vue";
import PageContentComponent from "@/components/PageContentComponent.vue";
import firebase from "firebase/compat/app";
import { useStore } from "@/services/Store";
import CardComponent from "./CardComponent.vue";
import { createCheckoutSession } from "@stripe/firestore-stripe-payments";
import { payments } from "@/services/Stripe";

export default defineComponent({
  setup() {
    const store = useStore();

    useHead({
      title: "Registration | User flows",
      meta: [
        {
          name: "description",
          content:
            "Join us and get access to curated real product user flows and screenshots collection.",
        },
      ],
    });

    const subscriptions = computed(() => store.state.subscriptions);
    const plan = computed(() => store.state.plan);
    const user = computed(() => store.state.user);

    return {
      subscriptions,
      plan,
      user,
    };
  },
  data() {
    return {
      email: ref(),
      password: ref(),
      agree: ref(false),
      showAgreeError: ref(false),
      error: ref(),
      loading: ref(false),
      router: useRouter(),
      store: useStore(),
      promoCode: ref(),
    };
  },
  mounted() {
    if (this.$route.query?.code) {
      const checkStripeDiscountCode = firebase
        .functions()
        .httpsCallable("checkStripeDiscountCode");
      checkStripeDiscountCode({ code: this.$route.query?.code })
        .then((result) => {
          console.log(result.data && result.data.promotionCode.active);
          this.promoCode = result.data.valid ? result.data.promotionCode : null;
          notificationService.addNotification(
            `Discount code with ${this.promoCode.coupon.percent_off}% off applied`
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  },
  methods: {
    async registerAndPay() {
      this.error = null;
      this.loading = true;
      await registerUser(
        this.email,
        this.password,
        this.$route.query.token
          ? `https://userflows.co.uk/register?token=${this.$route.query.token}`
          : null
      ).then(async (res: any) => {
        if (res.error) {
          this.error = getCleanErrorMessage(res.message);
          this.loading = false;
        } else {
          mixpanel.track("Event", {
            Event: "Registration",
          });
          if (this.plan) {
            this.goToPay();
          }
        }
      });
    },
    async signInWithGoogle() {
      try {
        const inviteLink = this.$route.query.token
          ? `https://userflows.co.uk/register?token=${this.$route.query.token}`
          : null;

        const provider = new firebase.auth.GoogleAuthProvider();
        const result = await firebase.auth().signInWithPopup(provider);
        const user = result.user;

        // Tracking new Google sign-ins as "Registration" events
        mixpanel.track("Event", {
          Event: "Google Registration",
        });

        if (inviteLink) {
          const db = firebase.firestore();
          const snapshot = await db
            .collection("teams")
            .where("inviteLink", "==", inviteLink)
            .get();

          if (!snapshot.empty) {
            const team = snapshot.docs[0].data();
            if (team.inviteLinkActive) {
              const teamId = snapshot.docs[0].id;
              await db
                .collection("teams")
                .doc(teamId)
                .update({
                  members: firebase.firestore.FieldValue.arrayUnion(user.email),
                });
              // Proceed to payment after updating team information
              await this.goToPay();
            } else {
              throw new Error("Invite link is not active.");
            }
          } else {
            throw new Error("Invalid invite link.");
          }
        } else {
          // Go directly to payment after successful sign-in, no invite link required
          await this.goToPay();
        }
      } catch (error) {
        console.error(error);
        // Handle errors, maybe show a message to the user
      }
    },

    async goToPay() {
      console.log("redirecting");

      this.store.commit("setGlobalLoading", true);
      if (this.user.isLoggedIn && !this.user.isSubscribed) {
        try {
          const sessionParams: any = {
            price: this.plan.price.id,
            quantity: this.plan.price.quantity,
          };

          if (this.promoCode) {
            sessionParams.promotion_code = this.promoCode.id;
          }

          const session = await createCheckoutSession(payments, sessionParams);

          // Open the payment session in a new tab
          window.open(session.url, "_blank");

          // Optionally, redirect the current tab to a safe landing page
          // window.location.href = "/dashboard"; // Redirect to a thank you page, or similar
          window.close();
        } catch (error) {
          console.error("Error creating checkout session:", error);
          // Handle errors, maybe show a message to the user
        } finally {
          this.store.commit("setGlobalLoading", false);
        }
      }
    },
  },
  components: {
    ButtonComponent,
    FieldComponent,
    Icon,
    ErrorComponent,
    PageComponent,
    PageContentComponent,
    CardComponent,
  },
});
