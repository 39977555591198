import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = {
  class: "d-flex gap-2 flex-column my-2 h-100",
  style: { 'max-height': '80vh' }
}
const _hoisted_3 = { class: "pe-2 flex-shrink-0" }
const _hoisted_4 = { class: "d-flex flex-column gap-1 flex-fill" }
const _hoisted_5 = {
  key: 0,
  class: "flex-fill overflow-hidden d-flex flex-row align-items-start gap-2 pb-1"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "d-flex flex-column flex-md-row flex-fill overflow-hidden" }
const _hoisted_8 = {
  key: 0,
  class: "flex-fill d-flex flex-column w-100 overflow-auto flex-fill"
}
const _hoisted_9 = { class: "d-flex px-2 mb-1 fw-bold text-muted" }
const _hoisted_10 = {
  class: "d-flex flex-fill flex-column items list-unstyled pe-1 overflow-auto",
  style: { 'max-height': '100%', overflow: 'auto' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ProductCardComponent = _resolveComponent("ProductCardComponent")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _directive_draggable_scroll = _resolveDirective("draggable-scroll")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex flex-column gap-1 search-component__input", { 'mx-auto': _ctx.$route.name == 'home' }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("form", {
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => {
          this.showSearchResults = false;
          _ctx.$router.push({
            ..._ctx.$route,
            name: 'search results',
            params: { s: _ctx.query },
          });
        }, ["prevent"]))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["d-flex align-items-center position-relative mx-auto w-100", { 'w-100': _ctx.showSearchResults }]),
            style: { 'max-width': '35rem' }
          }, [
            _withDirectives(_createElementVNode("input", {
              class: "form-control rounded-5 ps-4 w-100",
              placeholder: "Search in user flows, screenshots and products",
              onInput: _cache[0] || (_cache[0] = ($event: any) => {
              _ctx.showSearchResults = true;
              _ctx.search();
            }),
              onFocus: _cache[1] || (_cache[1] = ($event: any) => {
              _ctx.prepareData();
              _ctx.showSearchResults = true;
              _ctx.search();
            }),
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.query) = $event))
            }, null, 544), [
              [_vModelText, _ctx.query]
            ]),
            _createVNode(_component_Icon, {
              class: "text-primary start-0 position-absolute ps-2",
              icon: 'search'
            })
          ], 2)
        ], 32)
      ])
    ], 2),
    (_ctx.showSearchResults && _ctx.groupedDataResults)
      ? (_openBlock(), _createBlock(_component_CardComponent, {
          key: 0,
          class: "position-absolute w-100 h-auto shadow search-component__results",
          style: _normalizeStyle({ top: '3.4rem', 'max-width': 'calc(100vw - 5rem)' })
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_ctx.groupedDataResults && _ctx.groupedDataResults.length > 0)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.groupedDataResults, (data) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (data.label == 'Products')
                          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.items.slice(0, 20), (item) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "cursor-pointer d-flex flex-column align-items-center gap-1 justify-content-start",
                                  onClick: ($event: any) => {
                _ctx.showSearchResults = false;
                _ctx.$router.push({
                  name: 'product',
                  params: { productTitle: item.label },
                });
              }
                                }, [
                                  _createVNode(_component_ProductCardComponent, {
                                    hideDescription: true,
                                    style: { 'min-width': '14rem', 'max-width': '14rem' },
                                    product: item
                                  }, null, 8, ["product"])
                                ], 8, _hoisted_6))
                              }), 256))
                            ])), [
                              [_directive_draggable_scroll]
                            ])
                          : _createCommentVNode("", true)
                      ]))
                    }), 256))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_7, [
                (_ctx.groupedDataResults && _ctx.groupedDataResults.length > 0)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.groupedDataResults, (data) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        (data.label != 'Products')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createElementVNode("p", _hoisted_9, _toDisplayString(data.label), 1),
                              _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_10, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.items.slice(0, 20), (item) => {
                                  return (_openBlock(), _createElementBlock("li", null, [
                                    _createVNode(_component_ButtonComponent, {
                                      buttonClasses: 'pt-1 pb-1 px-2 justify-content-start h-auto',
                                      icon: data.label == 'Collections' ? 'folder' : false,
                                      "icon-weight": 'light',
                                      style: 'light',
                                      size: 'sm',
                                      label: 
                    data.label == 'Collections'
                      ? `${item.label} (${item.screenshots.length})`
                      : item.label
                  ,
                                      highlightQuery: _ctx.query,
                                      onClick: ($event: any) => (_ctx.showResults(data, item))
                                    }, null, 8, ["icon", "label", "highlightQuery", "onClick"])
                                  ]))
                                }), 256))
                              ])), [
                                [_directive_draggable_scroll]
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 256))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 1
        }, 8, ["style"]))
      : _createCommentVNode("", true),
    (_ctx.showSearchResults == true)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "search-component__backdrop",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showSearchResults = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}