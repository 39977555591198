
import { RouterLink } from "vue-router";
import { computed, defineComponent, inject, onMounted, ref } from "vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import Icon from "@/components/Icon.vue";
import FieldComponent from "@/components/FieldComponent.vue";
import IconLabelComponent from "@/components/IconLabelComponent.vue";
import {
  fetchData,
  getCollectionItemById,
  getCollectionItemByKeyValue,
  getCollectionItemsByKeyValue,
  getItemsInCollection,
  searchCollectionItems,
  searchRelatedCollectionItems,
} from "@/services/FirebaseService";
import { useHead } from "@vueuse/head";
import LoaderComponent from "@/components/LoaderComponent.vue";
import ScreenshotCardComponent from "@/components/ScreenshotCardComponent.vue";
import { useStore } from "@/services/Store";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import PageComponent from "@/components/PageComponent.vue";
import PageContentComponent from "@/components/PageContentComponent.vue";
import DraggableScrollDirective from "@/directives/DraggableDirective";
import Logo from "@/components/Logo.vue";
import CardComponent from "@/components/CardComponent.vue";
import CollectionComponent from "@/components/CollectionComponent.vue";
import SearchComponent from "@/components/SearchComponent.vue";
import ScreenshotGalleryComponent from "@/components/ScreenshotGalleryComponent.vue";
import FaqComponent from "@/components/FaqComponent.vue";
import RegistrationComponent from "@/components/RegistrationComponent.vue";
import ProductLogo from "@/components/ProductLogo.vue";
import VideoCard from "@/components/VideoCard.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import PricingComponent from "@/components/PricingComponent.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper/modules";
import AvatarGroup from "primevue/avatargroup";
import Avatar from "primevue/avatar";
import Modal from "@/components/Modal.vue";
import UserFlowStoryCards from "@/components/UserFlowStoryCards.vue";

export default defineComponent({
  components: {
    ButtonComponent,
    Icon,
    RouterLink,
    ScreenshotCardComponent,
    LoaderComponent,
    FieldComponent,
    IconLabelComponent,
    Accordion,
    AccordionTab,
    PageComponent,
    PageContentComponent,
    Logo,
    CardComponent,
    CollectionComponent,
    SearchComponent,
    ScreenshotGalleryComponent,
    FaqComponent,
    RegistrationComponent,
    ProductLogo,
    VideoCard,
    Swiper,
    SwiperSlide,
    PricingComponent,
    AvatarGroup,
    Avatar,
    VideoPlayer,
    Modal,
    UserFlowStoryCards,
  },
  directives: {
    draggableScroll: DraggableScrollDirective,
  },
  data() {
    return {
      post: ref(),
      blogPosts: ref(),
      exampleCategoryIndex: ref(0),
      auth: <any>inject("auth"),
      query: ref(),
      email: ref(),
      formResponse: ref(),
      signingUp: ref(false),
      videoIsReady: ref(false),
      galleryIndex: ref(),
      showGallery: ref(false),
      modules: [Navigation, Pagination],
      store: useStore(),
      trustedReviews: this.shuffle([
        {
          review: "Userflows is a goldmine for mastering user flow design.",
          name: "Nick Babich",
          position: "UX Consultant",
          avatarImage: "Nick_Babich.jpeg",
          urlLink: "https://www.linkedin.com/in/nbabich/",
        },
        {
          review:
            "Essential resource for keeping up with UX design industry standards.",
          name: "Nurkhon Akhmedov",
          position: "User Experience Designer",
          avatarImage: "Nurkhon_Akhmedov.jpeg",
          urlLink: "https://www.linkedin.com/in/nurxmedov/",
        },
        {
          review:
            "userflows.co.uk — your time-saver in the UX research process!",
          name: "Ozan Öztaskiran",
          position: "Digital Product Designer",
          avatarImage: "Ozan_Oztaskiran.jpeg",
          urlLink: "https://www.linkedin.com/in/ozanoz/",
        },
        {
          review:
            "A must-have product for elevating your UX design and research game.",
          name: "Jan Mraz",
          position: "UX / UI Designer",
          avatarImage: "jan_mraz.jpg",
          urlLink: "https://www.instagram.com/janm_ux/",
        },
        {
          review: "One of the best places to find user flows of real products.",
          name: "Willian Matiola",
          position: "Product Designer",
          avatarImage: "Willian_Matiola.jpeg",
          urlLink: "https://bento.me/willianmatiola",
        },
      ]),
    };
  },
  computed: {
    categoryProducts() {
      return (category) => {
        const products = this.products?.filter((product) => {
          return this.userFlows?.some((userFlow) => {
            return (
              userFlow.productId == product.id &&
              userFlow.categories?.includes(category.id)
            );
          });
        });
        return products;
      };
    },
    sortedCategories() {
      return this.categories?.sort((a, b) => {
        return (
          this.userFlows?.filter((userFlow) => {
            return userFlow.categories?.includes(b.id);
          }).length -
          this.userFlows?.filter((userFlow) => {
            return userFlow.categories?.includes(a.id);
          }).length
        );
      });
    },
    publicCollections() {
      return this.collections?.filter((collection) => {
        return collection.public;
      });
    },
  },
  setup() {
    const store = useStore();

    useHead({
      title: "User flow video recordings and screenshots | User flows",
      meta: [
        {
          name: "description",
          content: `User flow video recordings and screenshots. Get access to documented user flow recordings and screenshots.`,
        },
      ],
    });

    onMounted(() => {
      if (!store.state.popularCategories) {
        store.dispatch("fetchPopularCategories");
      }
    });

    const popularCategories = computed(() => store.state.popularCategories);
    const counts = computed(() => store.state.counts);

    return {
      popularCategories,
      counts,
    };
  },
  mounted() {
    this.getBlogPosts();
  },
  methods: {
    async getBlogPosts() {
      try {
        await fetchData("blog", 6, 1, "created", "desc").then((res: any) => {
          this.blogPosts = res.items;
        });
      } catch (error) {
        console.error(error);
      }
    },
    togglePricingModal() {
      this.$store.commit("setShowPricingModal", !this.showPricingModal);
    },
    submitForm() {
      const addContact = firebase
        .functions()
        .httpsCallable("addContactToEmailOctopus");
      const data = {
        email: this.email,
        status: "SUBSCRIBED",
      };
      addContact(data)
        .then((result) => {
          this.formResponse = {
            status: "success",
            message: "Thank you for subscribing.",
          };
          this.clearForm();
          this.signingUp = false;
        })
        .catch((error) => {
          this.formResponse = {
            status: "error",
            message: "Error submitting the form, try again later.",
          };
          this.signingUp = false;
        });
    },
    clearForm() {
      this.email = "";
    },
    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
      });
    },
    setSelectedTag(tag: any) {
      this.$store.commit("setSelectedTag", tag);
    },
    openGallery(index: any) {
      this.galleryIndex = index;
      this.showGallery = true;
    },
    selectCategory(category: any) {
      this.$store.commit("setSelectedCategory", category);
    },
    async getProduct(id) {
      try {
        this.product = await getCollectionItemById("products", id);
      } catch (error) {
        console.error(error);
      }
    },
    async getUserflowScreenshots(userflowId: any) {
      this.userflowScreenshots = null;
      try {
        await getCollectionItemsByKeyValue(
          "screenshots",
          {
            key: "userflowId",
            value: userflowId,
          },
          100,
          1,
          "time",
          "asc"
        ).then((res: any) => {
          this.userflowScreenshots = res.items;
          this.numberOfScreenshots = res.totalItems;
          this.videoIsReady = true;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getUserflows() {
      try {
        await getCollectionItemsByKeyValue(
          "user flows",
          {
            key: "draft",
            value: false,
          },
          1
        ).then((res: any) => {
          this.userflows = res.items;
        });
      } catch (error) {}
    },
    async getProducts() {
      try {
        await getItemsInCollection("products", 12).then((products: any) => {
          this.products = products.items;
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
});
