import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import { auth } from "@/firebase";
import user from "@/services/Auth";
import { notificationService } from "@/services/NotificationsService";

import mixpanel from "mixpanel-browser";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { fullPage: false },
  },
  {
    path: "/blog",
    name: "blog main",
    component: () => import("@/views/BlogsViewMain.vue"),
    redirect: { name: "blog", params: { pageNumber: 1 } }, // Set the default child route here
    meta: {
      fullPage: true,
    },
    children: [
      {
        path: "all/",
        name: "blog",
        component: () => import("@/views/BlogsView.vue"),
        meta: {
          fullPage: true,
        },
      },
      {
        path: ":postTitle/",
        name: "post",
        component: () => import("@/views/PostView.vue"),
        meta: {
          fullPage: true,
          breadcrumbs: {
            title: "Back",
            link: { name: "blog main" },
          },
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
    meta: { requiresNoAuth: true, fullPage: false },
  },
  {
    path: "/requests-and-feedback",
    name: "requests and feedback",
    component: () => import("@/views/RequestsAndFeedbackView.vue"),
    meta: { fullPage: false },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/DashboardView.vue"),
    meta: {
      requiresAuth: true,
      fullPage: true,
    },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("@/views/AccountView.vue"),
    meta: { requiresAuth: true, fullPage: true },
  },
  {
    path: "/questions",
    name: "questions",
    component: () => import("@/views/Questions.vue"),
    meta: { requiresAuth: true, fullPage: true },
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("@/views/PricingView.vue"),
    meta: { fullPage: false },
  },
  {
    path: "/register/:price?",
    name: "register",
    component: () => import("@/views/RegistrationView.vue"),
    meta: { fullPage: false },
  },
  {
    path: "/products/:industry?",
    name: "products",
    component: () => import("@/views/ProductsView.vue"),
    meta: { fullPage: true },
  },
  // {
  //   path: "/ui-elements",
  //   name: "ui elements",
  //   component: () => import("@/views/UiElementsView.vue"),
  //   meta: { fullPage: true },
  // },
  {
    path: "/search/:s?/",
    name: "search results",
    component: () => import("@/components/SearchResults.vue"),
    meta: { fullPage: true, blockPageScroll: true },
    children: [
      {
        path: ":userflowId/:time?",
        name: "user flow from search",
        component: () => import("../views/UserFlowView.vue"),
        meta: {
          parentPageName: "search results",
          blockPageScroll: true,
          showModal: true,
          fullPage: true,
          breadcrumbs: {
            title: "Back",
            link: { name: "search results" },
          },
        },
      },
    ],
  },
  {
    path: "/user-flows",
    name: "user flows main",
    component: () => import("@/components/UserFlowsInCategory.vue"),
    meta: {
      fullPage: true,
    },
    children: [
      {
        path: "video/:userflowId/:time?",
        name: "user flow",
        component: () => import("../views/UserFlowView.vue"),
        meta: {
          parentPageName: "user flows",
          blockPageScroll: true,

          showModal: true,
          fullPage: true,
          breadcrumbs: {
            title: "Back",
            link: { name: "user flows main" },
          },
        },
      },
    ],
  },
  {
    path: "/user-flows/:categoryName",
    name: "user flows in category",
    component: () => import("@/components/UserFlowsInCategory.vue"),
    meta: {
      parentPageName: "user flows",
      blockPageScroll: true,
      showModal: true,
      fullPage: true,
      breadcrumbs: {
        title: "Back",
        link: { name: "user flows main" },
      },
    },
  },
  {
    path: "/upcoming-user-flows/",
    name: "upcoming user flows main",
    redirect: { name: "upcoming user flows", params: { pageNumber: 1 } }, // Set the default child route here
    component: () => import("@/views/UserFlowsView.vue"),
    meta: { fullPage: true },
    children: [
      {
        path: "all/:pageNumber",
        name: "upcoming user flows",
        component: () => import("@/components/UserFlows.vue"),
        meta: { fullPage: true },
        children: [
          {
            path: ":userflowId/:time?",
            name: "user flow from upcoming user flows",
            component: () => import("../views/UserFlowView.vue"),
            meta: {
              parentPageName: "upcoming user flows",
              blockPageScroll: true,
              showModal: true,
              fullPage: true,
              breadcrumbs: {
                title: "Back",
                link: { name: "upcoming user flows main" },
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: "/emails/",
    name: "emails main",
    redirect: { name: "emails" }, // Set the default child route here
    component: () => import("@/views/EmailsView.vue"),
    meta: { fullPage: true },
    children: [
      {
        path: "",
        name: "emails",
        component: () => import("@/components/Emails.vue"),
        meta: { fullPage: true },
      },
    ],
  },
  {
    path: "/screenshots/",
    name: "screenshots main",
    redirect: { name: "screenshots", params: { pageNumber: 1 } }, // Set the default child route here
    component: () => import("@/views/ScreenshotsView.vue"),
    meta: { fullPage: true },
    children: [
      {
        path: "",
        name: "screenshots",
        component: () => import("@/components/Screenshots.vue"),
        meta: { fullPage: true },
        children: [
          {
            path: ":userflowId/:time?",
            name: "user flow from screenshots",
            component: () => import("../views/UserFlowView.vue"),
            meta: {
              parentPageName: "screenshots",
              blockPageScroll: true,
              showModal: true,
              fullPage: true,
              breadcrumbs: {
                title: "Back",
                link: { name: "screenshots main" },
              },
            },
          },
        ],
      },
      {
        path: "collections/",
        name: "collections main",
        redirect: { name: "collections" },
        component: () => import("@/views/CollectionsView.vue"),
        meta: {
          fullPage: false,
        },
        children: [
          {
            path: "personal/",
            name: "personal collections",
            component: () => import("@/components/PersonalCollections.vue"),
            meta: {
              fullPage: true,
              requiresAuth: true,
            },
          },
          {
            path: "all/",
            name: "collections",
            component: () => import("@/components/Collections.vue"),
            meta: {
              fullPage: true,
            },
          },
          {
            path: ":collectionId/",
            name: "screenshots in collection",
            component: () => import("@/views/CollectionView.vue"),
            meta: {
              fullPage: true,
              breadcrumbs: {
                title: "Back",
                link: { name: "collections" },
              },
            },
            children: [
              {
                path: ":userflowId/:time?",
                name: "user flow from screenshots in collection",
                component: () => import("../views/UserFlowView.vue"),
                meta: {
                  parentPageName: "screenshots in collection",
                  blockPageScroll: true,
                  showModal: true,
                  fullPage: true,
                  breadcrumbs: {
                    title: "Back",
                    link: { name: "screenshots in collection" },
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: "search/:pageNumber?/:s?",
        name: "screenshots search results",
        component: () => import("@/components/Screenshots.vue"),
        meta: { fullPage: true },
      },
      {
        path: "ui-elements/:pageNumber/:tagTitle",
        name: "screenshots by ui elements",
        component: () => import("@/components/TagResults.vue"),
        meta: {
          fullPage: true,
          breadcrumbs: {
            title: "Back",
            link: { name: "screenshots main" },
          },
        },
        children: [
          {
            path: ":userflowId/:time?",
            name: "user flow from ui element",
            component: () => import("../views/UserFlowView.vue"),
            meta: {
              parentPageName: "screenshots by ui elements",
              blockPageScroll: true,
              showModal: true,
              fullPage: true,
              breadcrumbs: {
                title: "Back",
                link: { name: "screenshots by ui elements" },
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: "/terms-of-services",
    name: "terms of services",
    component: () => import("@/views/TermsOfServiceView.vue"),
    meta: { fullPage: false },
  },
  {
    path: "/user-flows/flow/:flowId?",
    name: "user flows by flow",
    component: () => import("@/views/UserFlowsView.vue"),
    meta: { fullPage: true },
  },
  {
    path: "/:productTitle/user-flows-and-screenshots/",
    name: "product",
    component: () => import("@/views/ProductView.vue"),
    meta: {
      fullPage: true,
      breadcrumbs: { title: "Back", link: { name: "products" } },
    },
    children: [
      {
        path: ":userflowId/:time?",
        name: "user flow from product",
        component: () => import("../views/UserFlowView.vue"),
        meta: {
          parentPageName: "product",
          blockPageScroll: true,
          showModal: true,
          fullPage: true,
          breadcrumbs: {
            title: "Back",
            link: { name: "product" },
          },
        },
      },
    ],
  },
];

export let previousRoute = null;

const listOfUserFlowPages = [
  "user flow from ui element",
  "user flows by flow",
  "user flow from product",
  "user flow from screenshots in collection",
  "user flow from screenshots",
  "user flows search results",
  "user flow",
  "user flow from home",
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    if (
      listOfUserFlowPages.includes(to?.name as string) ||
      listOfUserFlowPages.includes(from?.name as string)
    ) {
      return false;
    } else {
      // Perform a manual scroll to the top of the .page-content-component element
      const pageContentComponent = document.querySelector(
        ".page-content-component"
      );
      if (pageContentComponent) {
        setTimeout(() => {
          pageContentComponent.scrollTop = 0;
        }, 0);
      }
      // Return false to prevent any additional automatic scrolling
      return false;
    }
  },
});

// Navigation guard
router.beforeEach(async (to, from, next) => {
  previousRoute = from;

  mixpanel.track("Page View", {
    "Page Name": to.name,
    Path: to.path,
  });

  const requiresAuth = to.meta.requiresAuth;
  const requiresNoAuth = to.meta.requiresNoAuth;
  const requiresNoSub = to.meta.requiresNoSub;

  // Wait for the authentication check to complete
  await new Promise<void>((resolve) => {
    const unsubscribe = auth.onAuthStateChanged((currentUser: any) => {
      if (currentUser) {
        // User is signed in
        user.isLoggedIn = true;
        user.data = currentUser;
      } else {
        // User is signed out
        user.isLoggedIn = false;
        user.data = null;
      }
      resolve();
    });
    return () => unsubscribe();
  });

  if (requiresAuth && !user.isLoggedIn) {
    notificationService.addNotification("Login required");
    next({ name: "login" });
  } else if (requiresNoAuth && user.isLoggedIn) {
    next({ name: "dashboard" });
  } else if (requiresNoSub && user.isSubscribed) {
    next({ name: "dashboard" });
  } else {
    next();
  }
});

export default router;
