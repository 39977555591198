import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error d-flex align-items-start gap-2 text-danger"
}
const _hoisted_2 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_ctx.error)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Icon, {
          class: "error__icon",
          style: { 'margin-top': '.25rem' },
          icon: 'exclamation-triangle'
        }),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.error), 1)
      ]))
    : _createCommentVNode("", true)
}