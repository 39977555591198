
import { RouterLink } from "vue-router";
import ButtonComponent from "./ButtonComponent.vue";
import FieldComponent from "@/components/FieldComponent.vue";
import Icon from "@/components/Icon.vue";
import Modal from "@/components/Modal.vue";
import TagComponent from "@/components/TagComponent.vue";
import { computed, defineComponent, inject, onMounted, ref } from "vue";
import Logo from "./Logo.vue";
import {
  createCollectionItem,
  getCollectionItemsByIds,
  getItemsInCollection,
  searchCollectionItems,
  updateCollectionItem,
} from "@/services/FirebaseService";
import { notificationService } from "@/services/NotificationsService";
import { useStore } from "@/services/Store";

export default defineComponent({
  components: {
    RouterLink,
    ButtonComponent,
    FieldComponent,
    Logo,
    Icon,
    Modal,
    TagComponent,
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      if (!store.state.industries) {
        store.dispatch("fetchIndustries");
      }
    });

    const user = computed(() => store.state.user);

    return {
      user,
    };
  },
  props: ["product"],
  data() {
    return {
      auth: <any>inject("auth"),
      showModal: ref(false),
      allIndustries: ref(),
      searchQuery: ref(),
      showSearchDropdown: ref(false),
      store: useStore(),
      productIndustries: ref(),
    };
  },
  created() {
    this.$watch(
      () => this.searchQuery,
      async () => {
        this.search();
      },
      { deep: true }
    );
  },
  mounted() {
    this.getIndustries();
  },
  computed: {
    getProductIndustries() {
      return this.industries.filter((industry: any) => {
        return this.product.productIndustries?.includes(industry.id);
      });
    },
  },
  methods: {
    async getIndustries() {
      try {
        this.productIndustries = await getCollectionItemsByIds(
          "industries",
          this.product.industries
        );
      } catch (error) {
        console.error(error);
      }
    },
    async search() {
      this.showSearchDropdown = true;
      if (this.searchQuery) {
        try {
          await searchCollectionItems(
            "industries",
            1000,
            1,
            this.searchQuery ? this.searchQuery : null,
            "name",
            undefined,
            "name",
            "asc",
            "name"
          ).then((res: any) => {
            this.allIndustries = res.items;
          });
        } catch (error) {}
      } else {
        try {
          await getItemsInCollection("industries", 100).then((res: any) => {
            this.allIndustries = res.items;
          });
        } catch (error) {
          console.error(error);
        }
      }
    },
    resetSearch() {
      this.searchQuery = "";
      this.showSearchDropdown = false;
    },
    async removeIndustry(industry) {
      const newProduct = { ...this.product };
      newProduct.industries = newProduct.industries.filter((id) => {
        return id != industry.id;
      });
      await updateCollectionItem("products", this.product.id, newProduct).then(
        () => {
          this.product.industries = newProduct.industries;
          notificationService.addNotification("Removed industry from product");
          this.getIndustries();
        }
      );
    },
    async addIndustry(id: any) {
      const newIndustryArray = [id];
      const newProduct = {
        ...this.product,
        industries: this.product.industries
          ? [...this.product.industries].concat(newIndustryArray)
          : newIndustryArray,
      };
      try {
        await updateCollectionItem("products", newProduct.id, newProduct).then(
          (res) => {
            this.product.industries = newProduct.industries;
            this.showModal = false;
            this.getIndustries();
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async addNewIndustry(industryName: string) {
      let foundCategories: any = [];
      this.allIndustries.forEach((industry: any) => {
        foundCategories.push(industry.name);
      });

      if (!foundCategories.includes(industryName)) {
        const industry = { name: industryName };
        try {
          await createCollectionItem("industries", industry).then((id: any) => {
            this.addIndustry(id);
          });
        } catch (error) {}
      }
    },
    showModalFunction() {
      this.showModal = true;
      this.search();
    },
  },
});
