import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-dialog mx-auto" }
const _hoisted_2 = { class: "modal-content bg-transparent" }
const _hoisted_3 = { class: "modal-header p-2 d-flex align-items-center" }
const _hoisted_4 = { class: "modal__title w-100 overflow-hidden" }
const _hoisted_5 = { class: "modal__close ms-auto d-flex align-items-center text-white" }
const _hoisted_6 = { class: "modal-body d-flex flex-column overflow-x-hidden rounded-bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal overflow-x-hidden", _ctx.fullScreen ? 'modal--full-screen' : 'modal--not-full-screen'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal__background bg-black bg-opacity-50", { 'bg-blur': _ctx.fullScreen }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close-modal')))
    }, null, 2),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _renderSlot(_ctx.$slots, "header")
          ]),
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_ButtonComponent, {
              class: "rounded-5 position-relative z-2",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close-modal'))),
              style: _normalizeStyle(_ctx.fullScreen ? 'secondary' : 'light'),
              "icon-size": 'xl',
              icon: 'times'
            }, null, 8, ["style"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ])
  ], 2))
}