
import { defineComponent } from "vue";
import ProductCardComponent from "@/components/ProductCardComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { useRouter } from "vue-router";
import CardComponent from "./CardComponent.vue";

export default defineComponent({
  props: ["posts"],
  components: {
    ProductCardComponent,
    ButtonComponent,
    CardComponent,
  },
  data() {
    return {
      router: useRouter(),
    };
  },
  methods: {
    navigateTo(page: any) {
      this.router.push({ name: page });
    },
  },
});
