
import { computed, defineComponent, inject, ref } from "vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import Icon from "@/components/Icon.vue";
import { useRoute, useRouter } from "vue-router";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import {
  Zoom,
  Navigation,
  Pagination,
  Keyboard,
  FreeMode,
  Virtual,
  EffectFade,
} from "swiper/modules";
import { useStore } from "@/services/Store";
import RegistrationComponent from "./RegistrationComponent.vue";
import ProductLogo from "./ProductLogo.vue";
import Paginator from "primevue/paginator";
import Slider from "primevue/slider";
import SelectButton from "primevue/selectbutton";
import mixpanel from "mixpanel-browser";
import VideoCard from "./VideoCard.vue";
import { getCollectionItemById } from "@/services/FirebaseService";

export default defineComponent({
  props: [
    "name",
    "screenshots",
    "numberOfScreenshots",
    "numberOfScreenshotsAllowed",
    "userflowsInDraft",
    "totalItems",
    "limit",
    "pageNumber",
    "locked",
    "activeTag",
    "hideHeader",
    "hideResizing",
    "showGallery",
    "galleryImageIndex",
    "hideSidebar",
  ],
  emits: ["update-screenshots", "close-gallery"],
  data() {
    return {
      router: useRouter(),
      tag: ref(),
      swiper: ref(),
      store: useStore(),
      screenshot: ref(),
      product: ref(),
      userFlow: ref(),
    };
  },
  components: {
    ButtonComponent,
    Swiper,
    SwiperSlide,
    Icon,
    RegistrationComponent,
    Paginator,
    Slider,
    SelectButton,
    ProductLogo,
    VideoCard,
  },
  setup() {
    const store = useStore();

    const totalScreenshots = computed(() => store.state.screenshots);
    const tags = computed(() => store.state.tags);
    const userFlows = computed(() => store.state.userFlows);
    const draftUserFlows = computed(() => store.state.draftUserFlows);
    const products = computed(() => store.state.products);

    return {
      totalScreenshots,
      tags,
      products,
      userFlows,
      draftUserFlows,
      modules: [
        Navigation,
        Keyboard,
        FreeMode,
        Pagination,
        Zoom,
        Virtual,
        EffectFade,
      ],
    };
  },
  computed: {
    screenshotUserFlow() {
      return this.product?.userFlows?.find(
        (userFlow) => userFlow.id == this.screenshot?.userflowId
      );
    },
  },
  methods: {
    goNext() {
      this.$refs.mySwiper.swiper.slideNext();
    },
    goPrev() {
      this.$refs.mySwiper.swiper.slidePrev();
    },
    onSlideLoad(swiper) {
      this.toggleVideo(this.screenshots[swiper.activeIndex]);
      this.getData(swiper);
    },
    onSlideChange(swiper) {
      this.toggleVideo(this.screenshots[swiper.activeIndex]);
      this.getData(swiper);
    },
    getData(swiper) {
      this.screenshot = this.screenshot = this.screenshots[swiper.activeIndex];
      this.getProduct(this.screenshots[swiper.activeIndex]);
      if (
        !this.userFlow ||
        this.userFlow?.id != this.screenshots[swiper.activeIndex].userflowId
      ) {
        this.getUserFlow(this.screenshots[swiper.activeIndex]);
      }
    },
    async getProduct(screenshot) {
      try {
        await getCollectionItemById("products", screenshot.productId).then(
          (res) => {
            this.product = res;
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async getUserFlow(screenshot) {
      try {
        await getCollectionItemById("user flows", screenshot.userflowId).then(
          (res) => {
            this.userFlow = res;
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    getVideoSrc(screenshot) {
      const baseUrl =
        "https://storage.googleapis.com/userflows-390418.appspot.com/user flows/";
      const userFlow = this.userFlows?.find(
        (userFlow) => userFlow?.id == screenshot.userflowId
      );

      const videoPathParts = userFlow?.video.path.split("/");
      const videoPath = videoPathParts?.pop(); // Correct usage of pop()

      return `${baseUrl}compressed-${videoPath}#t=${this.convertStringToTime(
        screenshot.time
      )},${this.convertStringToTime(screenshot.time) + 1000}`;
    },
    convertStringToTime(time: any) {
      if (typeof time !== "string") {
        time = String(time);
      }
      const [minutes, seconds] = time.split(":");
      const convertedTime = parseInt(minutes) * 60 + parseInt(seconds);
      return convertedTime;
    },
    handleVideoError(screenshot: { id: any }) {
      this.videoError = true;

      const videoElement = document.getElementById(
        `video_${screenshot.id}`
      ) as HTMLVideoElement;

      if (videoElement) {
        videoElement.load();
      }
    },
    videoSource(userflow: { video: { path: string } }) {
      // Base URL for the storage bucket
      const baseUrl =
        "https://storage.googleapis.com/userflows-390418.appspot.com/";

      // Extract the directory path and the filename
      const directoryPath = "user flows/";
      const filename = userflow?.video.path?.split("/").pop(); // Get the filename part after the last '/'

      // Construct the primary video path (compressed video)
      // by inserting 'compressed-' between the directory path and the filename
      const primaryPath = directoryPath + "compressed-" + filename;

      // Construct the fallback video path (original video)
      const fallbackPath = userflow?.video.path;

      // Return the full URL, using the primary path by default, and fallback path if there's been an error
      return baseUrl + (this.videoError ? fallbackPath : primaryPath);
    },
    toggleVideo(screenshot) {
      const userFlow = this.userFlows
        ?.concat(this.draftUserFlows)
        ?.find((userFlow) => {
          return userFlow?.id == screenshot.userflowId;
        });

      const product = this.products?.find((product) => {
        return product.id == userFlow?.productId;
      });

      mixpanel.track("Event", {
        Event: "Live screenshot",
        Device: userFlow?.mobile ? "mobile" : "desktop",
        Industries: product?.industries,
      });

      const video = document.getElementById(
        `video_${screenshot.id}`
      ) as HTMLVideoElement;

      video.src = this.getVideoSrc(screenshot);

      const alternativeSrc = () => {
        video.src = this.getVideoSrc(screenshot).replace("compressed-", "");
        video.removeEventListener("error", alternativeSrc);
      };

      video.addEventListener("error", alternativeSrc);

      if (video.readyState >= 3) {
        // HAVE_FUTURE_DATA or better
        this.playVideo(video, screenshot);
      } else {
        video.addEventListener(
          "canplay",
          () => this.playVideo(video, screenshot),
          { once: true }
        );
      }

      if (video.readyState >= 3) {
        // HAVE_FUTURE_DATA or better
        this.playVideo(video, screenshot);
      } else {
        video.addEventListener(
          "canplay",
          () => this.playVideo(video, screenshot),
          {
            once: true,
          }
        );
      }
    },

    playVideo(video, screenshot) {
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_: any) => {
            // Initialize progress bar
            const progressBar = document.getElementById(
              `progress-bar${screenshot.id}`
            );
            const swiperBg = document.getElementsByClassName("swiper")[0];
            const updateProgressBar = () => {
              const percentage =
                (video.currentTime -
                  (this.convertStringToTime(screenshot.time) - 1)) *
                25;
              progressBar.style.width = Math.max(percentage, 0) + "%";
              progressBar.style.height = ".2rem";
            };

            // Update progress bar immediately and then every second
            updateProgressBar(); // Update immediately
            const intervalId = setInterval(updateProgressBar, 1000);

            // Clear interval and reset progress bar when video ends or is paused
            const resetProgressBar = () => {
              clearInterval(intervalId);
              progressBar.style.width = "0%";
              progressBar.style.height = "0";
              swiperBg.classList.add("bg-opacity-75");
            };

            video.addEventListener("ended", resetProgressBar);
            video.addEventListener("pause", resetProgressBar);
            video.removeEventListener("error", null);

            // Existing timeout logic here
            setTimeout(() => {
              const v = video.currentSrc;
              video.src = "";
              video.load();
              resetProgressBar(); // Reset progress bar when video is reset
            }, 4000); // 5000 milliseconds = 5 seconds
          })
          .catch((error: any) => {
            console.error("Error trying to play the video:", error);
          });
      }
    },
  },
});
