import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.logo)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["product-logo d-flex align-items-center justify-content-center rounded-circle", _ctx.size == 'large' ? 'product-logo--' + _ctx.size + '' : ''])
      }, [
        _createElementVNode("img", {
          class: "product-logo__image",
          src: _ctx.logo,
          alt: "",
          loading: "lazy"
        }, null, 8, _hoisted_1)
      ], 2))
    : _createCommentVNode("", true)
}