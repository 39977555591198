
import { computed, defineComponent, ref } from "vue";
import { RouterLink } from "vue-router";
import Logo from "./Logo.vue";
import ButtonComponent from "./ButtonComponent.vue";
import FieldComponent from "./FieldComponent.vue";
import { useStore } from "@/services/Store";

export default defineComponent({
  components: { RouterLink, Logo, ButtonComponent, FieldComponent },
  data() {
    return {};
  },
  setup() {
    const store = useStore();

    const popularTags = computed(() => store.state.popularTags);
    const popularCategories = computed(() => store.state.popularCategories);
    const popularProducts = computed(() => store.state.popularProducts);

    return {
      popularTags,
      popularCategories,
      popularProducts,
    };
  },
  methods: {},
});
