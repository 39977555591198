
import { defineComponent } from "vue";
import PageComponent from "@/components/PageComponent.vue";
import PageContentComponent from "@/components/PageContentComponent.vue";
import FaqComponent from "@/components/FaqComponent.vue";
import PricingComponent from "@/components/PricingComponent.vue";

export default defineComponent({
  components: {
    FaqComponent,
    PageComponent,
    PageContentComponent,
    PricingComponent,
  },
});
