
import { computed, defineComponent, inject, onMounted, ref } from "vue";
import ScreenshotCardComponent from "./ScreenshotCardComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import ScreenshotGalleryComponent from "@/components/ScreenshotGalleryComponent.vue";
import Icon from "@/components/Icon.vue";
import { useRoute, useRouter } from "vue-router";
import {
  getCollectionItemByKeyValue,
  updateCollectionItem,
} from "@/services/FirebaseService";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import {
  Zoom,
  Navigation,
  Pagination,
  Keyboard,
  FreeMode,
} from "swiper/modules";
import user from "@/services/Auth";
import { useStore } from "@/services/Store";
import RegistrationComponent from "./RegistrationComponent.vue";
import Paginator from "primevue/paginator";
import Slider from "primevue/slider";
import { notificationService } from "@/services/NotificationsService";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import LoaderComponent from "./LoaderComponent.vue";
import CollectionComponent from "./CollectionComponent.vue";
import Menu from "primevue/menu";

export default defineComponent({
  props: [
    "name",
    "screenshots",
    "numberOfScreenshots",
    "numberOfScreenshotsAllowed",
    "userflowsInDraft",
    "totalItems",
    "limit",
    "pageNumber",
    "locked",
    "activeTag",
    "hideHeader",
    "hideResizing",
    "hideTags",
    "collection",
    "allowSelecting",
  ],
  emits: ["update-screenshots", "removed-from-collection"],
  data() {
    return {
      showScreenshot: ref(true),
      unlocking: ref(false),
      auth: <any>inject("auth"),
      router: useRouter(),
      galleryImageIndex: ref(),
      showGallery: ref(false),
      galleryScreenshots: this.screenshots,
      tag: ref(),
      size: ref(),
      swiper: ref(),
      user: user,
      screenshotWidths: ref([
        { name: "4 Columns", value: 3 },
        { name: "3 Columns", value: 4 },
        { name: "2 Columns", value: 6 },
        { name: "1 Column", value: 12 },
      ]),
      currentWidthIndex: ref(2),
      store: useStore(),
      liveScreenshots: ["Zd7l3A02fyQfAnrMhnCt", "d30qLfh9Qzdf5bicmZY7"],
    };
  },
  components: {
    ScreenshotCardComponent,
    ButtonComponent,
    PaginationComponent,
    Swiper,
    SwiperSlide,
    Icon,
    RegistrationComponent,
    Paginator,
    Slider,
    SelectButton,
    ScreenshotGalleryComponent,
    LoaderComponent,
    Dropdown,
    Menu,
  },
  created() {
    const route = useRoute();

    // Watch for route changes and update component data
    this.$watch(
      () => route.params.pageNumber,
      async () => {
        // this.getData();
      }
    );
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      if (!store.state.tags) {
        store.dispatch("fetchTags");
      }
    });

    const user = computed(() => store.state.user);
    const tags = computed(() => store.state.tags);
    const personalCollections = computed(() => store.state.personalCollections);

    return {
      user,
      tags,
      personalCollections,
      modules: [Navigation, Keyboard, FreeMode, Pagination, Zoom],
    };
  },
  async mounted() {
    this.getData();
    this.size = localStorage.getItem("size") ? localStorage.getItem("size") : 1;
  },
  methods: {
    addUniqueCollectionName(baseName: any) {
      let counter = 1;
      let potentialName = baseName;

      while (true) {
        // Use the `some` method to check if the name exists in the array.
        let exists = this.personalCollections.value?.some(
          (collection) => collection.name == potentialName
        );

        if (!exists) {
          break; // If the name doesn't exist, break out of the loop.
        }

        potentialName = `${baseName} ${counter}`; // If the name exists, append the counter and loop again.
        counter++;
      }

      // Use the determined unique name as a const.
      const uniqueName = potentialName;

      // Push the new name to the collectionsay.
      return uniqueName;
    },
    changeScreenshotWidth() {
      this.currentWidthIndex =
        this.currentWidthIndex == this.screenshotWidths.length - 1
          ? 0
          : this.currentWidthIndex + 1;
    },
    getVideoSrc(screenshot) {
      const baseUrl =
        "https://storage.googleapis.com/userflows-390418.appspot.com/user flows/";
      const userFlow = this.userFlows.find(
        (userFlow) => userFlow.id == screenshot.userflowId
      );

      const videoPathParts = userFlow.video.path.split("/");
      const videoPath = videoPathParts.pop(); // Correct usage of pop()

      return `${baseUrl}compressed-${videoPath}#t=${this.convertStringToTime(
        screenshot.time
      )},${this.convertStringToTime(screenshot.time) + 1000}`;
    },
    convertStringToTime(time: any) {
      if (typeof time !== "string") {
        time = String(time);
      }
      const [minutes, seconds] = time.split(":");
      const convertedTime = parseInt(minutes) * 60 + parseInt(seconds);
      return convertedTime;
    },
    handleVideoError(screenshot: { id: any }) {
      this.videoError = true;

      const videoElement = document.getElementById(
        `video_${screenshot.id}`
      ) as HTMLVideoElement;

      if (videoElement) {
        videoElement.load();
      }
    },
    videoSource(userflow: { video: { path: string } }) {
      // Base URL for the storage bucket
      const baseUrl =
        "https://storage.googleapis.com/userflows-390418.appspot.com/";

      // Extract the directory path and the filename
      const directoryPath = "user flows/";
      const filename = userflow.video.path?.split("/").pop(); // Get the filename part after the last '/'

      // Construct the primary video path (compressed video)
      // by inserting 'compressed-' between the directory path and the filename
      const primaryPath = directoryPath + "compressed-" + filename;

      // Construct the fallback video path (original video)
      const fallbackPath = userflow.video.path;

      // Return the full URL, using the primary path by default, and fallback path if there's been an error
      return baseUrl + (this.videoError ? fallbackPath : primaryPath);
    },
    toggleVideo(screenshot) {
      const video = document.getElementById(
        `video_${screenshot.id}`
      ) as HTMLVideoElement;

      video.src = this.getVideoSrc(screenshot);

      const alternativeSrc = () => {
        video.src = this.getVideoSrc(screenshot).replace("compressed-", "");
        video.removeEventListener("error", alternativeSrc);
      };

      video.addEventListener("error", alternativeSrc);

      if (video.readyState >= 3) {
        // HAVE_FUTURE_DATA or better
        this.playVideo(video, screenshot);
      } else {
        video.addEventListener(
          "canplay",
          () => this.playVideo(video, screenshot),
          { once: true }
        );
      }

      if (video.readyState >= 3) {
        // HAVE_FUTURE_DATA or better
        this.playVideo(video, screenshot);
      } else {
        video.addEventListener(
          "canplay",
          () => this.playVideo(video, screenshot),
          {
            once: true,
          }
        );
      }
    },

    playVideo(video, screenshot) {
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_: any) => {
            // Initialize progress bar
            const progressBar = document.getElementById(
              `progress-bar${screenshot.id}`
            );
            const swiperBg = document.getElementsByClassName("swiper")[0];
            const updateProgressBar = () => {
              const percentage =
                (video.currentTime -
                  (this.convertStringToTime(screenshot.time) - 1)) *
                25;
              progressBar.style.width = Math.max(percentage, 0) + "%";
              progressBar.style.height = ".2rem";
            };

            // Update progress bar immediately and then every second
            updateProgressBar(); // Update immediately
            const intervalId = setInterval(updateProgressBar, 1000);

            // Clear interval and reset progress bar when video ends or is paused
            const resetProgressBar = () => {
              clearInterval(intervalId);
              progressBar.style.width = "0%";
              progressBar.style.height = "0";
              swiperBg.classList.add("bg-opacity-75");
            };

            video.addEventListener("ended", resetProgressBar);
            video.addEventListener("pause", resetProgressBar);
            video.removeEventListener("error", null);

            // Existing timeout logic here
            setTimeout(() => {
              const v = video.currentSrc;
              video.src = "";
              video.load();
              resetProgressBar(); // Reset progress bar when video is reset
            }, 4000); // 5000 milliseconds = 5 seconds
          })
          .catch((error: any) => {
            console.error("Error trying to play the video:", error);
          });
      }
    },
    async autoTagScreenshots() {
      notificationService.addNotification("Auto tagging started");
      for (const screenshot of this.screenshots) {
        if (!screenshot.tags || screenshot.tags.length == 0) {
          await this.delay(500);
          notificationService.addNotification(
            "Tagging screenshot without tags"
          );
          await this.autoTagScreenshot(screenshot);
        } else {
          const hasPageTag = screenshot.tags.some((tagId: any) => {
            const tag = this.tags.find((tag: { id: any }) => tag.id === tagId);
            return (
              (tag && tag.name?.toLowerCase().includes("page")) ||
              (tag && tag.name?.toLowerCase().includes("modal"))
            );
          });

          if (!hasPageTag) {
            try {
              await updateCollectionItem("screenshots", screenshot.id, {
                ...screenshot,
                tags: [],
              }).then(async () => {
                await this.delay(500);
                notificationService.addNotification(
                  "Deleted tags, adding new ones"
                );
                await this.autoTagScreenshot(screenshot);
              });
            } catch (error) {
              console.error(error);
            }
          }
        }
      }
      await this.delay(500);
      notificationService.addNotification("Completed");
    },

    async autoTagScreenshot(screenshot: {
      id: string;
      screenshot: { path: any };
    }) {
      window.document.getElementById(screenshot.id).scrollIntoView();
      await this.delay(500);
      notificationService.addNotification("Adding tags to screenshot");
      const screenshotComponentRef = this.$refs[screenshot.id];
      if (screenshotComponentRef) {
        screenshotComponentRef[0].generateTags(
          `https://storage.googleapis.com/userflows-390418.appspot.com/${screenshot.screenshot.path}`
        );
      }

      await this.delay(500);
      notificationService.addNotification("Waiting...");
      await this.delay(10000); // Ensure delay is defined and returns a Promise
    },

    delay(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    togglePricingModal() {
      this.$store.commit("setShowPricingModal", !this.showPricingModal);
    },
    updatePagination(data: { page: number }) {
      this.$router.push({
        ...this.$route,
        params: { ...this.$route.params, pageNumber: data.page + 1 },
        query: { ...this.$route.query, pageNumber: data.page + 1 },
      });
    },
    getData() {
      this.getTagData();
    },
    getImagesFromScreenshots() {
      const images: any = [];
      this.screenshots.forEach((screenshot: any) => {
        images.push(screenshot.screenshot.url);
      });
      return images;
    },
    async getTagData() {
      if (this.$route.params.tagTitle && this.$route.params.tagTitle != "") {
        try {
          this.tag = await getCollectionItemByKeyValue(
            "tags",
            "name",
            this.$route.params.tagTitle
          );
        } catch (error) {
          console.error(error);
        }
      }
    },
    navigateTo(page: any) {
      this.router.push({ name: page });
    },
    nextImage(index: any) {
      this.galleryImageIndex =
        index == this.screenshots?.length - 1 ? index : index + 1;
    },
    previousImage(index: any) {
      this.galleryImageIndex = index != 0 ? index - 1 : index;
    },
    showGalleryFunction(imageIndex: any) {
      this.showGallery = true;
      this.galleryImageIndex = imageIndex;
    },
  },
});
