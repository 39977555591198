import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column min-vh-100 overflow-y-visible"
}
const _hoisted_2 = {
  key: 0,
  class: "mt-0"
}
const _hoisted_3 = { class: "d-flex flex-column gap-4 w-100 overflow-y-visible" }
const _hoisted_4 = { class: "content flex-fill overflow-y-visible" }
const _hoisted_5 = {
  key: 1,
  class: "d-flex flex-column vw-100 vh-100 justify-content-center align-items-center position-fixed fixed-top"
}
const _hoisted_6 = { class: "w-100 mb-auto flex-grow-0" }
const _hoisted_7 = { class: "d-flex flex-fill" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications_component = _resolveComponent("notifications-component")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Logo = _resolveComponent("Logo")!

  return ((_ctx.$route.name == 'home' || _ctx.user.checked) && !_ctx.globalLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_notifications_component),
        (_ctx.routeNamesWithProductSidebar.includes(_ctx.$route.name as string))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_router_view)
                ])
              ])
            ]))
          : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_ProgressBar, {
            class: "w-100",
            mode: "indeterminate",
            style: {"height":"6px"}
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Logo, { class: "mx-auto" })
        ])
      ]))
}