import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icon w-3 h-3 d-flex align-items-center justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("i", {
      class: _normalizeClass(["fa fa-fw", [
        'fa-' + _ctx.icon,
        'fa-' + _ctx.size,
        _ctx.style ? `fa-${_ctx.style}` : 'fas',
        'icon__' + _ctx.size,
        'd-block fa-' + _ctx.animation,
        'fw-' + _ctx.weight,
      ]])
    }, null, 2)
  ]))
}