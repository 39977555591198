
import ButtonComponent from "../components/ButtonComponent.vue";
import FaqComponent from "../components/FaqComponent.vue";
import FieldComponent from "../components/FieldComponent.vue";
import LoaderComponent from "../components/LoaderComponent.vue";
import ProductLogo from "../components/ProductLogo.vue";
import Icon from "../components/Icon.vue";
import { computed, defineComponent, inject, ref } from "vue";
import { getProducts } from "@stripe/firestore-stripe-payments";
import { createCheckoutSession } from "@stripe/firestore-stripe-payments";
import { payments } from "@/services/Stripe";
import { useRouter } from "vue-router";
import { useHead } from "@vueuse/head";
import { Swiper, SwiperSlide } from "swiper/vue";
import { useStore } from "@/services/Store";
import PageComponent from "@/components/PageComponent.vue";
import PageContentComponent from "@/components/PageContentComponent.vue";
import SelectButton from "primevue/selectbutton";
import Slider from "primevue/slider";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { notificationService } from "@/services/NotificationsService";

export default defineComponent({
  components: {
    ButtonComponent,
    FieldComponent,
    LoaderComponent,
    Swiper,
    SwiperSlide,
    ProductLogo,
    Icon,
    FaqComponent,
    PageComponent,
    PageContentComponent,
    SelectButton,
    Slider,
  },
  setup() {
    const store = useStore();

    const popularProducts = computed(() => store.state.popularProducts);
    const user = computed(() => store.state.user);
    const counts = computed(() => store.state.counts);

    return {
      popularProducts,
      user,
      counts,
    };
  },
  data() {
    return {
      subscriptionType: ref(),
      auth: <any>inject("auth"),
      stripeProducts: ref(),
      router: useRouter(),
      loading: ref(false),
      usersInTeam: ref(5),
      store: useStore(),
      promoCode: ref(),
    };
  },
  async mounted() {
    if (this.$route.query?.code) {
      const checkStripeDiscountCode = firebase
        .functions()
        .httpsCallable("checkStripeDiscountCode");
      checkStripeDiscountCode({ code: this.$route.query?.code })
        .then((result) => {
          console.log(result.data && result.data.promotionCode.active);
          this.promoCode = result.data.valid ? result.data.promotionCode : null;
          notificationService.addNotification(
            `Discount code with ${this.promoCode.coupon.percent_off}% off applied`
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    if (this.user.isSubscribed) {
      this.$router.push({ name: "dashboard" });
    }

    await getProducts(payments, {
      includePrices: true,
      activeOnly: true,
    }).then((res) => {
      this.stripeProducts = this.filterInactivePrices(res);
      this.subscriptionType = this.stripeProducts[0];
    });
  },
  computed: {
    preparedCounts() {
      return [
        {
          title: "User flow recordings",
          value: this.roundUpCount(this.counts?.["user flows"]) * 1.5,
        },
        {
          title: "Screenshots",
          value: this.roundUpCount(this.counts?.screenshots) * 1.5,
        },
        {
          title: "Products",
          value: this.roundUpCount(this.counts?.products) * 1.5,
        },
      ];
    },
  },
  methods: {
    roundUpCount(count) {
      return Math.round(count / 100) * 100;
    },
    teamPrice(tiers) {
      let priceAmount = 0;
      let remainingUsers = this.usersInTeam;

      tiers.forEach((tier, index) => {
        if (remainingUsers <= 0) {
          return;
        }

        let usersInThisTier = 0;

        if (tier.up_to) {
          // If there's an upper limit for this tier
          if (index === tiers.length - 1) {
            // If it's the last tier, include all remaining users
            usersInThisTier = remainingUsers;
          } else {
            // Calculate how many users fall into this tier
            let tierLimit =
              tier.up_to - (index > 0 ? tiers[index - 1].up_to : 0);
            usersInThisTier = Math.min(tierLimit, remainingUsers);
          }
        } else {
          // If there's no upper limit, this tier includes all remaining users
          usersInThisTier = remainingUsers;
        }

        // Calculate the price for this tier
        priceAmount += usersInThisTier * tier.unit_amount;

        // Subtract the number of users handled in this tier
        remainingUsers -= usersInThisTier;
      });

      return priceAmount;
    },
    filterInactivePrices(data: any) {
      return data.map((product: any) => {
        return {
          ...product,
          prices: product.prices.filter((price: any) => price.active),
        };
      });
    },
    getAnnualSavings(prices) {
      const annualPrice =
        (prices.find((price: any) => {
          return price.interval == "year";
        })?.unit_amount *
          0.01) /
        12;
      const monthlyPrice =
        prices.find((price: any) => {
          return price.interval == "month";
        })?.unit_amount * 0.01;
      const priceDifference = monthlyPrice - annualPrice;
      return ((priceDifference * 100) / monthlyPrice).toFixed(0);
    },
    async signUp(
      priceName: any,
      priceDescription: any,
      priceId: any,
      priceInterval: any
    ) {
      const plan = {
        price: {
          name: priceName,
          description: priceDescription,
          id: priceId,
          interval: priceInterval,
          quantity: priceName == "Teams" ? this.usersInTeam : 1,
        },
      };

      this.store.commit("setPlan", plan);

      if (this.user.isLoggedIn) {
        this.store.commit("setGlobalLoading", true);
        if (this.user.isLoggedIn) {
          const sessionParams: any = {
            price: plan.price.id,
            quantity: plan.price.quantity,
          };

          if (this.promoCode) {
            sessionParams.promotion_code = this.promoCode.id;
          }

          const session = await createCheckoutSession(payments, sessionParams);
          window.location.assign(session.url);
        }
      } else {
        this.$router.push({ ...this.$route, name: "register" });
      }
    },
    // price.unit_amount, price.interval, price.interval_count
    getMonthlyPrice(amount: number, interval: string, count: number) {
      const amountInGbp = amount * 0.01;
      const numberOfMonths = interval == "year" ? count * 12 : count;
      return Math.ceil(amountInGbp / numberOfMonths);
    },
  },
});
