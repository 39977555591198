import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "w-100 overflow-hidden",
  style: { 'max-width': '60rem' }
}
const _hoisted_2 = { class: "d-flex flex-column gap-3 align-items-start text-start w-100 overflow-hidden" }
const _hoisted_3 = { class: "d-flex flex-column gap-1 w-100 overflow-hidden" }
const _hoisted_4 = { class: "d-flex flex-column gap-2 w-100 overflow-hidden" }
const _hoisted_5 = { class: "row border-top pt-3" }
const _hoisted_6 = { class: "col-12 col-lg-4" }
const _hoisted_7 = { style: {
                  'line-height': '1.4',
                  'font-size': '1.3rem',
                } }
const _hoisted_8 = { class: "col-12 col-lg-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faq, (category) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.questions, (question) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h3", _hoisted_7, _toDisplayString(question.question), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("p", null, _toDisplayString(question.answer), 1)
                ])
              ]))
            }), 256))
          ])
        ])
      ]))
    }), 256))
  ]))
}